<template>
    <component class="z-daterange" :is="options.component" v-bind="options.option" v-model="inputValue"></component>
</template>

<script setup>
import dayjs from "dayjs";
import { computed, onBeforeUnmount, watch, ref } from "vue";
import { DateRangePicker, TimeRangePicker } from "tdesign-vue-next";
import { setting } from "../../config/appsetting";

const props = defineProps({
    /** 时间类型 */
    Type: String,
    /** 字段 */
    Field: String,
    /** 开始时间字段 */
    BeginField: String,
    /** 结束时间字段 */
    EndField: String,
    /** 是否只读 */
    Readonly: { type: Boolean, default: null },
    /** 值格式化 */
    Format: String
});

const emits = defineEmits(["change"]);

const model = defineModel({ type: Array, default: [] });

const inputValue = ref([]);

const options = computed(() => {
    if (props.Type == "timerange") {
        /**
         * @type {import("tdesign-vue-next").TimeRangePickerProps}
         */
        var option = {
            size: "small",
            disabled: props.Readonly === true,
            format: props.Format || setting.component.default[setting.component.type.dateRange.indexOf(props.Type)],
            allowInput: true,
            clearable: true,
            onChange(value) {
                let oldValue = model.value;
                model.value = value;
                emits("change", model.value, oldValue);
            }
        };
        return { component: TimeRangePicker, option };
    } else {
        /**
         * @type {import("tdesign-vue-next").DateRangePickerProps}
         */
        var option = {
            size: "small",
            disabled: props.Readonly === true,
            allowInput: true,
            clearable: true,
            format: props.Format || setting.component.default[setting.component.type.dateRange.indexOf(props.Type)],
            mode: props.Type == "datetimerange" ? "date" : props.Type.substring(0, props.Type.length - 5),
            enableTimePicker: props.Type == "datetimerange",
            valueType: setting.component.dateFormat,
            onChange(value) {
                let oldValue = model.value;
                model.value = value;
                emits("change", model.value, oldValue);
            }
        };
        return { component: DateRangePicker, option };
    }
});

const stopWatchs = [
    watch(() => model.value, value => {
        inputValue.value = value.map(x => {
            if (x)
                return dayjs(x).format(setting.component.dateFormat);
            else
                return x;
        });
    }, { deep: true, immediate: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>

<style scoped>
.z-daterange {
    width: 100%;
}

:deep(.t-range-input.t-is-disabled) {
    background-color: var(--td-bg-color-specialcomponent);
}

:deep(.t-input.t-is-disabled) {
    background-color: var(--td-bg-color-specialcomponent);
}

:deep(.t-input.t-is-disabled .t-input__inner) {
    color: var(--td-text-color-primary);
}
</style>