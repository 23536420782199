<template>
    <t-form class="z-form" label-align="right" :show-message="false" :label-width="autoWidth">
        <auto-column :items="Content" :max-count="ColCount || 4" @change="onColumnChange" @renderVisible="renderVisible"
            colSpan="ColSpan" :maxRow="MaxRow">
            <template #default="{ item }">
                <t-form-item v-if="item.Type == 'blank'"></t-form-item>
                <t-form-item v-else :requiredMark="renderRequired(item)">
                    <template #label>
                        <tip v-bind="item.Tip">{{ renderLabel(item) }}</tip>
                    </template>
                    <z-input :config="item" v-model="model" :Readonly="renderReadonly(item)"
                        @change="onChange"></z-input>
                </t-form-item>
            </template>
            <template #collapsed="{ item }">
                <t-link theme="primary" variant="outline" size="small" style="margin-left: 4px;"
                    v-if="model[item.Field]">
                    <span style="margin-left: 10px;">{{ renderLabel(item) }}</span>：
                    <span>{{ model[item.Field] }}</span>
                </t-link>
            </template>
        </auto-column>
        <input type="submit" @click="onSubmit" style="display: none;" />
    </t-form>
</template>

<script setup name="form">
import { getCurrentInstance, onBeforeMount, onMounted, ref, watch } from "vue";
import { RegisterEvent } from "../../utils/common";
import zInput from "./input.vue";
import autoColumn from "./auto-column.vue";
import tip from "./tip.vue";
import { max } from "lodash";
import { bindDefault } from "../../hooks/useInputComponent";
import { CaretRightSmallIcon } from "tdesign-icons-vue-next";

const props = defineProps({
    /** 列数 */
    ColCount: { type: Number, default: null },
    /** 控件内容 */
    Content: Array,
    /** 是否只读 */
    Readonly: { type: Boolean, default: null },
    /** 展示2行 */
    MaxRow: { type: Number, default: null },
});

const model = defineModel();

const autoWidth = ref("12em");

const emits = defineEmits(["loaded", "change", "change-column", "submit"]);

/** 提交 */
function onSubmit(e) {
    emits("submit", model.value, props);
    //移除原有form的post事件，只需要知道表单要触发提交就可以了
    e.preventDefault();
    return false;
}

/** 改变 */
function onChange() {
    emits("change", ...arguments);
}

/** 改变列数 */
function onColumnChange() {
    emits("change-column", ...arguments);
}

function renderLabel(control) {
    return RegisterEvent(getCurrentInstance(), "RenderLabel", x => x.Label, control, model.value);
}

function renderRequired(control) {
    if (control.Required)
        return true;
    return RegisterEvent(getCurrentInstance(), "RenderRequired", x => false, control, model.value);
}

function renderVisible(control, res) {
    if (control.Visible === false)
        res.visible = false;
    else
        res.visible = RegisterEvent(getCurrentInstance(), "RenderVisible", x => x.Visible, control, model.value);
}

/** 渲染只读 */
function renderReadonly(control) {
    if (props.Readonly === true || control.Readonly === true)
        return true;
    return RegisterEvent(getCurrentInstance(), "RenderReadonly", x => false, control, model.value);
}

function calculateCustomLength(label) {
    let totalLength = 0;
    if (label) {
        for (let i = 0; i < label.length; i++) {
            const char = label.charAt(i);
            // 判断字符类别并累加相应长度
            if (/[a-zA-Z0-9]/.test(char)) { // 字母或数字
                totalLength += 0.5;
            } else if (/[!@#$%^&*(),.?":{}|<>]/.test(char) || char === '.') { // 英文符号或小数点
                totalLength += 0.2;
            } else {
                totalLength += 1;
            }
        }
    }
    return totalLength;
}

const stopWatchs = [
    watch(() => props.Content, value => {
        if (value) {
            let widths = value.map(x => calculateCustomLength(x.Label));
            autoWidth.value = `${max(widths) + 1.5}em`;
        }
    }, { immediate: true, deep: true })
];

onMounted(() => {
    bindDefault(props.Content, model.value).finally(() => emits("loaded"));
});

onBeforeMount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>

<style scoped>
.z-form {
    padding: 2px 6px 0 4px;
}

.z-form :deep(.t-form__item) {
    margin-bottom: 0;
}

.z-form :deep(.t-form__label) {
    padding-right: 4px;
}
</style>