<template>
    <button-bar :buttons="buttons" @click="onClick"></button-bar>
    <z-form v-if="hasQuery()" v-bind="Query" :MaxRow="Query.MaxRow || 2" v-model="queryInfo" @change="onChange"
            @submit="onSubmit" @loaded="onLoad"></z-form>
</template>

<script setup>
import { computed, getCurrentInstance, ref } from "vue";
import buttonBar from "../base/button-bar.vue";
import zForm from "../base/form.vue";
import { IsGenesisQuery, LoadConfig } from "../../utils/business";
import { clearValue } from "../../hooks/useInputComponent";
import { PageConfig } from "../../config/pageconfig";
import { OpenDialog } from "../../utils/dialog";
import { useAppStore } from "../../stores/useAppStore";
import { RegisterEvent } from "../../utils/common";
import { isArray } from "lodash";
import { appsettings } from "../../../appsettings";

const props = defineProps({
    Query: Object,
    Tabar: Object,
    Content: Object
});

const model = defineModel();

const queryInfo = defineModel("query");

const emits = defineEmits(["query", "clear", "change", "click"]);

/**
 * 是否有查询条件
 */
function hasQuery() {
    return props.Query && props.Query.Content && props.Query.Content.length > 0;
}

function onClick() {
    emits("click", ...arguments);
}

function onSubmit() {
    emits("query", queryInfo.value);
}

function onLoad() {
    //默认值加载完毕后，自动查询
    if (props.Content.InitLoad !== false)
        onSubmit();
}

function onChange() {
    emits("change", ...arguments);
}

/** 列改变 */
function onChangeColumn(value) {
    if (showMore.value) {

    }
}

/**
 * 是否显示更多
 */
const showMore = ref(false);

const buttons = computed(() => {
    let res = [];
    if (hasQuery()) {
        res.push({
            Label: props.Query.QueryButtonLabel || "查询",
            Action() {
                emits("query", queryInfo.value);
            }
        });
        if (appsettings.Query.GenesisQuery && IsGenesisQuery(props.Content.URL)) {
            res[0].Options = [{
                Label: "精准查询",
                Action() {
                    //精准查询.第三个参数为是否开启精准查询
                    emits("query", queryInfo.value, true);
                }
            }, {
                Label: "查询方案",
                Action() {
                    let code;
                    if (PageConfig.ConfigPath == "/ReportList/ReportList") {
                        //通用报表配置路径非真实路径
                        code = `/${PageConfig.ConfigParam.report}`;
                    } else {
                        code = PageConfig.ConfigPath;
                    }
                    OpenDialog("查询方案", "/Genesis/Permissions/NormalScheme/MyScheme", {
                        code: code,
                        id: PageConfig.SchemeID
                    }, id => {
                        useAppStore().scheme = id;
                        LoadConfig(null, () => emits("query", queryInfo.value));
                    }, { FromRight: true, ShowTabar: false });
                }
            }];
        }
        res.push({
            Label: "清空",
            Style: "danger",
            Action() {
                if (props.Query && props.Query.Content) {
                    for (let index = 0; index < props.Query.Content.length; index++) {
                        const control = props.Query.Content[index];
                        if (control.Readonly === true || control.Visible === false)
                            continue;
                        clearValue(control, queryInfo.value);
                    }
                }
                emits("clear");
                if (props.Query.AutoQuery) {
                    emits("query", queryInfo.value);
                }
            }
        });
    }
    if (props.Tabar && props.Tabar.Buttons)
        res.push(...props.Tabar.Buttons.filter(renderBtnVisible));
    return res;
});

function renderBtnVisible(btn) {
    return btn.Visible !== false && RegisterEvent(getCurrentInstance(), "RenderBtnVisible", () => {
        if (isArray(btn.VisibleCode))
            return btn.VisibleCode.includes(useAppStore().pagetype);
        return true;
    }, btn, model.value);
}

</script>

<style scoped></style>