<template>
    <t-card shadow="always" class="z-timeline">
        <template #header>
            <span style="font-size: 16px;" v-text="Label"></span>
            <z-button style="float: right;" v-bind="Button" @click="onClick"></z-button>
        </template>
        <div class="z-content">
            <t-timeline layout="vertical" mode="same">
                <t-timeline-item v-for="record in model[Field]" :key="record" :label="record[TimeField || 'time']">
                    <template #dot>
                        <div class="z-timeline-dot" :style="bindStyle(record)"></div>
                    </template>
                    <template #content>
                        <div style="font-size: 13px;" v-html="onRender(record)"></div>
                    </template>
                </t-timeline-item>
            </t-timeline>
        </div>
    </t-card>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { HasContent, toFn } from "../../utils/common";
import { isArray } from "lodash";
import { AsyncLoadInfo } from "../../utils/network";
import { BusinessPropterty, BusinessQuery } from "../../utils/business";
import zButton from "../../components/base/button.vue";

const props = defineProps({
    ...BusinessPropterty,
    Label: String,
    Button: Object,
    /** 数据字段 */
    Field: String,
    /** 内容字段 */
    ContentField: String,
    /** 图标字段 */
    IconField: String,
    /** 颜色字段 */
    ColorField: String,
    /** 风格字段 */
    StyleField: String,
    /** 时间戳字段 */
    TimeField: String,
    /** 渲染方法 */
    Render: String || Function
});

const model = defineModel();

function bindStyle(record) {
    let color = record[props.ColorField || 'color'],
        style = record[props.StyleField || 'style'];
    if (HasContent(color))
        return `background-color: ${color}; color: ${color};`;
    else
        return `background-color: var(--td-${style || 'primary'}-color-5); color: var(--td-${style || 'primary'}-color-5)`;
}

function onRender(record) {
    if (HasContent(props.Render))
        return toFn(props.Render)(record);
    else
        return record[props.ContentField || 'content'];
}

function onClick(btn) {
    toFn(btn)(btn, props);
}

function GetQuery() {
    return {};
}

function loadData(isCreate, callback, tempQuery) {
    let asyncname = `Record${props.Field}`;
    if (isCreate && props.Field in model.value) {
        //初始化，已经有数据的，不再调用URL取数
        return;
    }
    if (props.URL) {
        let query = GetQuery();
        if (HasContent(tempQuery))
            Object.assign(query, tempQuery);
        BusinessQuery(props.URL, query, {
            IgnoreAuth: props.IgnoreAuth,
            PostForJson: props.PostForJson
        }, () => {
            AsyncLoadInfo[asyncname] = 1;
        }, res => {
            if (isArray(res.data)) {
                model.value[props.Field] = res.data;
                toFn(callback)();
                AsyncLoadInfo[asyncname] = 2;
            } else {
                throw new Error("接口数据异常，未找到匹配数据！");
            }
        }, err => {
            AsyncLoadInfo[asyncname] = 3;
        });
    }
    if (isCreate) {
        toFn(callback)();
    }
}

onMounted(() => {
    loadData(true);
});
</script>

<style scoped>
.z-timeline {
    margin-top: 2px;
}

.z-timeline :deep(.t-card__header) {
    padding: 2px 6px;
    user-select: none;
}

.z-timeline :deep(.t-card__body) {
    padding: 0;
}

.z-content {
    max-height: 400px;
    overflow-y: auto;
    padding-left: 14px;
    margin-right: 4px;
    margin-bottom: 4px;
}

.z-timeline-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: translateX(-4px);
}
</style>