<template>
    <t-transfer v-model="model" :data="options" :target-order="IsMult ? TargetOrder : 'unshift'"
                :titles="[LeftTitle, RightTitle]" @change="onChange"></t-transfer>
</template>

<script>
import { useEnumComponent } from "../../hooks/useEnumComponent";
export default useEnumComponent(undefined, {
    TargetOrder: String,
    LeftTitle: String,
    RightTitle: String
});
</script>