import { Graph } from "@antv/x6";
import { Clipboard } from "@antv/x6-plugin-clipboard";
import { History } from "@antv/x6-plugin-history";
import { Keyboard } from "@antv/x6-plugin-keyboard";
import { Selection } from "@antv/x6-plugin-selection";

/**
 * @description: 使用键盘
 * @param {Graph} graph
 * @return {*}
 */
function useKeyboard(graph) {
    graph.use(new Keyboard({ enabled: true }));
    graph.use(new Clipboard({ enabled: true }));
    graph.use(new Selection({
        enabled: true, 
        multiple: true, 
        rubberband: true, 
        showNodeSelectionBox: true, 
        showEdgeSelectionBox: true
    }));
    graph.use(new History({ enabled: true }));

    //撤回
    graph.bindKey("ctrl + z", () => {
        if (graph.canUndo())
            graph.undo();
        return false;
    });
    //重做
    graph.bindKey("ctrl + y", () => {
        if (graph.canRedo())
            graph.redo();
        return false;
    });
    //开启画布拖拽
    graph.bindKey("alt", () => {
        if (!graph.isPannable()) {
            graph.enablePanning();
            //关闭范围选中
            graph.disableRubberband();
        }
        return false;
    }, "keypress");
    //关闭画布拖拽
    graph.bindKey("alt", () => {
        if (graph.isPannable()) {
            graph.disablePanning();
            //开启范围选中
            graph.enableRubberband();
        }
        return false;
    }, "keyup");
    //全选节点
    graph.bindKey("ctrl + a", () => {
        let nodes = graph.getNodes();
        if (nodes) graph.select(nodes);
        return false;
    });
    //绑定删除
    graph.bindKey(["backspace", "delete"], () => {
        let cells = graph.getSelectedCells();
        if (cells.length) {
            graph.removeCells(cells.filter(x => !x.data || (x.data.type != "start" && x.data.type != "end")));
        }
        return false;
    });
    //拷贝
    graph.bindKey("ctrl + c", () => {
        let cells = graph.getSelectedCells();
        if (cells.length) {
            graph.copy(cells.filter(cell => {
                if (cell.shape == "edge") {
                    //连接开始和结束的节点无法拷贝
                    return cell.source.cell != "start" && cell.target.cell != "end";
                } else {
                    return cell.data.type != "start" && cell.data.type != "end";
                }
            })
            );
        }
        return false;
    });
    //剪切
    graph.bindKey("ctrl + x", () => {
        let cells = graph.getSelectedCells();
        if (cells.length) {
            graph.cut(cells.filter(cell => {
                if (cell.shape == "edge") {
                    //连接开始和结束的节点无法剪切
                    return cell.source.cell != "start" && cell.target.cell != "end";
                } else {
                    return cell.data.type != "start" && cell.data.type != "end";
                }
            })
            );
        }
        return false;
    });
    //粘贴
    graph.bindKey("ctrl + v", () => {
        if (graph.isClipboardEmpty() == false) {
            let cells = graph.paste({ offset: 32 });
            graph.cleanSelection();
            graph.select(cells);
        }
        return false;
    });
}


export {
    useKeyboard
}