import { HasContent, IsNullOrEmpty } from "../../../utils/common";

/**
 * 构建表格配置
 * @returns {import("vxe-table").VxeGridProps} 表格配置
 */
function useVxeTableOption(params) {
    /** 
     * @type {import("vxe-table").VxeGridProps}
    */
    const options = {
        scrollX: { enabled: true, scrollToLeftOnChange: false },
        scrollY: { enabled: true, scrollToTopOnChange: false },

        height: 500,
        autoResize: true,
        border: true,
        size: "mini",
        showOverflow: "title",

        toolbarConfig: {
            buttons: [],
            tools: []
        },

        headerAlign: "left",
        headerCellClassName: "z-table__header",

        columnConfig: {
            isCurrent: true,
            isHover: true,
            resizable: true,
            useKey: true
        },

        rowConfig: {
            isCurrent: true,
            isHover: true,
            keyField: "$rowIndex",
            useKey: true,
            height: 26
        },

        checkboxConfig: {
            checkField: "$rowChecked",
            range: true,
            reserve: true
        },

        menuConfig: {
            enabled: true,
            body: {
                options: [[]]
            }
        },

        editConfig: {
            trigger: "manual",
            mode: "row",
            showIcon: false,
            showAsterisk: false,
            autoClear: false
        },

        sortConfig: {
            showIcon: false,
            sortMethod({ data, sortList }) {
                let column = sortList[0].column,
                    order = sortList[0].order;
                return data.sort((a, b) => {
                    if (["number", "money"].includes(column.params.Type)) {
                        if (IsNullOrEmpty(a[column.field])) {
                            if (IsNullOrEmpty(b[column.field]))
                                return 0;
                            else
                                return order == "asc" ? 1 : -1;
                        } else {
                            if (IsNullOrEmpty(b[column.field]))
                                return order == "asc" ? -1 : 1;
                            else {
                                if (order == "asc")
                                    return Number(a[column.field]) - Number(b[column.field]);
                                else
                                    return Number(b[column.field]) - Number(a[column.field]);
                            }
                        }
                    } else {
                        let res = String(a[column.field] || "").localeCompare(b[column.field] || "", ["en", "zh"]);
                        return order == "asc" ? res : -res;
                    }
                });
            }
        },

        showFooter: true,
        footerCellClassName: "z-table__footer",
        footerMethod() { return [[]]; },
        params: {
            ...params
        }
    };

    return options;
}

export {
    useVxeTableOption
}