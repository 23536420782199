<template>
    <t-calendar class="z-calendar" ref="crxCalendar" v-bind="calendarOptions" :value="today">
        <template #head>
            <div class="z-calendar-head">
                <div style="width: 7em;text-align: center;" v-text="dayjs(dateModel).format('YYYY 年 MM 月')"></div>
                <a-button-group>
                    <a-button type="outline" size="mini" @click="onPrevMonth">上个月</a-button>
                    <a-button type="outline" size="mini" @click="onToday">今天</a-button>
                    <a-button type="outline" size="mini" @click="onNextMonth">下个月</a-button>
                </a-button-group>
            </div>
            <line-loading :loading="loading"></line-loading>
        </template>
        <template #cellAppend="{ date }">
            <t-tooltip placement="right-top" theme="light" v-if="HasDataContent(date) && RenderDetail">
                <template #content>
                    <template v-for="item in GetDataContent(date)" :key="item">
                        <div class="z-online" v-html="render(item, true)"></div>
                    </template>
                </template>
                <div class="z-calendar-day" @click="onClick(date)">
                    <template v-for="item in GetDataContent(date)" :key="item">
                        <div class="z-online" v-html="render(item, false)" style="line-height: 1;padding: 1px 0;"></div>
                    </template>
                </div>
            </t-tooltip>
            <div class="z-calendar-day" v-if="!RenderDetail" @click="onClick(date)">
                <template v-for="item in GetDataContent(date)" :key="item">
                    <div v-html="render(item, false)"></div>
                </template>
            </div>
        </template>
    </t-calendar>
</template>

<script setup>
import lineLoading from "./line-loading.vue";

import { computed, getCurrentInstance, onBeforeUnmount, onMounted, reactive, ref, watch, nextTick } from "vue";
import { BusinessPropterty, BusinessQuery } from "../../utils/business";
import { AsyncLoadInfo } from "../../utils/network";
import { dayjs } from "element-plus";
import { HasContent, RegisterEvent, toFn } from "../../utils/common";
import { isArray } from "lodash";
import { useAppStore } from "../../stores/useAppStore";

const props = defineProps({
    ...BusinessPropterty,
    Height: { type: Number, default: null },
    /** 是否等待加载，主要用于等待其他条件加载完毕后再加载 */
    WaitLoad: { type: Boolean, default: null },
    /** 是否根据URL自动初始化数据，默认：是 */
    InitLoad: { type: Boolean, default: null },
    /** 自动刷新时间间隔 */
    RefreshTime: { type: Number, default: null },
    Field: String,
    DateField: String,
    NameField: String,
    /** 初始查询条件 */
    BaseQuery: Object,
    /** 默认值 */
    DefaultValue: String,
    /** 详细内容渲染函数 */
    RenderDetail: String,
    /** 日期内容渲染函数 */
    RenderContent: String
});
const emits = defineEmits(["change", "click"]);

const calendarOptions = computed(() => {
    return {
        firstDayOfWeek: 7,
        preventCellContextmenu: true,
        controllerConfig: {
            mode: false,
            weekend: false,
            current: false,
            year: false,
            month: false
        },
        year: dayjs(dateModel.value).year(),
        month: dayjs(dateModel.value).month() + 1
    }
});
const valueFormat = "YYYY-MM-DD";
const model = defineModel();
const dateModel = defineModel("date", { type: String });
const today = new Date();
const loading = ref(false);
const queryInfo = ref();
const inputValue = reactive({});


/** 上个月 */
function onPrevMonth() {
    onChange(dayjs(dateModel.value).subtract(1, "month").format(valueFormat));
}

/** 今天 */
function onToday() {
    onChange(dayjs(Date.now()).format(valueFormat));
}

/** 下个月 */
function onNextMonth() {
    onChange(dayjs(dateModel.value).add(1, "month").format(valueFormat));
}

/** 点击
 * @param {String} date 
 */
function onChange(date) {
    dateModel.value = date;
    emits("change", dayjs(date).toDate());
    nextTick(() => loadData(false));
}

/** 是否有数据 */
function HasDataContent(date) {
    let key = dayjs(date).format("YYYYMMDD");
    return key in inputValue;
}

/** 获取详细内容
 * @param {Date} date 
 */
function GetDataContent(date) {
    let key = dayjs(date).format("YYYYMMDD");
    if (key in inputValue)
        return inputValue[key].Content;
    return [];
}

function render(item, isdetail) {
    var result = RegisterEvent(getCurrentInstance(), "CalendarRender", function (config, data, isdetail) {
        if (isdetail && props.RenderDetail)
            return toFn(props.RenderDetail)(config, data);
        if (props.RenderContent)
            return toFn(props.RenderContent)(config, data);
        return data[props.NameField];
    }, props, item, isdetail);
    return result;
}

/** 点击日期事件
 * @param {Date} date 
 */
function onClick(date) {
    emits("click", date);
}

/**
 * 加载数据
 * @param {Boolean} isCreate 是否新增
 * @param {Function} callback 加载后回调
 * @param {Boolean} isAccurate 是否精准查询
 * @param {Object} tempQuery 临时查询数据
 */
function loadData(isCreate, callback, isAccurate, tempQuery) {
    let asyncname = `Calendar${props.Field}`;
    if (isCreate && props.Field in model.value) {
        AnalysisValue(model.value[props.Field]);
        toFn(callback)();
        //初始化，已经有数据的，不再调用URL取数
        return;
    }
    if (props.URL) {
        let query = GetQuery(true);
        if (HasContent(tempQuery))
            Object.assign(query, tempQuery);
        loading.value = null;
        nextTick(() => {
            BusinessQuery(props.URL, query, {
                IsAccurate: isAccurate,
                IgnoreAuth: props.IgnoreAuth,
                PostForJson: props.PostForJson,
                Query: useAppStore().config.Query
            }, () => {
                AsyncLoadInfo[asyncname] = 1;
                loading.value = true;
            }, res => {
                if (isArray(res.data)) {
                    model.value[props.Field] = reactive(res.data);
                    AnalysisValue(res.data);
                    toFn(callback)();
                    AsyncLoadInfo[asyncname] = 2;
                } else {
                    throw new Error("接口数据异常，未找到匹配数据！");
                }
            }, err => {
                AsyncLoadInfo[asyncname] = 3;
            }, () => {
                loading.value = false;
            });
        });
    }
}

/** 解析成inputValue
 * @param {Array} value 
 */
function AnalysisValue(value) {
    Object.keys(inputValue).forEach(key => delete inputValue[key]);
    if (HasContent(value) && value.length > 0) {
        value.forEach(row => {
            if (props.DateField in row) {
                let key = dayjs(row[props.DateField]).format("YYYYMMDD");
                if (key in inputValue) {
                    inputValue[key].Content.push(row);
                } else {
                    inputValue[key] = { Content: [row] };
                }
            }
        });
    }
}

/** 获取查询条件 */
function GetQuery() {
    let query = {},
        first = dayjs(dateModel.value).clone().startOf("month").startOf("week"),
        last = dayjs(dateModel.value).clone().endOf("month").endOf("week");
    return RegisterEvent(getCurrentInstance(), "GetCalendarQuery", function () {
        return {
            ...query,
            ...props.BaseQuery,
            ...queryInfo.value,
            [props.DateField]: [first.format(valueFormat), last.format(valueFormat)],
        };
    }, props, query);
}

function convertValue(value) {
    if (value == "$now")
        return new Date();
    else
        return dayjs(value).toDate();
}

const stopWatchs = [
    watch(() => props.DefaultValue, value => HasContent(value) && (dateModel.value = dayjs(value).format(valueFormat)))
];

onMounted(() => {
    if (HasContent(props.DefaultValue)) {
        var date = convertValue(props.DefaultValue);
        dateModel.value = dayjs(date).format(valueFormat);
        emits("change", date);
    }
    else {
        var date = new Date();
        dateModel.value = dayjs(date).format(valueFormat);
        emits("change", date);
    }
    if (props.WaitLoad !== true && props.InitLoad !== false)
        loadData(true);
    if (props.RefreshTime > 0) {
        let fn = () => setTimeout(() => loadData(false, fn), props.RefreshTime * 100);
        fn();
    }
});

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
})

defineExpose({
    /** 改变大小 */
    ChangeSize() { },
    /** 刷新
     * @param {Object} query 
     * @param {Function} callback 加载后回调
     */
    Refresh(query, callback) {
        if (HasContent(query)) {
            queryInfo.value = query;
        }
        loadData(false, callback);
    },
    /** 单次查询
     * @param {Object} query 
     * @param {Function} callback 加载后回调
     * @param {Boolean} isAccurate 是否精准查询
     */
    OnceQuery(query, callback, isAccurate) {
        loadData(false, callback, isAccurate, query);
    },
    /** 设置查询条件
     * @param {Object} query 
     * @param {Boolean} hasLoad 是否已经加载
     * @param {Function} callback 加载后回调
     * @param {Boolean} isAccurate 是否精准查询
     */
    SetQuery(query, hasLoad, callback, isAccurate) {
        queryInfo.value = query || {};
        if (hasLoad !== false) {
            loadData(false, callback, isAccurate);
        }
    },
    /** 获取查询条件
     * @returns {Object} 查询条件
     */
    GetQuery,
    /** 获取查询条件
     * @returns {Object} 查询条件
     */
    get QueryData() {
        return queryInfo.value;
    }
});
</script>

<style scoped>
.z-calendar {
    width: 100%;
    height: 100%;
    padding: var(--td-comp-paddingTB-xs) var(--td-comp-paddingLR-xs);
    border: none
}

.z-calendar-head {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.z-calendar-head>:first-child {
    margin-left: 10px;
    margin-right: 20px;
}

.z-calendar-day {
    height: 100%;
    overflow-y: auto;
}

.z-online {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    line-height: 1.2;
    font-size: 12px;
}

.z-calendar :deep(.t-calendar__control-section) {
    padding-right: var(--td-comp-paddingLR-s)
}

.z-calendar :deep(.t-calendar__panel) {
    margin-top: 0;
    height: calc(100% - 28px)
}

.z-calendar :deep(.t-calendar__panel .t-calendar__table) {
    display: flex;
    flex-direction: column
}

.z-calendar :deep(.t-calendar__table-head) {
    padding: var(--td-comp-paddingTB-s) 0;
    margin-bottom: 0;
    flex-grow: 0
}

.z-calendar :deep(.t-calendar__table-head-cell) {
    justify-content: center;
    width: calc(100% / 7)
}

.z-calendar :deep(.t-calendar__table-body) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100% - 28px);
    overflow-y: auto
}

.z-calendar :deep(.t-calendar__table-body-row) {
    flex-grow: 1;
    min-height: 38px;
    max-height: 25%
}

.z-calendar :deep(.t-calendar__table-body-cell) {
    width: calc(100% / 7);
    height: unset;
    align-items: flex-start;
    flex-direction: row;
    overflow-x: hidden;
    background-color: var(--td-bg-color-container)
}

.z-calendar :deep(.t-calendar__table-body-cell-display) {
    font-size: 2em
}

.z-calendar :deep(.t-calendar__table-body-cell-content) {
    padding: var(--td-comp-paddingTB-xs) var(--td-comp-paddingLR-s);
    height: 100%;
    overflow-y: auto
}

.z-calendar :deep(.t-calendar__table-body-cell--now) {
    background-color: #f6f872
}
</style>