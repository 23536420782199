<template>
    <vxe-grid class="z-grid" ref="crxContent" v-bind="{ ...vxeOption, ...vxeEvent }" :data="data">
        <template v-if="PageMode !== false" #pager>
            <vxe-pager v-bind="pageInfo" v-model:page-size="pageInfo.pageSize"
                       v-model:current-page="pageInfo.currentPage" size="mini" align="left">
                <template #left></template>
                <template #right></template>
            </vxe-pager>
        </template>
    </vxe-grid>
    <template v-if="SubTables && SubTables.length > 0">
        <template v-for="subTable in SubTables" :key="subTable.Field">
            <z-table v-if="subTable" v-bind="subTable" v-model="currentRow"
                     :Position="`${Position}${Field}/`"></z-table>
        </template>
    </template>
</template>

<script setup>
import { computed, getCurrentInstance, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import { useVxeTableOption } from '../custom/table/useTableOption';
import { useVxeTableEvent } from '../custom/table/useTableEvent';
import { useVxeTableColumn } from '../custom/table/useTableColumn';
import { useVxeTableButton } from "../custom/table/useVxeTableButton";
import 'vxe-table/lib/style.css';
import { debounce, isArray } from 'lodash';
import { HasContent, RegisterEvent, toFn } from '../../utils/common';
import { PageConfig } from '../../config/pageconfig';
import { useAppStore } from '../../stores/useAppStore';
import { BusinessQuery } from '../../utils/business';
import { AsyncLoadInfo } from '../../utils/network';
import { TableReadonly, RowClick } from '../custom/table/action';

defineOptions({ name: 'zTable' });

/**
 * 表格组件
 * @type {import("../../models/ZTableProperty").ZTableProperty}
 */
const props = defineProps({
    /** 表标题 */
    Label: String,
    /** 表数据存放字段 */
    Field: String,
    /** 主键字段（唯一键） */
    Key: String,
    /** 名称字段 */
    Name: String,
    /** 数据URL */
    URL: String,
    /** 是否等待加载，主要用于等待其他条件加载完毕后再加载 */
    WaitLoad: { type: Boolean, default: null },
    /** 是否根据URL自动初始化数据，默认：是 */
    InitLoad: { type: Boolean, default: null },
    /** 自动刷新时间间隔 */
    RefreshTime: { type: Number, default: null },
    /** 表格模式：view/edit */
    Mode: String,
    /** 是否支持多选 */
    Single: { type: Boolean, default: null },
    /** 是否分页 */
    PageMode: { type: Boolean, default: null },
    /** 表内按钮 */
    Tabar: Array,
    /** 表格的列字段 */
    Columns: Array,
    /** 子表清单 */
    SubTables: Array,
    /** 表格高度 */
    Height: { type: Number, default: null },
    /** 是否支持通用导出 */
    Export: { type: Boolean, default: null },
    /** 是否支持通用导入 */
    Import: { type: Boolean, default: null },
    /** 是否支持批量修改 */
    BatchEdit: { type: Boolean, default: null },
    /** 是否自动增加行 */
    AutoAddRow: { type: Boolean, default: null },
    /** 是否分组表格 */
    IsGroupTable: { type: Boolean, default: null },
    /** 是否显示序号 */
    ShowIndex: { type: Boolean, default: null },
    /** 列默认只读状态，默认：只读 */
    ColumnDefaultReadonly: { type: Boolean, default: null },
    /** 当前表格的页面大小 */
    Limit: { type: Number, default: null },
    /** 是否必填 */
    Required: { type: Boolean, default: null },
    /** 是否只读 */
    Readonly: { type: Boolean, default: null },
    /** 是否显示 */
    Visible: { type: Boolean, default: null },
    /** 初始查询条件 */
    BaseQuery: Object,
    /** 是否需要空白列 */
    BlankColumn: { type: Boolean, default: null },
    /** 行高 */
    RowHeight: { type: Number, default: 22 },
    /** 表格位置 */
    Position: { type: String, default: "" },
    /** 是否支持性能模式，启用虚拟滚动，默认启动 */
    Performance: { type: Boolean, default: null },
    /** 是否支持换行，和虚拟滚动一起启用时可能出现渲染异常，默认不支持 */
    WrapLine: { type: Boolean, default: null },
});
const emits = defineEmits(["row-click", "row-dbclick", "cell-click", "cell-dbclick", "page-change"]);

const model = defineModel({ type: Object });

/** 表格数据 */
const data = computed({
    get() {
        if (props.PageMode !== false) {
            if (model.value[props.Field])
                return model.value[props.Field].data;
        }
        else
            return model.value[props.Field];
        return null;
    },
    set(value) {
        if (props.PageMode !== false)
            model.value[props.Field].data = value;
        else
            model.value[props.Field] = value;
    }
});

/**
 * @type {import('vue').Ref<import("vxe-table").VxeGridInstance>}
 */
const crxContent = ref(null);

const vxeOption = reactive(useVxeTableOption({
    GetModelValue() {
        return model.value;
    },
    GetQuery() {
        return GetQuery(false);
    },
    /** 清空当前行 */
    ClearCurrentRow() {
        currentRow.value = {};
    }
}));

const {
    currentRow,
    events: vxeEvent
} = useVxeTableEvent({ emits });

/** 页面信息 */
const pageInfo = reactive({
    currentPage: 1,
    pageSize: PageConfig.DefaultPageLimit,
    total: 0,
    layouts: ["PrevPage", "JumpNumber", "NextPage", "FullJump", "Total", "Sizes"],
    pageSizes: PageConfig.PageSizes
});

/** 查询条件 */
const queryInfo = ref({});

const renderButtons = debounce((readonly) => {
    vxeOption.toolbarConfig.buttons.splice(0, vxeOption.toolbarConfig.buttons.length, ...useVxeTableButton(props, readonly));
}, 200);

function onCurrentChange(value) {
    emits("page-change", value);
    loadData();
}

const stopWatchs = [
    watchEffect(() => {
        renderButtons(TableReadonly(props));
        if (data.value && data.value.length > 0) {
            data.value.forEach((row, index) => {
                if (row.$Index != index)
                    row.$Index = index;
                if (props.Key)
                    row.$Key = row[props.Key];
                if (props.Name)
                    row.$Name = row[props.Name];
            });
        }
    }),
    watch(() => props.Height, (value) => value && (vxeOption.height = value)),
    watch(() => props.Columns, () => nextTick(() => crxContent.value.loadColumn(useVxeTableColumn(props, vxeOption))), { immediate: true, deep: true }),
    watch(() => pageInfo.currentPage, onCurrentChange),
    watch(() => pageInfo.pageSize, () => onCurrentChange(1)),
    watch(() => props.RowHeight, value => vxeOption.rowConfig.height = value || 26),
    watch(() => props.Performance, value => vxeOption.scrollY.enabled = value !== false, { immediate: true }),
    watch(() => props.WrapLine, value => vxeOption.showOverflow = value ? false : "title", { immediate: true })
];

/**
 * 获取查询条件
 * @param {Boolean} needPage 是否需要分页
 * @returns {Object}
 */
function GetQuery(needPage) {
    let query = {};
    return RegisterEvent(getCurrentInstance(), "GetTableQuery", function () {
        if (needPage)
            return Object.assign(query, {
                page: pageInfo.currentPage,
                limit: pageInfo.pageSize,
                ...props.BaseQuery,
                ...queryInfo.value
            });
        else
            return Object.assign(query, {
                ...props.BaseQuery,
                ...queryInfo.value
            });
    }, props, query);
}

/**
 * 加载数据
 * @param {Boolean} isCreate 是否新增
 * @param {Function} callback 加载后回调
 * @param {Boolean} isAccurate 是否精准查询
 * @param {Object} tempQuery 临时查询数据
 */
function loadData(isCreate, callback, isAccurate, tempQuery) {
    let asyncname = `Table${props.Field}`;
    //初始化，已经有数据的，不再调用URL取数
    if (isCreate && props.Field in model.value) {
        toFn(callback)();
        if (props.PageMode !== false) {
            if (model.value[props.Field].data && model.value[props.Field].data.length > 0)
                RowClick(crxContent.value, model.value[props.Field].data[0])
            else
                currentRow.value = {};
        }
        return;
    }
    if (props.URL) {
        let query = GetQuery(true);
        if (HasContent(tempQuery))
            Object.assign(query, tempQuery);
        BusinessQuery(props.URL, query, {
            IsAccurate: isAccurate,
            IgnoreAuth: props.IgnoreAuth,
            PostForJson: props.PostForJson,
            Query: useAppStore().config.Query
        }, () => {
            AsyncLoadInfo[asyncname] = 1;
            vxeOption.loading = true;
        }, res => {
            if (props.PageMode !== false) {
                if (typeof res.data === "object" && "data" in res.data && isArray(res.data.data)) {
                    crxContent.value.loadData(res.data.data);
                    if (isNaN(res.data.page))
                        pageInfo.currentPage = 1;
                    else
                        pageInfo.currentPage = res.data.page;
                    if (isNaN(res.data.count))
                        pageInfo.total = 0;
                    else
                        pageInfo.total = res.data.count;
                    if (isNaN(res.data.limit))
                        pageInfo.pageSize = PageConfig.DefaultPageLimit;
                    else
                        pageInfo.pageSize = res.data.limit;
                    model.value[props.Field] = reactive(res.data);
                    if (res.data.data.length > 0)
                        RowClick(crxContent.value, res.data.data[0]);
                    else
                        currentRow.value = {};
                } else {
                    //如果数据不匹配，自动调整为空数据
                    crxContent.value.loadData([]);
                    pageInfo.total = 0;
                    model.value[props.Field] = reactive({
                        count: 0,
                        page: pageInfo.currentPage,
                        limit: pageInfo.pageSize,
                        data: []
                    });
                    currentRow.value = {};
                }
            }
            else {
                if (isArray(res.data)) {
                    crxContent.value.loadData(res.data);
                    model.value[props.Field] = reactive(res.data);
                    if (res.data.length > 0)
                        RowClick(crxContent.value, res.data[0]);
                    else
                        currentRow.value = {};
                } else {
                    //如果数据不匹配，自动调整为空数据
                    crxContent.value.loadData([]);
                    model.value[props.Field] = reactive([]);
                    currentRow.value = {};
                }
            }
            toFn(callback)();
            AsyncLoadInfo[asyncname] = 2;
        }, err => {
            AsyncLoadInfo[asyncname] = 3;
        }, () => {
            vxeOption.loading = false;
        });
    }
}

onMounted(() => {
    Object.assign(vxeOption.params, props);
    if (props.Height)
        vxeOption.height = props.Height;
    if (props.WaitLoad !== true && props.InitLoad !== false) {
        loadData(true);
    }
    if (props.RefreshTime > 0) {
        let fn = () => setTimeout(() => loadData(false, fn), props.RefreshTime * 100);
        fn();
    }
    if ((props.Field in model.value) == false)
        model.value[props.Field] = reactive([]);
});

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});

defineExpose({
    /** 表格控件变更大小，用于表格强制重新渲染 */
    ChangeSize() { },
    /** 刷新表格
     * @param {Object} query 查询条件
     */
    Refresh(query, callback) {
        if (HasContent(query)) {
            queryInfo.value = query;
            pageInfo.currentPage = 1;
        }
        loadData(false, callback);
    },
    /** 单次查询，条件不记录到表格中
     * @param {Object} query 查询条件
     * @param {Function} callback 回调事件
     */
    OnceQuery(query, callback, isAccurate) {
        pageInfo.currentPage = 1;
        loadData(false, callback, isAccurate, query);
    },
    /** 设置查询条件
     * @param {Object} query 查询条件
     * @param {Boolean} hasLoad 是否重新加载表格
     * @param {Function} callback 回调事件
     * @param {Boolean} isAccurate 是否精准查询
     */
    SetQuery(query, hasLoad, callback, isAccurate) {
        queryInfo.value = query || {};
        pageInfo.currentPage = 1;
        if (hasLoad !== false)
            loadData(false, callback, isAccurate);
    },
    /** 获取查询条件
     * @returns {Object} 查询条件
     */
    GetQuery,
    /** 获取选中行
     * @param {Function} callback 回调事件
     */
    GetSelect() {
        return crxContent.value.getCheckboxRecords();
    },
    get QueryData() {
        return queryInfo.value;
    }
});
</script>

<style scoped>
.z-grid :deep(.vxe-cell--checkbox) {
    --vxe-checkbox-font-size-mini: 16px;
    position: relative;
    top: -1.5px;
}

.z-grid :deep(.vxe-cell) {
    padding: 0 2px;
}

.z-grid :deep(.vxe-toolbar) {
    padding: 0;
    background-color: var(--gray-100);
}

.z-grid :deep(.vxe-toolbar .vxe-button--item) {
    margin-left: 0 !important;
}

.z-grid :deep(.vxe-header--column),
.z-grid :deep(.vxe-footer--column) {
    background-color: var(--gray-100);
}

.z-grid :deep(.z-table__header),
.z-grid :deep(.z-table__footer) {
    padding: 1px !important;
}

.z-grid.is--loading :deep(>.vxe-table .vxe-loading) {
    background-color: var(--color-mask-bg);
}

.z-grid :deep(.vxe-loading>.vxe-loading--chunk),
.z-grid :deep(.vxe-loading>.vxe-loading--warpper) {
    color: var(--color-white);
    zoom: 1.4;
    -ms-zoom: 1.4;
}

.z-grid :deep(.z-table-edit-cell) {
    background-image: linear-gradient(var(--vxe-table-border-color), var(--vxe-table-border-color)), linear-gradient(var(--green-600), var(--green-600)) !important;
    border-bottom: 1px solid var(--green-600);
}
</style>