<template>
    <iframe ref="crxFrame" :name="formStamp"></iframe>
    <form ref="crxForm" method="post" :target="formStamp"></form>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { GetAccountSet, GetFromMenu } from '../../utils/business';
import { ViteSetting } from '../../config/vitesetting';
import { CreateFormByData, CreateURL, GetLocationURL } from '../../utils/network';

const props = defineProps({
    title: String,
    src: String,
    param: Object
});

/**
 * @type {import("vue").Ref<HTMLIFrameElement>}
 */
const crxFrame = ref(null);
/**
 * @type {import("vue").Ref<HTMLFormElement>}
 */
const crxForm = ref(null);

const timeStamp = ref(0);

const formStamp = computed(() => props.title + timeStamp);

onMounted(() => {
    var uri = props.src,
        query = {
            ...props.param,
            menuid: GetFromMenu(),
            accid: GetAccountSet(),
            v: Date.now()
        };
    timeStamp.value = query.v;
    if (ViteSetting.IsApp && uri.length > 0 && uri.substring(0, 1) != "/" && uri.startsWith("http") == false) {
        let _uri = GetLocationURL();
        if (_uri.pathname != "/")
            uri = _uri.pathname.replace(/\/\w+\?|\/\w+$/, `/${uri}`);
    }
    if ("$Post" in query) {
        var form = CreateFormByData(query.$Post, crxForm.value);
        delete query.$Post;
        if (ViteSetting.IsApp)
            form.action = `?app=${encodeURIComponent(CreateURL(uri, query, false))}`;
        else
            form.action = CreateURL(uri, query, false);
        form.submit();
    } else {
        if (ViteSetting.IsApp && uri.startsWith("http") == false) {
            crxFrame.value.src = `?app=${encodeURIComponent(CreateURL(uri, query, false))}`;
        } else {
            crxFrame.value.src = CreateURL(uri, query, false);
        }
    }
});
</script>