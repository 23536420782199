<template>
    <span class="z-high-light" ref="crxContent" v-html="content"></span>
</template>

<script setup>
import { nextTick, onBeforeUnmount, ref, watch } from "vue";
import { IsNullOrEmpty } from "../../utils/common";
import { debounce } from "lodash";
import { match } from "pinyin-pro";

const props = defineProps({
    value: String,
    /** 关键词 */
    keyword: String,
    /** 高亮的颜色 */
    color: String
});

/** 显示内容
 * @type {import("vue").Ref<HTMLDivElement>)}
 */
const crxContent = ref();

const content = ref("");

/** 解析 */
const analysis = debounce((value) => {
    if (IsNullOrEmpty(props.value) == false && IsNullOrEmpty(props.keyword) == false) {
        let matchs = match(props.value, props.keyword, { precision: "first", continuous: true });
        if (matchs && matchs.length > 0) {
            content.value = props.value.substring(0, matchs[0])
                + `<span class='light'>${props.value.substring(matchs[0], matchs[matchs.length - 1] + 1)}</span>`
                + props.value.substring(matchs[matchs.length - 1] + 1);
        } else {
            content.value = props.value;
        }
    }
    else if (content.value != props.value)
        content.value = props.value;
}, 200);

const stopWatchs = [
    watch(() => props.value, analysis, { immediate: true }),
    watch(() => props.keyword, analysis, { immediate: true }),
    watch(() => props.color, value => {
        if (crxContent.value)
            crxContent.value.style["--z-light-color"] = value;
    }, { immediate: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>

<style scoped>
.z-high-light {
    --z-light-color: var(--orange-400);
}

.z-high-light :deep(.light) {
    background-color: var(--z-light-color);
    color: #fff;
    padding: 1px 3px;
    border-radius: 2px;
    font-weight: bolder;
}
</style>