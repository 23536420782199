import { PageConfig } from "../config/pageconfig";
import { OpenWindow } from "../utils/dialog";

window.addEventListener("keydown", function (e) {
    if (e.altKey && e.ctrlKey && e.shiftKey) {
        switch (e.code) {
            case "KeyL":
                OpenWindow("系统日志", "/Security/SystemLog/Index");
                break;
            case "KeyQ":
                OpenWindow("数据库监控", "/Vue/DBMonitor");
                break;
            case "KeyT":
                OpenWindow("数据库表管理", "/Genesis/DbManage/TableInfo/Index");
                break;
            case "KeyV":
                OpenWindow("数据库视图管理", "/Genesis/DbManage/ViewInfo/Index");
                break;
            case "KeyP":
                OpenWindow("数据库存储过程管理", "/Genesis/DbManage/ProcedureInfo/Index");
                break;
            case "KeyC":
                let path = PageConfig.ConfigPath;
                //区分通用报表页面
                OpenWindow("页面配置", "/Vue/EditPageConfig", {
                    type: PageConfig.PageType,
                    path: path == "/ReportList/ReportList" ? PageConfig.ConfigParam.report : path
                });
                break;
            case "KeyO":
                //支持切换新旧页面【新版 BUG 多，支持切换兼容，POST 请求页面不支持切换】
                if (/[?&]v2=1/g.test(this.location.search)) {
                    this.location.replace(this.location.search.replace(/v2=1/g, ""));
                } else {
                    this.location.replace(this.location.search ? `${this.location.search}&v2=1` : `?v2=1`);
                }
                break;
        }
    }
});