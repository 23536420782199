<template>
    <el-popover v-if="Message" v-bind="option">
        <template #reference>
            <slot></slot>
        </template>
    </el-popover>
    <slot v-else></slot>
</template>

<script setup>
import { computed } from 'vue';

/**
 * @type {{
 *  Title: String,
 *  Message: String,
 *  Width: Number,
 *  Placement: 'top'|'left'|'right'|'bottom'|'top-left'|'top-right'|'bottom-left'|'bottom-right'|'left-top'|'left-bottom'|'right-top'|'right-bottom'
 * }}
 */
const props = defineProps({
    /** 标题 */
    Title: String,
    /** 内容 */
    Message: String,
    /** 位置 */
    Placement: String,
    /** 宽度 */
    Width: Number || String
});

const option = computed(() => {
    return {
        title: props.Title,
        trigger: 'hover',
        placement: props.Placement || 'top',
        title: props.Title || '提示',
        content: props.Message,
        width: props.Width || 200,
    };
});
</script>