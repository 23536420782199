import { debounce } from 'lodash';
import { ref } from 'vue';
import { OnceRequest } from '../../../utils/network.extend';
import { HttpGet } from '../../../utils/network';
import { HasContent, IsNullOrEmpty, ParseParameters } from '../../../utils/common';
import { BusinessQuery } from '../../../utils/business';

/** 使用快速查询
 * @param {{ Enabled: boolean, ShowCode: boolean,  Code: string, Url: string, Param: Object, SetValue: (value)=> void}} param0 
 * @returns {{
*      configuration: { Key: String, Name: String, URL: String, PageMode: Boolean, IgnoreAuth: Boolean, PostForJson: Boolean },
*      searching: import('vue').Ref<boolean>, 
*      suggestions: import('vue').Ref<Array>,
*      searchSuggestion: (value: string)=> void,
*      searchSuggestionAsync: (value: string)=> import("lodash").DebouncedFunc<()=> void>,
*      clearSuggestion: ()=> void,
*      clearSuggestionAsync: ()=> import("lodash").DebouncedFunc<()=> void>,
*      selectSuggestion: (value)=> void,
* }}
*/export function useQuickSearch({ Enabled, ShowCode, Code, Url, Param, SetValue }) {
    const configuration = {};
    let path;
    if (Code && typeof Business != "undefined" && Code in Business.DialogCode)
        path = Business.DialogCode[Code];
    else if (Url)
        path = Url;

    //获取快速查询的配置信息
    if (Enabled !== false) {
        OnceRequest(HttpGet)("GetVueConfig", { type: "list", path }, res => {
            const content = res.data.Content;
            if (/^\/|^http/.test(content.URL)) {
                //绝对地址，不需要转换
                configuration.URL = content.URL;
            } else {
                //相对地址，需要转换绝对路径
                configuration.URL = path.replace(/[^\/]+$/, content.URL);
                const query = /\?.*$/.test(content.URL);
                if (query)
                    query.URL += query[0];
            }
            configuration.Key = content.Key;
            configuration.Name = content.Name;
            configuration.PageMode = content.PageMode;
            configuration.IgnoreAuth = content.IgnoreAuth;
            configuration.PostForJson = content.PostForJson;
        });
    }

    /** 是否在检索中 */
    const searching = ref(false);
    /** 检索建议 */
    const suggestions = ref([]);
    /** 检索建议
     * @param {string} keywork 关键词
     */
    function searchSuggestion(keywork, props, other) {
        if (!configuration.URL) {
            //配置信息还未加载完毕，暂时不触发检索
            return;
        }
        const param = {
            from__quick: true
        };
        if (ShowCode)
            param[configuration.Key] = keywork;
        else
            param[configuration.Name] = keywork;
        if (configuration.PageMode !== false) {
            param.page = 1;
            //减少可用选项
            param.limit = 30;
        }
        if (HasContent(Param))
            Object.assign(param, JSON.parse(ParseParameters(JSON.stringify(Param), other)));
        BusinessQuery(configuration.URL, param, {
            from__path: path,
            IgnoreAuth: configuration.IgnoreAuth,
            PostForJson: configuration.PostForJson
        }, () => searching.value = true, res => {
            const data = [];
            if (configuration.PageMode !== false)
                data.push(...res.data.data);
            else
                data.push(...res.data);
            data.forEach(x => {
                if (configuration.Key)
                    x.$Key = x[configuration.Key];
                if (configuration.Name)
                    x.$Name = x[configuration.Name];
            });
            clearSuggestionAsync.cancel();
            suggestions.value = data.map((x, index) => ({
                label: ShowCode ? x.$Key : x.$Name,
                text: String(index),
                value: x
            }));
        }, null, () => searching.value = false);
    }
    const searchSuggestionAsync = debounce(searchSuggestion, 200);

    /** 清空检索建议 */
    function clearSuggestion() {
        suggestions.value = [];
    }
    /** 清空搜索建议异步（可取消） */
    const clearSuggestionAsync = debounce(clearSuggestion, 200);

    /** 选择检索建议
     * @param {number} index 选择的索引
     */
    function selectSuggestion(index) {
        clearSuggestionAsync.cancel();
        if (IsNullOrEmpty(index)) {
            if (suggestions.value.length > 0) {
                SetValue(suggestions.value[0].value, true);
            }
        } else {
            SetValue(suggestions.value[index].value, true);
        }
    }

    return {
        configuration,
        searching,
        suggestions,
        searchSuggestion,
        searchSuggestionAsync,
        clearSuggestion,
        clearSuggestionAsync,
        selectSuggestion
    }
}