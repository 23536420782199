<template>
    <div class="z-pages-dashboard">
        <auto-height scroll>
            <template #header>
                <z-query ref="crxTabar" v-bind="$props" v-model:query="queryModel" v-model="model" @query="onQuery"
                         @change="onQueryChange" @click="onClick"></z-query>
            </template>
            <template #default>
                <z-layout :count="ColCount || 3" :model-value="Content" draggable resizable :margin="[4, 4]"
                          :row-height="130" @resized="onResized">
                    <template #default="{ index, cell }">
                        <t-card shadow="hover" class="box-card" :style="GetCardStyle(cell)">
                            <template #title>
                                <span class="drag-move" v-text="cell.Label"></span>
                            </template>
                            <template #actions>
                                <t-tooltip class="item" content="刷新" placement="bottom">
                                    <RefreshIcon :size="24" @click="onRefresh(index)"></RefreshIcon>
                                </t-tooltip>
                                <t-tooltip v-if="cell.MenuID" class="item" content="查看更多" placement="bottom">
                                    <LinkIcon :size="25" @click="onMore(cell)"></LinkIcon>
                                </t-tooltip>
                            </template>
                            <div class="no-drag">
                                <auto-height>
                                    <template #default="{ height }">
                                        <component :ref="`crxContent_${index}`" :is="GetComponent(cell,height)"
                                                   v-model="model"></component>
                                    </template>
                                </auto-height>
                            </div>
                        </t-card>
                    </template>
                </z-layout>
            </template>
        </auto-height>
    </div>
</template>

<script setup>
import zQuery from "../src/components/custom/query.vue";
import { getCurrentInstance, onMounted, h, ref } from "vue";
import autoHeight from "../src/components/base/auto-height.vue";
import zLayout from "../src/components/base/layout.vue";
import { appsettings } from "../appsettings";
import { useStickyToolStore } from "../src/stores/useStickyToolStore";
import { LinkIcon, RefreshIcon } from "tdesign-icons-vue-next";
import { useAppStore } from "../src/stores/useAppStore";
import zChart from "../src/components/base/chart.vue";
import zTable from "../src/components/base/table.vue";
import zCalendar from "../src/components/base/calendar.vue";
import { RegisterEvent, toFn } from "../src/utils/common";
import { reactive } from "vue";
import { PageConfig } from "../src/config/pageconfig";
// import zPageList from "../src/components/base/page-list.vue";

const model = defineModel();
const queryModel = reactive({ ...PageConfig.Query });

const props = defineProps({
    /** 查询条件 */
    Query: Object,
    /** 按钮栏 */
    Tabar: Object,
    /** 列数 */
    ColCount: { type: Number, default: null },
    /** 内容 */
    Content: Array
});

/**
 * @type {import("vue").Ref<import("vue").ComponentPublicInstance>}
 */
const instance = ref();

/** 刷新数据
 * @param {number} i
 */
function onRefresh(i) {
    if (instance.value && instance.value.refs && `crxContent_${i}` in instance.value.refs) {
        toFn(instance.value.refs[`crxContent_${i}`].Refresh)();
    }
}

/** 查看更多
 * @param {Object} cell
 */
function onMore(cell) {
    if (top && top.$app && top.$app.OpenTab)
        top.$app.OpenTab(cell.MenuID);
}

/** 窗口大小改变
 * @param {number} i
 */
function onResized(i) {
    if (instance.value && instance.value.refs && `crxContent_${i}` in instance.value.refs) {
        toFn(instance.value.refs[`crxContent_${i}`].RefreshSize)();
    }
}

function SetQuery(query) {

}

function onQuery(query) {
    for (let i = 0; i < props.Content.length; i++) {
        if (IsQueryDashBoard(props.Content[i]))
            Refresh(i);
    }
}

function Refresh(i) {
    let query;
    if (IsQueryDashBoard(props.Content[i]))
        query = queryModel;
    else
        query = null;
    if (instance.value && instance.value.refs && `crxContent_${i}` in instance.value.refs)
        toFn(instance.value.refs[`crxContent_${i}`].Refresh)();
}

function onQueryChange() {

}

function onClick(btn) {
    RegisterEvent(getCurrentInstance(), "TabarClick", () => {
        toFn(btn)(btn);
    }, btn);
}

function GetComponent(cell, height) {
    if (cell.Chart)
        return h(zChart, { ...cell.Chart, Height: height });
    if (cell.Table)
        return h(zTable, { ...cell.Table, Height: height });
    if (cell.Calendar)
        return h(zCalendar, { ...cell.Calendar, Height: height });
    return h("div");
}

function GetCardStyle(cell) {
    let res = "";
    if (cell.BackgroundColor)
        res += `--z-bg-color: ${cell.BackgroundColor};`;
    if (cell.Color)
        res += `--z-font-color: ${cell.Color};`;
    return res;
}

function IsQueryDashBoard(control) {
    return RegisterEvent(instance.value, "IsQueryDashBoard", function () {
        return control.IsQueryDashBoard;
    }, control);
}

onMounted(() => {
    instance.value = getCurrentInstance();
    useAppStore().__bindInstance(getCurrentInstance());
    if (appsettings.Page.HelpButton)
        useStickyToolStore().AddStandardStickyTool("help");
});

defineExpose({
    IsQueryDashBoard,
})
</script>

<style scoped>
.z-pages-dashboard {
    height: 100%;
}

.box-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.box-card :deep(.t-card__header) {
    flex-shrink: 0;
    padding: 3px 12px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    background-color: var(--z-bg-color);
    color: var(--z-font-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.box-card :deep(.t-card__body) {
    flex-grow: 1;
    padding: 0;
}

.box-card .no-drag {
    height: 100%;
    width: 100%;
}

.item {
    padding: 4px;
    cursor: pointer;
}
</style>