<template>
    <t-rate :class="{ 'z-readonly': Readonly }" v-model="inputValue" @change="onChange" :max="Max" :low-threshold="Low"
        :high-threshold="High" :allow-half="AllowHalf" :colors="Colors" :texts="Texts"
        :show-text="Texts && Texts.length > 0" :show-score="ShowScore" :disabled="Readonly" clearable></t-rate>
</template>

<script setup>
import {
    onBeforeUnmount,
    ref, watch
} from "vue";
const props = defineProps({
    Type: String,
    /** 是否只读 */
    Readonly: { type: Boolean, default: null },
    /** 最大分值 */
    Max: { type: Number, default: null },
    /** 是否允许半星 */
    AllowHalf: { type: Boolean, default: null },
    /** 低分界限值 */
    Low: { type: Number, default: null },
    /** 高分界限值 */
    High: { type: Number, default: null },
    /** 颜色值 */
    Colors: Array,
    /** 辅助文本 */
    Texts: Array,
    /** 是否显示分值 */
    ShowScore: { type: Boolean, default: null }
});
const emits = defineEmits(["change"]);

const model = defineModel({ type: Number });

const inputValue = ref();

function onChange(value) {
    if (model.value != value) {
        let oldValue = model.value;
        model.value = value;
        emits("change", value, oldValue);
    }
}

const stopWatchs = [
    watch(() => model.value, value => {
        inputValue.value = value;
    }, { immediate: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>

<style scoped>
.z-readonly,
.z-readonly :deep(.t-rate__item) {
    cursor: not-allowed;
}
</style>