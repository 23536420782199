<template>
    <div :class="`z-modal-footer z-modal-footer__${align || 'right'}`">
        <t-space size="small">
            <template v-for="btn in buttons">
                <component :is="getButtonComponent(btn)" @click="onClick(btn)" />
            </template>
        </t-space>
    </div>
</template>

<script setup>
import { Button } from "tdesign-vue-next";
import { h } from "vue";

/**
 * @type {{
 *  buttons: import("tdesign-vue-next").ButtonProps[],
 *  modal: import("vxe-table").VxeModalInstance,
 *  align: "left" | "right" | "center"
 * }}
 */
const props = defineProps({
    buttons: Array,
    modal: Object,
    align: String
});

function getButtonComponent(btn) {
    if (typeof btn == "string")
        return h(Button, { content: btn });
    else
        return h(Button, btn);
}

function onClick(btn) {
    props.modal.dispatchEvent("close", { btn });
}
</script>

<style scoped>
.z-modal-footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.z-modal-footer__left {
    justify-content: flex-start;
}

.z-modal-footer__center {
    justify-content: center;
}

.z-modal-footer__right {
    justify-content: flex-end;
}

.z-modal-footer :deep .t-button {
    height: 26px;
    padding: 0 10px;
    min-width: 60px;
}
</style>