import "./src/assets/css/tur-iconfont.css"
import 'vxe-table/styles/cssvar.scss'
import "./startup.css"
import "./color.css"

import { createApp } from 'vue'
import startup from './startup.vue'

import PrimeVue from "primevue/config"
import PrimeOne from "primevue/themes/primeone"

import ConfirmAtionService from "primevue/confirmationservice"
import { createPinia } from 'pinia'
import VueGridLayout from "vue-grid-layout"

import "./src/exports/window.fn"
import "./src/exports/window.dialog"
import "./src/exports/window.network"
import "./src/exports/window.event"

import "./src/components/custom/table/registerConfig"
import XEUtils from 'xe-utils';
import { VXETable, Grid, Table, Pager, Edit, Keyboard, Toolbar, Filter, Menu, Select, List, Validator, Modal } from 'vxe-table'
import zhCN from 'vxe-table/es/locale/lang/zh-CN';
import { ViteSetting } from "./src/config/vitesetting"
import { defaultTheme } from "./src/themes/defalutTheme"

const pinia = createPinia();
const app = createApp(startup);

app.config.warnHandler = (msg, options) => {
    if (ViteSetting.IsApp) {
        let ignoreType = msg.startsWith("Invalid prop: type check failed for prop")
            || msg == "Property \"$router\" was accessed during render but is not defined on instance."
        if (ignoreType)
            return false;
        console.warn(msg, options);
    } else {
        //网页环境忽略所有警告
        return false;
    }
};

app.use(pinia);
app.use(PrimeVue, {
    theme: {
        base: PrimeOne,
        preset: defaultTheme,
        options: {
            prefix: "",
            darkModeSelector: "",
            cssLayer: false
        }
    }
});
app.use(ConfirmAtionService);
app.use(VueGridLayout);

VXETable.config({
    i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
});

[
    Grid, Table, Pager, Edit, Keyboard, Toolbar, Filter, Menu, Select, List, Validator, Modal
].forEach(x => app.use(x));

app.mount('#app');
