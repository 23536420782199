import { defineStore } from "pinia";
import { HttpGet } from "../utils/network";
import { toFn } from "../utils/common";
import { differenceBy } from "lodash";
import { Notification } from "../utils/dialog";

export const useMessageStore = defineStore("message", {
    state: () => ({
        /** 未读消息
         * @type {Array}
         */
        UnReadMessages: [],
        /** 已读消息
         * @type {Array}
         */
        ReadMessages: []
    }),
    actions: {
        /**
         * 加载消息
         * @param {"read" | "unread"} type 消息类型
         */
        loadMessage(type, URL, success, fail, complete) {
            HttpGet(URL, {}, (res) => {
                switch (type) {
                    case "read":
                        toFn(success)(res);
                        this.ReadMessages.splice(0, this.ReadMessages.length, ...res.data.data);
                        break;
                    case "unread":
                        toFn(success)(res);
                        this.UnReadMessages.splice(0, this.UnReadMessages.length, ...res.data.data);
                        break;
                }
            }, fail, complete);
        },
        initTask(URL) {
            if (URL) {
                setTimeout(() => {
                    this.loadMessage("unread", URL, res => {
                        var diff = differenceBy(res.data.data, this.UnReadMessages, x => x.ID);
                        if (diff.length > 6) {
                            Notification("新消息", `您有 ${diff.length} 条新的未读消息，请及时处理。`, "info", 0, {
                                btn: "点此查看"
                            });
                        } else {
                            diff.forEach(x => Notification(x.Title, x.Content, "info", 0));
                        }
                    }, null, () => this.initTask(URL));
                }, 20000);
            }
        }
    },
});