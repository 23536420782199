<template>
    <z-table v-bind="table" v-model="model"></z-table>
</template>

<script setup>
import zTable from "./table.vue";

import { isArray } from "lodash";
import { computed, getCurrentInstance, isReactive, reactive } from "vue";
import { RegisterEvent, toFn } from "../../utils/common";
import { Alert, Confirm, Toast } from "../../utils/dialog";
import { DownLoadByPost, HttpPost, HttpUpload } from "../../utils/network";
import { ZTableProperty } from "../../models/ZTableProperty";

const props = defineProps({
    Field: String,
    FileField: String,
    IgnoreLogin: { type: Boolean, default: null },
    ViewNewPage: { type: Boolean, default: null },
    Readonly: { type: Boolean, default: null },
    CanUpload: { type: Boolean, default: null },
    CanDelete: { type: Boolean, default: null },
    CanDownload: { type: Boolean, default: null },
    /** 是否自动保存 */
    AutoSave: { type: Boolean, default: null },
    /** 批量下载文件名 */
    DownloadName: String,
    Height: { type: Number, default: 400 }
});

const model = defineModel();

const annexId = computed({
    get() {
        return model.value[props.Field || "AnnexId"];
    },
    set(value) {
        model.value[props.Field || "AnnexId"] = value;
    }
});

/** 附件列表
 * @type {import("vue").ComputedRef<Array>} 
 */
const files = computed({
    get() {
        if ((props.FileField || "AnnexFiles") in model.value == false)
            files.value = [];
        return model.value[props.FileField || "AnnexFiles"];
    },
    set(value) {
        if (isReactive(value))
            model.value[props.FileField || "AnnexFiles"] = value;
        else
            model.value[props.FileField || "AnnexFiles"] = reactive(value);
    }
});

const table = computed(() => {
    /** 表格数据
     * @type {import("vue").UnwrapNestedRefs<ZTableProperty>}
     */
    let result = {
        Field: props.FileField || "AnnexFiles",
        URL: `/Annex/${GetNoLoginKey(props.IgnoreLogin)}GetFileList?id=${annexId.value}`,
        Export: false,
        PageMode: false,
        BatchEdit: false,
        Height: props.Height,
        Tabar: [
            {
                Label: "上传文件",
                Action: UploadFile,
                Visible() {
                    return props.Readonly !== true && props.CanUpload !== false;
                }
            },
            {
                Label: "删除",
                Style: "danger",
                Action: DeleteFile,
                Visible() {
                    return props.Readonly !== true && props.CanDelete !== false;
                }
            },
            {
                Label: "批量下载",
                Action: BatchDownload,
                Visible() {
                    return props.CanDownload !== false;
                }
            }
        ],
        Columns: [
            { Label: "文件名称", Field: "FilePath", Type: "file", Readonly: true, Width: 400, ViewNewPage: props.ViewNewPage },
            { Label: "上传时间", Field: "CreateTime", Type: "datetime" }
        ]
    };
    return result;
});

/** 批量下载
 * @param {{FileName:string,FilePath:string}[]} select 表格选中项
 * @author anturin
 */
function BatchDownload(select) {
    if (select && select.length > 0) {
        DownLoadByPost("/Vue/BatchDownload", {
            fieldname: props.DownloadName || "附件下载-yyyyMMddHHmm",
            files: select.map(x => ({
                name: x.FileName.split('.')[0],
                src: x.FilePath
            }))
        });
    } else {
        Toast("请先选择要下载的附件！", "error");
    }
}

/** 上传文件
 * @author anturin
 */
function UploadFile() {
    if (props.AutoSave) {
        //即使提交
        HttpUpload(`/Annex/${GetNoLoginKey(props.IgnoreLogin)}UploadFile`, true, {
            AnnexId: annexId.value,
            source: 'file',
            store: 'Annex'
        }, undefined, res => {
            let fn = () => {
                annexId.value = res.data.Id;
                files.value.push(...res.data.files);
            };
            if (annexId.value != res.data.Id)
                RegisterEvent(getCurrentInstance(), "AnnexUpdateId", fn, props, res.data.Id);
            else
                fn();
        });
    } else {
        //一起提交
        HttpUpload(`/Vue/${GetNoLoginKey(props.IgnoreLogin)}Upload`, true, {
            source: 'file',
            store: 'Annex'
        }, undefined, res => {
            if (isArray(res.data))
                files.value.push(...res.data);
            else
                files.value.push(res.data);
        });
    }
}

/** 删除文件
 * @param {Array} select 选中项
 */
function DeleteFile(select) {
    if (select == null || select.length == 0)
        return Alert("请先选择要删除的项！", "error");
    Confirm("确定要删除这些附件？", e => {
        if (e.confirm) {
            let fnDelete = function () {
                files.value = files.value.filter(x => select.includes(x) == false);
            };
            if (props.AutoSave) {
                //即时提交
                HttpPost(`/Annex/${GetNoLoginKey(props.IgnoreLogin)}Delete`, {
                    annexid: annexId.value,
                    ids: select.map(x => x.Id)
                }, () => {
                    toFn(parent.AnnexFileChange)(annexId.value);
                    fnDelete();
                });
            } else {
                //一起提交
                fnDelete();
            }
        }
    })
}

/** 是否不需要登录
 * @returns {string}
 */
function GetNoLoginKey() {
    return props.IgnoreLogin === true ? "NoLogin" : "";
}

</script>