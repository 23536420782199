import { IsNullOrEmpty } from "../../utils/common";
import { HttpPost } from "../../utils/network";

/** 返回流程业务输入配置 */
function useFlowBusinessInput(props) {
    const flowName = {
        config: {
            Field: 'Name',
            Required: true
        },
        ...props
    };
    const flowBusiness = {
        config: {
            Field: "Business",
            Type: "select",
            Url: "/Genesis/Service/WorkFlow/GetBusiness"
        },
        ...props
    };
    const flowMessage = {
        config: {
            Field: "Message",
            NameField: "MessageName",
            Type: "popup",
            Code: "MSGTemplate"
        },
        ...props
    }
    return {
        flowName,
        flowBusiness,
        flowMessage
    };
}

function useFlowSystemInfo(props) {
    const flowCreator = {
        config: {
            Field: "Creator"
        },
        Readonly: true,
        ...props
    };
    const flowCreateTime = {
        config: {
            Type: "datetime",
            Field: "CreateTime"
        },
        Readonly: true,
        ...props
    };
    const flowEditor = {
        config: {
            Field: "Editor"
        },
        Readonly: true,
        ...props
    };
    const flowEditTime = {
        config: {
            Type: "datetime",
            Field: "EditTime"
        },
        Readonly: true,
        ...props
    };
    return {
        flowCreator,
        flowCreateTime,
        flowEditor,
        flowEditTime
    }
}

/** 返回流程节点输入配置 */
function useFlowNodeInput() {
    const nodeName = {
        config: {
            Field: "label",
            PlaceHolder: "请输入节点名称",
            Max: 8
        },
        branch: true,
        ukey: "Node"
    };
    const nodeCheckType = {
        config: {
            Type: "radio",
            Field: "CheckType",
            ColCount: 3,
            Options: [
                { Text: "会签", Value: "and" },
                { Text: "或签", Value: "or" },
                { Text: "手动", Value: "select" }
            ]
        },
        branch: true,
        ukey: "Node"
    };
    const nodeUserRole = {
        branch: true,
        ukey: "Node",
        config: {
            Type: "popup",
            Field: "Role",
            NameField: "RoleName",
            Code: "UserRoles",
            IsMult: true
        }
    };
    const nodeUser = {
        branch: true,
        ukey: "Node",
        config: {
            Type: "popup",
            Field: "User",
            NameField: "UserName",
            Code: "Users",
            IsMult: true,
            Param: {
                value: "code"
            }
        }
    };
    const nodeRelation = {
        branch: true,
        ukey: "Node",
        config: {
            Type: "checkbox",
            Field: "Relation",
            ColCount: 2,
            Options: [
                { Text: '直接主管', Value: 'superior' },
                { Text: '直系领导', Value: 'leader' }
            ]
        }
    };
    return {
        nodeName,
        nodeCheckType,
        nodeUserRole,
        nodeUser,
        nodeRelation
    }
}

/** 返回流程节点事件配置 */
function useFlowEventInput({ UserFields = [], ApproveEvents = [], RejectEvents = [], RetractEvents = [] }) {
    const ApproveEvent = {
        config: {
            Field: "ApproveEvent",
            Type: "select",
            EnumLabel: "Name",
            EnumValue: "Action",
            Options: [...ApproveEvents]
        }
    };
    const RejectEvent = {
        config: {
            Field: "RejectEvent",
            Type: "select",
            EnumLabel: "Name",
            EnumValue: "Action",
            Options: [...RejectEvents]
        }
    };
    const RetractEvent = {
        config: {
            Field: "RetractEvent",
            Type: "select",
            EnumLabel: "Name",
            EnumValue: "Action",
            Options: [...RetractEvents]
        }
    };
    const UserField = {
        config: {
            Field: "UserField",
            Type: "select",
            IsMult: true,
            Options: [...UserFields]
        }
    };
    return {
        ApproveEvent,
        RejectEvent,
        RetractEvent,
        UserField
    }
}

function useFlowEventInputAsync(business) {
    if (IsNullOrEmpty(business))
        return;
    HttpPost("/Genesis/Service/WorkFlow/GetBusinessEvent", {
        name: business
    }, res => {
    });
}

export {
    useFlowBusinessInput,
    useFlowSystemInfo,
    useFlowNodeInput,
    useFlowEventInput,
    useFlowEventInputAsync
}