<template>
    <div class="z-full-screen enlarge" @click="onFullScreenChange">
        <FullscreenExit1Icon v-if="isFullScreen" :size="19"></FullscreenExit1Icon>
        <Fullscreen1Icon v-else :size="19"></Fullscreen1Icon>
    </div>
</template>

<script setup>
import { FullscreenExit1Icon, Fullscreen1Icon } from 'tdesign-icons-vue-next';
import { onMounted, onUnmounted, ref } from 'vue';

const isFullScreen = ref(false);

const compatible = {
    /** 【标准】 【FireFox】 【Chrome，Safari，Opera】 【IE，Edge】 */
    /** 打开全屏 */
    open: ["requestFullscreen", "webkitRequestFullscreen", "mozRequestFullScreen", "msRequestFullscreen"],
    /** 退出全屏 */
    exit: ["exitFullscreen", "webkitExitFullscreen", "mozCancelFullScreen", "msExitFullscreen"],
    /** 全屏元素 */
    element: ["fullscreenElement", "webkitFullscreenElement", "mozFullScreenElement", "msFullscreenElement"],
    /** 是否支持全屏 */
    enabled: ["fullscreenEnabled", "webkitFullscreenEnabled", "mozFullScreenEnabled", "msFullscreenEnabled"],
    /** 全屏变化事件 */
    change: ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "MSFullscreenChange"],
    /** 全屏报错事件 */
    error: ["fullscreenerror", "webkitfullscreenerror", "mozfullscreenerror", "MSFullscreenError"],
};

/** 全屏切换 */
function onFullScreenChange() {
    if (isFullScreen.value)
        compatible.exit.forEach(fn => {
            if (fn in document)
                document[fn]();
        });
    else
        compatible.open.forEach(fn => {
            if (fn in document.documentElement)
                document.documentElement[fn]();
        });
}

/** 监听全屏状态变化 */
function listenFullScreenStatus() {
    isFullScreen.value = compatible.element.map(dom => !!document[dom]).includes(true);
}

onMounted(() => {
    listenFullScreenStatus();
    compatible.change.forEach(e => document.addEventListener(e, listenFullScreenStatus));
});

onUnmounted(() => {
    compatible.change.forEach(e => document.removeEventListener(e, listenFullScreenStatus));
});
</script>

<style scoped>
.z-full-screen {
    cursor: pointer;
}
</style>