import { isArray, join, reject, remove } from "lodash";
import { HasContent, IsNullOrEmpty, IsNullOrEmptyOrZero, RegisterEvent, toFn } from "../../../utils/common";
import { Alert, AlertExtend, Confirm, Loading, OpenFormDialog, Toast } from "../../../utils/dialog";
import { DownLoadByPostForJson, HttpGet, HttpUpload } from "../../../utils/network";
import { useAppStore } from "../../../stores/useAppStore";
import { PageConfig } from "../../../config/pageconfig";
import { BusinessQuery, IsGenesisQuery } from "../../../utils/business";
import { GetEnum, GetEnumForUrl } from "../../../hooks/useEnumComponent";
import { bindDefault } from "../../../hooks/useInputComponent";
import { nextTick, reactive } from "vue";

/** 是否是系统列，很多业务不处理
 * @param {import('vxe-table').VxeColumnProps} column
 */
function isSystemColumn(column) {
    if (HasContent(column.params))
        return false;
    return ["checkbox", "seq", undefined].includes(column.type);
}


/** 表格导出
 * @param {import("vxe-table").VxeGridInstance} grid 
 */
function TableExport(grid) {
    RegisterEvent(grid, "TableExport", function (config, data) {
        let printData,
            select = grid.getCheckboxRecords(),
            fnPrint = (pconf, pdata, pquery) => {
                let query = null;
                if (pquery) {
                    query = { ...pquery };
                    if (IsGenesisQuery(pconf.URL)) {
                        Object.assign(query, {
                            from__path: PageConfig.ConfigPath,
                            from__scheme: PageConfig.SchemeID,
                            ignore__auth: pconf.IgnoreAuth
                        });
                    }
                } else if (HasContent(pdata)) {
                    let fields = [];
                    pdata = JSON.parse(JSON.stringify(pdata));
                    if (HasContent(pconf.Key)) {
                        fields.push(pconf.Key);
                    }
                    pconf.Columns.forEach(column => {
                        [column.Field, column.NameField, column.BeginField, column.EndField].forEach(field => {
                            if (HasContent(field))
                                fields.push(field);
                        });
                        if (column.Type == "checkbox" && column.IsSingle && column.OptionRender) {
                            let fKey = `$ckRender.${column.Field}`;
                            fields.push(fKey);
                            try {
                                var render = eval(column.OptionRender);
                                if (typeof render == "function")
                                    pdata.forEach(x => x[fKey] = render.cancel({ data: x }, column));
                                else
                                    pdata.forEach(x => x[fKey] = String(column.OptionRender));
                            } catch {
                                pdata.forEach(x => x[fKey] = column.OptionRender);
                            }
                        }
                    });
                    pdata = JSON.parse(JSON.stringify(pdata, (key, value) => {
                        if (HasContent(value)) {
                            if (typeof value == "object")
                                return value;
                            if (fields.includes(key))
                                return value;
                        }
                        return undefined;
                    }));
                }
                //使用基本控制器，方便调用本地取数逻辑
                DownLoadByPostForJson("ExportExcel", {
                    Config: pconf,
                    Data: pdata,
                    Query: query
                });
            };
        if (HasContent(select) && select.length > 0) {
            printData = select;
        } else {
            //未选择就全部导出
            if (config.PageMode !== false) {
                //分页询问导出范围
                let id = Date.now(),
                    message = "请选择导出范围",
                    app = useAppStore(),
                    content = app.config.Content,
                    isAccurate = IsGenesisQuery(config.URL) && "Query" in app.config
                        && content.URL == config.URL && content.Field == config.Field
                        && config.branch !== true && IsNullOrEmpty(config.ukey) && IsNullOrEmpty(config.pField);
                isAccurate = false;
                if (isAccurate) {
                    let controls = [];
                    if (HasContent(app.config.Query.Content)) {
                        app.config.Query.Content.forEach(x => {
                            if (HasContent(x.Type) == false || x.Type == "text")
                                controls.push({ Text: x.Label, Value: x.Field });
                        });
                    }
                    window[`$tableexportinfos${id}`] = {
                        Controls: controls,
                        Print: {
                            Config: config,
                            Query: {
                                from__path: PageConfig.ConfigPath,
                                ignore__auth: config.IgnoreAuth,
                            }
                        }
                    };
                    message += `，使用<a href='javascript:void();' onclick='Business.RangeQueryExport(${id})精准查询</a>导出`
                }
                return Confirm(message, e => {
                    if (e.confirm) {
                        printData = data[config.Field].data;
                        //导出当前页
                        fnPrint(config, printData);
                    } else if (e.cancel) {
                        //导出全部页，不需要传数据，传查询条件
                        fnPrint(config, null, grid.props.params.GetQuery());
                    }
                    if (isAccurate)
                        delete window[`$tableexportinfos${id}`];
                }, {
                    confirmText: "当前页",
                    cancelText: "全部页",
                    dangerouslyUseHTMLString: isAccurate
                });
            } else {
                printData = data;
            }
        }
        fnPrint(config, printData);
    }, grid.props.params, grid.props.params.GetModelValue());
}

/** 表格导入
 * @param {import("vxe-table").VxeGridInstance} grid 
 */
function TableImport(grid) {
    let config = grid.props.params;
    var loading = Loading("正在上传文件，请稍后……");
    HttpUpload("/Vue/ImportExcel", false, {
        source: "excel",
        config: JSON.stringify(config)
    }, undefined, res => {
        // 没有导入数据，不需要按控件解析
        if (res.data.length == 0) {
            loading.close();
            return;
        }
        let tasks = ImportAnalysis(config, res.data);
        loading.text = "正在解析并校验Excel数据，请稍后……";
        Promise.all(tasks).then(analyses => {
            try {
                let row, rowKey, analysis, value, error = [];
                for (let rIndex = 0; rIndex < res.data.length; rIndex++) {
                    row = res.data[rIndex];
                    for (let aIndex = 0; aIndex < analyses.length; aIndex++) {
                        analysis = analyses[aIndex];
                        if (typeof analysis.field == "string") {
                            if (analysis.field in row)
                                row[analysis.field] = analysis.map[row[analysis.field]];
                        }
                        else {
                            rowKey = row[analysis.key];
                            if (HasContent(rowKey)) {
                                for (let key in analysis.field) {
                                    value = analysis.field[key];
                                    var matchKey = Object.keys(analysis.map).find(key => String(key).toLocaleLowerCase() == String(rowKey).toLocaleLowerCase());
                                    if (matchKey) {
                                        row[analysis.key] = matchKey;
                                        row[key] = analysis.map[matchKey][value];
                                    } else {
                                        if (analysis.column.Type == "popup" && HasContent(row[analysis.column.Field])) {
                                            //弹窗组件，数据已经有值的情况下。不在做匹配操作
                                        } else {
                                            error.push({ message: `第${rIndex + 1}行，【${analysis.column.Label}】未找到匹配项！` });
                                        }
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
                if (error.length > 0) {
                    AlertExtend("Excel解析失败！", "error", { chinese: { message: "错误信息" }, data: error }, loading.close);
                    return;
                } else {
                    let model = config.GetModelValue(),
                        data;
                    if (config.PageMode !== false) {
                        if (model[config.Field]) {
                            if (model[config.Field].data)
                                data = model[config.Field].data;
                            else
                                data = model[config.Field].data = reactive([]);
                        } else {
                            model[config.Field] = reactive({ data: [] });
                            data = model[config.Field].data;
                        }
                    } else {
                        if (model[config.Field])
                            data = model[config.Field];
                        else
                            data = model[config.Field] = reactive([]);
                    }
                    if (data.length > 0) {
                        Confirm("将如何处理导入的数据？", e => {
                            if (e.close) {
                                // 关闭
                            }
                            else if (e.confirm) {
                                // 覆盖
                                var arr = data,
                                    ids = res.data.map(x => x[config.Key]);
                                for (let i = 0; i < arr.length; i++) {
                                    var id = arr[i][config.Key];
                                    if (IsNullOrEmptyOrZero(id))
                                        continue;
                                    var index = ids.lastIndexOf(String(id));
                                    if (index >= 0) {
                                        Object.assign(arr[i], res.data[index]);
                                    }
                                }
                                res.data.forEach(row => {
                                    var id = row[config.Key];
                                    if (IsNullOrEmptyOrZero(id)) {
                                        arr.push(row);
                                    }
                                });
                            }
                            else if (e.cancel) {
                                // 追加
                                res.data.forEach(row => delete row[config.Key]);
                            }
                            loading.close();
                            return;
                        }, { confirmText: "覆盖", cancelText: "追加" });
                    } else {
                        data.push(...res.data);
                        loading.close();
                    }
                }
            } catch (e) {
                reject(e);
            }
        }).catch(() => {
            Alert("导入失败！", "error");
            loading.close();
        });
    }, loading.close);
}

/** 根据控件类型解析导入数据
 * @param {Object} table 表格配置
 * @param {Array} import_data 导入的数据
 * @returns {Promise[]}
 */
function ImportAnalysis(table, import_data) {
    let tasks = [];
    for (let cIndex = 0; cIndex < table.Columns.length; cIndex++) {
        switch (table.Columns[cIndex].Type) {
            case "switch":
                tasks.push(new Promise(resolve => {
                    let column = table.Columns[cIndex];
                    resolve({ column, field: column.Field, map: { 是: true, 否: false } });
                }));
                break;
            case "popup":
                tasks.push(new Promise((resolve, reject) => {
                    let dialog,
                        column = table.Columns[cIndex];
                    if (column.Code && typeof Business != "undefined" && column.Code in Business.DialogCode)
                        dialog = Business.DialogCode[column.Code];
                    else
                        dialog = column.Url;
                    if (IsNullOrEmpty(dialog)) {
                        reject("未找到弹窗信息！");
                        return;
                    }
                    HttpGet("GetVueConfig", { type: "list", path: dialog }, res => {
                        let config = res.data.Content,
                            url = config.URL;
                        if (HasContent(url)) {
                            if (/^\/|^http/.test(url)) {
                                //绝对地址，不用转换
                            } else {
                                //相对地址
                                let query = /\?.*$/.exec(url);
                                url = dialog.replace(/[^\/]+$/, url);
                                if (query) url += query[0];
                            }
                        }
                        let select = Array.from(new Set([config.Key, config.Name, ...Object.values(column.SelectMap || {})])),
                            values = Array.from(new Set(import_data.map(x => column.ShowCode || column.OnlyName ? x[column.Field] : x[column.NameField]).filter(x => !!x)));
                        if (values.length == 0) {
                            let field = {}, map = {};
                            if (column.OnlyName)
                                field[column.Field] = config.Name;
                            else {
                                field[column.Field] = config.Key;
                                field[column.NameField] = config.Name;
                            }
                            Object.assign(field, column.SelectMap);
                            resolve({
                                column, field, map,
                                key: column.ShowCode || column.OnlyName ? column.Field : column.NameField
                            });
                        } else {
                            let body = {
                                page: 1, limit: 99999,
                                $ImportQuery: {
                                    select: join(select, ","),
                                    where: `${column.ShowCode ? config.Key : config.Name} IN ('${join(Array.from(values), "','")}')`
                                }
                            };
                            BusinessQuery(url, body, {
                                from__path: dialog,
                                from__scheme: "",
                                ignore__auth: config.IgnoreAuth
                            }, undefined, res => {
                                let field = {}, map = {}, arr;
                                if (column.OnlyName)
                                    field[column.Field] = config.Name;
                                else {
                                    field[column.Field] = config.Key;
                                    field[column.NameField] = config.Name;
                                }
                                Object.assign(field, column.SelectMap);
                                if (config.PageMode !== false)
                                    arr = res.data.data;
                                else
                                    arr = res.data;
                                if (isArray(arr))
                                    arr.forEach(x => map[x[column.ShowCode ? config.Key : config.Name]] = x);
                                resolve({
                                    column, field, map,
                                    key: column.ShowCode || column.OnlyName ? column.Field : column.NameField
                                });
                            }, reject);
                        }
                    }, reject);
                }));
                break;
            case "select":
            case "radio":
            case "checkbox":
                /** 解析映射字典
                 * @param {Array} options 选项
                 * @param {String} label 选项名称字段
                 * @param {String} value 选项值字段
                 * @param {String} children 选项子节点字段
                 * @returns {Object} 映射字段
                 */
                let fnMap = function (options, label, value, children, map) {
                    map = map || {};
                    options.forEach(option => {
                        if (label in option)
                            map[option[label]] = option[value];
                        else
                            map[option[value]] = option[value];
                        if (children in option && HasContent(option[children]))
                            fnMap(option[children], label, value, children, map);
                    });
                    return map;
                };
                tasks.push(new Promise((resolve, reject) => {
                    let label = "Text", value = "Value", children = "Children",
                        column = table.Columns[cIndex],
                        field = column.Field;
                    if (column.Code) {
                        label = "EnumValue";
                        value = "EnumCode";
                        GetEnum(column.Code, res => {
                            let map = fnMap(res.data, label, value, children);
                            resolve({ column, field, map });
                        }, reject);
                    }
                    if (column.Url) {
                        if (column.EnumLabel)
                            label = column.EnumLabel;
                        if (column.EnumValue)
                            value = column.EnumValue;
                        if (column.EnumChildren)
                            children = column.EnumChildren;
                        GetEnumForUrl(column.Url, {}, res => {
                            let map = fnMap(res.data, label, value, children);
                            resolve({ column, field, map });
                        }, reject);
                    }
                    if (column.Options) {
                        let map = fnMap(column.Options, label, value, children);
                        resolve({ column, field, map });
                    }
                }));
                break;
            default:
                break;
        }
    }
    return tasks;
}

/** 批量修改
 * @param {import("vxe-table").VxeGridInstance} grid 
 */
function TableBatchEdit(grid) {
    let config = grid.props.params,
        column = grid.getCurrentColumn(),
        select = grid.getCheckboxRecords();
    if (TableReadonly(config)) {
        Toast("当前表格不允许编辑！", "error");
        return;
    }
    if (HasContent(select) == false || select.length == 0) {
        Toast("请先选择要批量修改的行！", "error");
        return;
    }
    if (HasContent(column)) {
        if (HasContent(column.params) == false || column.params.BatchEdit === false || TableColumnReadonly(config, column.params)) {
            Toast("当前列不支持批量修改！", "error");
            return;
        }
        RegisterEvent(this, "BatchEdit", () => {
            OpenFormDialog("批量修改", { ColCount: 1, Content: [column.params] }, {}, res => {
                select.forEach(row => {
                    //行只读，不做修改
                    if (TableColumnReadonly(config, column.params, row))
                        return;
                    //赋值合并带回来的数据
                    Object.assign(row, res);
                });
            }, { width: "450px" });
        }, column.params);
    } else {
        Toast("请先选择要批量修改的列！", "error");
        return;
    }
}

/** 表格重置
 * @param {import("vxe-table").VxeGridInstance} grid 表格对象
 */
function TableResetFilter(grid) {
    let tasks = [];
    tasks.push(...grid.getCheckedFilters().map(x => grid.clearFilter(x.column)));
    tasks.push(...grid.getSortColumns().map(x => grid.clearSort(x.column)));
    if (tasks.length > 0)
        Promise.all(tasks).then(() => grid.updateData());
}

/** 表格是否只读
 * @returns {Boolean}
 */
function TableReadonly(config) {
    return config.Mode != "edit" || config.Readonly;
}

/** 表格列是否只读
 * @param {Object} column 
 * @returns {Boolean}
 */
function TableColumnReadonly(config, column, row) {
    if (TableReadonly(config))
        return true;
    let readonly;
    if ("Readonly" in column)
        readonly = column.Readonly;
    else if ("ColumnDefaultReadonly" in config)
        readonly = config.ColumnDefaultReadonly;
    if (readonly === true)
        return true;
    // 动态可编辑列，根据数据进行优化
    if (HasContent(row))
        return RegisterEvent(this, "TableRenderReadonly", () => false, config, row, column);
    return false;
}

/** 表格新增列
 * @param {import("vxe-table").VxeGridInstance} grid 
 */
function TableAddRow(grid) {
    let config = grid.props.params;
    if (TableReadonly(config)) {
        Toast("当前表格不允许新增！", "error");
        return;
    }
    let data = config.GetModelValue(),
        newRow = RegisterEvent(grid, "TableAddRow", () => ({}), config, data);
    return bindDefault(config.Columns, config.GetModelValue()).finally(() => {
        if (config.PageMode !== false) {
            if (config.Field in data) {
                if (data[config.Field].data)
                    data[config.Field].data.push(newRow);
                else {
                    data[config.Field].data = reactive([newRow]);
                    data[config.Field].total = 1;
                }
            } else {
                data[config.Field] = reactive({
                    data: reactive([newRow]),
                    total: 1
                });
            }
        } else {
            if (data[config.Field])
                data[config.Field].push(newRow);
            else
                data[config.Field] = reactive([newRow]);
        }
        nextTick(() => {
            grid.scrollToRow(newRow);
            RowClick(grid, newRow);
        });
    });
}

/** 表格删除列
 * @param {import("vxe-table").VxeGridInstance} grid 
 */
function TableDeleteRow(grid) {
    let config = grid.props.params;
    if (TableReadonly(config)) {
        Toast("当前表格不允许删除！", "error");
        return;
    }
    let select = grid.getCheckboxRecords();
    if (HasContent(select) == false || select.length == 0) {
        Toast("请先选择要删除的行！", "error");
        return;
    }
    remove(grid.props.data, x => select.includes(x));
    var current = grid.getCurrentRecord();
    if (current && grid.props.data.includes(current)) {
        //当前行还在表格中，不切换当前行
    } else {
        //选中首行
        if (grid.props.data.length > 0)
            RowClick(grid, grid.props.data[0]);
        else
            grid.props.params.ClearCurrentRow();
    }
}

/** 表格复制列
 * @param {import("vxe-table").VxeGridInstance} grid 
 * @param {string[]} ignoreFields 忽略字段
 */
function TableCopyRow(grid, ignoreFields) {
    let config = grid.props.params;
    if (TableReadonly(config)) {
        Toast("当前表格不允许复制！", "error");
        return;
    }
    let select = grid.getCheckboxRecords();
    if (HasContent(select) == false || select.length == 0) {
        Toast("请先选择要复制的行！", "error");
        return;
    }
    select = JSON.parse(JSON.stringify(select, (key, value) => {
        if (key.startsWith("$") || key == config.Key || ignoreFields.includes(key) || value == null)
            return undefined;
        return value;
    }));
    return Promise.all(select.map(row => bindDefault(config.Columns, row)))
        .finally(() => {
            grid.props.data.push(...select);
            var last = select[select.length - 1];
            grid.scrollToRow(last);
            RowClick(grid, last);
        });
}

/** 行点击事件
 * @param {import("vxe-table").VxeGridInstance} grid 
 * @param {Object} row 
 */
function RowClick(grid, row, column) {
    nextTick(() => {
        var dom = (grid.$el || grid.instance.vnode.el);
        if (dom) {
            var domRow = dom.querySelector(`tr[rowid="${grid.getRowid(row)}"]>td`);
            if (domRow)
                domRow.click();
        }
    });
}

export {
    isSystemColumn,
    TableExport,
    TableImport,
    TableBatchEdit,
    TableResetFilter,
    TableAddRow,
    TableDeleteRow,
    TableCopyRow,
    TableReadonly,
    TableColumnReadonly,
    RowClick
}