import { parse } from "qs";
import { OpenFormDialog, Toast } from "../utils/dialog";
import { GetLocationURL, HttpGet, HttpPost, HttpPostForJson } from "../utils/network";
import { difference } from "lodash";
import { PageConfig } from "../config/pageconfig";
import { useAppStore } from "../stores/useAppStore";
import { EqualsObject,  HasContent, RegexMatch, toFn } from "./common";

const regexGenesisQuery = [/^(.+\/)?PageQuery$/, /^\/GetReportList\/.+$/];

/** 是否是平台通用查询 */
function IsGenesisQuery(uri) {
    return RegexMatch(uri, ...regexGenesisQuery) > -1;
}

class QueryOption {
    /** 是否有数据权限
     * @returns {Boolean}
     */
    get IgnoreAuth() { }
    /** 是否支持精准查询
     * @returns {Boolean}
     */
    get IsAccurate() { }
    /** 查询区域配置
     * @returns {Object}
     */
    get Query() { }
    /** Body是否使用Json请求
     * @returns {Boolean}
     */
    get PostForJson() { }
}

/**
 * 
 * @param {String} uri 业务查询地址
 * @param {Object} body 业务查询参数
 * @param {QueryOption} option 业务查询选项
 * @param {function():void} start 开始查询事件
 * @param {function(res):void} success 查询成功回调
 * @param {function(err):void} fail 查询失败回调
 * @param {function():void} complete 查询完成回调
 */
function BusinessQuery(uri, body, option, start, success, fail, complete) {
    if (HasContent(uri)) {
        body = body || {};
        if (IsGenesisQuery(uri)) {
            Object.assign(body, {
                from__path: option.from__path || PageConfig.ConfigPath,
                from__scheme: PageConfig.SchemeID,
                ignore__auth: option.IgnoreAuth
            });
        }
        let fnQuery = () => {
            toFn(start)();
            if (option.PostForJson) {
                HttpPostForJson(uri, body, success, fail, complete);
            } else {
                HttpPost(uri, body, success, fail, complete);
            }
        };
        if (option.IsAccurate) {
            if (option.Query) {
                let controls = option.Query.Content.filter(x => !x.Type || x.Type == "text");
                if (controls.length == 0) {
                    fnQuery();
                } else {
                    OpenFormDialog("范围查询", {
                        Content: [{
                            Label: "查询字段",
                            Field: "RangeField",
                            Type: "select",
                            Options: controls.map(x => ({ Text: x.Label, Value: x.Field }))
                        }, {
                            Label: "查询范围",
                            Field: "RangeValue",
                            Type: "text",
                            PlaceHolder: "请输入要查询的范围，值之间使用【换行】进行分割",
                            ColSpan: 3,
                            RowSpan: 20
                        }]
                    }, {}, res => {
                        if (HasContent(res.RangeField)) {
                            body[res.RangeField] = res.RangeValue.split("\n");
                            if (body[res.RangeField].length == 1) {
                                //只有一个的情况下，补充空白数据
                                body[res.RangeField].push("");
                            }
                            fnQuery();
                        } else {
                            Toast("请选择要查询的字段！", "error");
                            return false;
                        }
                    }, { FromRight: true });
                }
            } else {
                console.error("option缺少Query参数！");
                fnQuery();
            }
        }
        else {
            fnQuery();
        }
    }
}

/** 获取账套
 * @returns {String} 
 */
function GetAccountSet() {
    let win = window, accid;
    while (true) {
        if (win.location.search.length > 1) {
            accid = parse(win.location.search.substring(1)).accid;
            if (HasContent(accid))
                return accid;
        }
        if (win == win.parent)
            break;
        win = win.parent;
    }
}

/** 获取来源菜单编码
 * @returns {String}
 */
function GetFromMenu() {
    if (PageConfig.Query != undefined && "menuid" in PageConfig.Query)
        return PageConfig.Query.menuid;
    let uri = GetLocationURL();
    if (uri.search.length > 1)
        return parse(uri.search.substring(1)).menuid;
    else
        return null;
}

/** 刷新List页面的表格
 * @param {TurApp} app 
 */
function RefreshTable(app) {
    app = app || useAppStore();
    app.refs.crxContent.Refresh();
}

/** 业务查询基本参数 */
const BusinessPropterty = {
    /** 异步取数地址 */
    URL: String,
    /** 是否采用Json的数据结构调用POST接口 */
    PostForJson: { type: Boolean, default: null },
    /** 通用查询PageQuery/ReportList下，是否忽略数据权限 */
    IgnoreAuth: { type: Boolean, default: null },
}

const regex = /.*\//;

/** 加载配置文件 */
function LoadConfig(isCreate, param, success, complete) {
    if (isCreate && HasContent(PageConfig.Config))
        return;
    if (HasContent(PageConfig.ConfigPath)) {
        let config = useAppStore().config,
            body = {
                type: PageConfig.PageType,
                path: PageConfig.ConfigPath,
                scheme: PageConfig.SchemeID,
                ismult: PageConfig.IsMult,
                ...PageConfig.ConfigParam,
                ...param
            };
        HttpGet(`${regex.exec(PageConfig.ConfigPath)[0]}GetVueConfig`, body,
            res => {
                if (isCreate)
                    Object.assign(config, res.data);
                else {
                    let cKeys = Object.keys(config),
                        dKeys = Object.keys(res.data),
                        diffKeys = difference(cKeys, dKeys);
                    diffKeys.forEach(key => delete config[key]);
                    dKeys.forEach(key => {
                        if (EqualsObject(config[key], res.data[key]) == false)
                            config[key] = res.data[key];
                    });
                    toFn(success)();
                }
            }, null, toFn(complete));
    } else {
        toFn(complete)();
    }
}

export {
    IsGenesisQuery,
    BusinessQuery,
    GetAccountSet,
    GetFromMenu,
    RefreshTable,
    BusinessPropterty,
    LoadConfig
}