<template>
    <div class="z-pages-list">
        <auto-height @change="value => Content.Height = value">
            <template #header>
                <z-query ref="crxTabar" v-bind="$props" v-model:query="queryModel" v-model="model"
                         @query="onQuery" @change="onQueryChange" @click="onClick"></z-query>
            </template>
            <template #default="{ height }">
                <div :style="{ height: `${height}px`, display: 'flex' }">
                    <a-resize-box :directions="['right']" v-if="TreeQuery" :height="height"
                                  v-model:width="treeQueryWidth" @update:width="onResizeEnd">
                        <z-tree ref="crxTreeQuery" v-bind="TreeQuery" v-model="queryModel[TreeQuery.Field]"
                                @change="onTreeQuery"></z-tree>
                    </a-resize-box>
                    <div style="overflow-y: auto; flex: 1;">
                        <z-table ref="crxContent" v-bind="Content" v-model="model" :WaitLoad="waitLoad"
                                 :BaseQuery="PageConfig.Query"></z-table>
                        <template v-if="Contents">
                            <template v-for="table in Contents" :key="table.Field">
                                <z-table :ref="`crxContent_${table.Field}`" v-bind="table"
                                         v-model="model"></z-table>
                            </template>
                        </template>
                    </div>
                </div>
            </template>
        </auto-height>
    </div>
</template>

<script setup>
import autoHeight from '../src/components/base/auto-height.vue';
import zTree from "../src/components/base/tree.vue";
import zQuery from "../src/components/custom/query.vue";
import zTable from "../src/components/base/table.vue";

import { GetStorageSync, RegisterEvent, SetStorageAsync, toFn } from '../src/utils/common';
import { computed, getCurrentInstance, onMounted, reactive, ref } from 'vue';
import { Alert } from '../src/utils/dialog';
import { appsettings } from '../appsettings';
import { GetFromMenu, LoadConfig } from '../src/utils/business';
import { useStickyToolStore } from '../src/stores/useStickyToolStore';
import { PageConfig } from '../src/config/pageconfig';
import { GetLocationURL } from '../src/utils/network';
import { debounce } from 'lodash';

const props = defineProps({
    /**
     * 页面类型
     */
    pagetype: String,
    /**
     * 是否自定义配置
     */
    IsCustomConfig: Boolean,
    /**
     * 查询参数
     */
    Query: Object,
    /**
     * 树查询参数
     */
    TreeQuery: Object,
    /**
     * 按钮参数
     */
    Tabar: Object,
    /**
     * 内容参数
     */
    Content: Object,
    /**
     * 内容参数
     */
    Contents: Array
});

const model = defineModel();
const queryModel = reactive({ ...PageConfig.Query });

const crxContent = ref(null);
/** 是否完成查询 */
const isDoneQuery = ref(true);

const waitLoad = computed(() => {
    return props.Query && props.Query.Content && props.Query.Content.length > 0;
});

function onQuery(value, isAccurateQuery) {
    let res = null;
    RegisterEvent(getCurrentInstance(), "Query", function () {
        //重新加载页面
        if (props.Query.ReLoadConfig) {
            LoadConfig(false, value);
        }
        res = crxContent.value.SetQuery(value, true, () => {
            isDoneQuery.value = true;
        }, isAccurateQuery);
    }, props, value, res);
}

function onQueryChange(control, data) {
    RegisterEvent(getCurrentInstance(), "QueryChange", () => {
        isDoneQuery.value = false;
    }, control, data);
}

function onTreeQuery() {
    if (props.TreeQuery.AutoQuery) {
        crxContent.value.SetQuery(queryModel, null, () => {
            isDoneQuery.value = true;
        });
    }
}

function onClick(btn) {
    let select = crxContent.value.GetSelect();
    RegisterEvent(getCurrentInstance(), "ListTabarClick", function () {
        toFn(btn)(select, model.value, btn);
    }, btn, select, model.value);
};

const treeQueryWidth = ref(0);

/**
 * @param {number} value 顺序模块的宽度大小
 */
const onResizeEnd = debounce(value => {
    let code = `treequery_width${GetLocationURL().pathname}\/${GetFromMenu() || ""}`;
    SetStorageAsync(code, value);
}, 200);

onMounted(() => {
    if (appsettings.Page.HelpButton)
        useStickyToolStore().AddStandardStickyTool("help");
    if (props.TreeQuery) {
        let code = `treequery_width${GetLocationURL().pathname}\/${GetFromMenu() || ""}`;
        treeQueryWidth.value = GetStorageSync(code) || props.TreeQuery.Width || 300;
    }
});

defineExpose({
    CheckQuery() {
        if (isDoneQuery.value == false) {
            Alert("查询条件已变更，请先执行查询操作！", "warning");
            return false;
        }
        return true;
    },
    SetQuery(value) {
        Object.assign(queryModel, value);
        crxContent.value.SetQuery(...arguments);
    },
    Refresh(value) {
        Object.assign(queryModel, value);
        crxContent.value.Refresh(...arguments);
    },
    OnQuery() {
        Object.assign(queryModel, value);
        crxContent.value.OnQuery(...arguments);
    },
    OnceQuery() {
        crxContent.value.OnceQuery(...arguments);
    },
    GetQuery() {
        return crxContent.value.GetQuery(...arguments);
    },
    GetSelect() {
        return crxContent.value.GetSelect(...arguments);
    },
    get QueryData() {
        return crxContent.value.QueryData;
    }
})
</script>

<style scoped>
.z-pages-list {
    height: 100%;
}
</style>