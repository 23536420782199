import { CopyToClipboard } from "../utils/common";
import {
    Toast,
    Alert,
    AlertExtend,
    Loading,
    Confirm,
    ConfirmExtend,
    OpenPage,
    OpenDialog,
    OpenFormDialog,
    OpenWindow,
    ClosePage,
    CloseDialog,
    CloseFormDialog,
    RefreshPage,
    RefreshData
} from "../utils/dialog";

window.CopyToClipboard = CopyToClipboard;

window.Toast = Toast;
window.Alert = Alert;
window.AlertExtend = AlertExtend;
window.Loading = Loading;
window.Confirm = Confirm;
window.ConfirmExtend = ConfirmExtend;
window.OpenPage = OpenPage;
window.OpenDialog = OpenDialog;
window.OpenFormDialog = OpenFormDialog;
window.OpenWindow = OpenWindow;
window.ClosePage = ClosePage;
window.CloseDialog = CloseDialog;
window.CloseFormDialog = CloseFormDialog;
window.RefreshPage = RefreshPage;
window.RefreshData = RefreshData;