<template>
    <z-button v-bind="button" v-model="model" @click="onClick"></z-button>
</template>

<script setup>
import { HasContent, RegisterEvent, toFn } from "../../../utils/common";
import { Loading } from "../../../utils/dialog";
import { HttpUpload } from "../../../utils/network";
import zButton from "../../base/button.vue";
import { TableExport, TableImport, TableBatchEdit, TableResetFilter, TableAddRow, TableCopyRow, TableDeleteRow } from "./action";

/**
 * @type {{
 *  grid: import("vxe-table").VxeGridInstance,
 *  table: import("vxe-table").VxeTableInstance,
 *  button: import("vxe-table").VxeButtonProps,
 *  align: String
 * }}
 */
const props = defineProps({
    /** 表格对象 */
    grid: Object,
    /** 表格对象 */
    table: Object,
    /** 表格按钮 */
    button: Object,
    /** 对齐方式 */
    align: String
});

const model = defineModel();

function onClick(btn) {
    let action = btn.Action;
    if (HasContent(action)) {
        if (typeof action != "function") {
            switch (String(action)) {
                case "$export":
                    return TableExport(props.grid);
                case "$import":
                    return TableImport(props.grid);
                case "$batchEdit":
                    return TableBatchEdit(props.grid);
                case "$reset":
                    return TableResetFilter(props.grid);
            }
        }
        // 获取表格配置信息
        let config = props.grid.props.params,
            select = props.grid.getCheckboxRecords();

        RegisterEvent(props.grid, "TableTabarClick", function () {
            switch (String(action)) {
                case "AddRow":
                    return TableAddRow(props.grid);
                case "DeleteRow":
                    return TableDeleteRow(props.grid);
                case "CopyRow":
                    return TableCopyRow(props.grid, btn.Paramenters ? btn.Paramenters.Ignore : []);
                default:
                    if (btn.Type == "file") {
                        var loading = Loading("正在上传文件，请稍候……");
                        HttpUpload(btn.Url, btn.IsMult, {}, null, res => {
                            loading.close();
                            toFn(action).call(props.grid, select, res.data, btn, config);
                        }, loading.close);
                    }
                    else
                        toFn(action).call(props.grid, select, config.GetModelValue(), btn);
            }
        }, config, btn, select, config.GetModelValue());
    }
}
</script>