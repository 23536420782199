<template>
  <div v-if="Type == 'image' && ShowPreview" class="z-file">
    <div
      ref="avatar"
      :style="{
        height: ImgRatio ? imgWidth * ImgRatio + 'px' : '100px',
        'background-color': HasContent(model) ? 'rgb(243,244,246)' : '',
        'justify-content': HasContent(model) ? 'center' : 'flex-start',
      }"
      class="avatar-uploader"
      @click="onUpload">
      <template v-if="HasContent(model)">
        <!-- 非只读，有图片 -->
        <t-image-viewer :images="[GetImageView(model)]">
          <template #trigger="{ open }">
            <div class="tdesign-demo-image-viewer__ui-image">
              <t-image :src="GetThumbnail(model)" fit="contain" style="z-index: 1" class="tdesign-demo-image-viewer__ui-image--img" />
              <div class="tdesign-demo-image-viewer__ui-image--hover" style="z-index: 2">
                <span @click.stop="open">
                  <BrowseIcon size="35px" />
                </span>
                <span @click.stop="onUpload" v-if="Readonly !== true">
                  <ImageEditIcon size="30px" style="margin-left: 15px" />
                </span>
                <span @click.stop="onDelete" v-if="Readonly !== true">
                  <CloseIcon size="40px" style="margin-left: 10px" />
                </span>
              </div>
            </div>
          </template>
        </t-image-viewer>
      </template>
      <div v-else style="width: 100%; height: 100%">
        <!-- 非只读，没图片 -->
        <div v-if="!Readonly" class="imageUpload">
          <AddIcon size="35px" class="addIcon" />
        </div>
        <!-- 只读，没图片 -->
        <div v-else class="error">暂未上传图片</div>
      </div>
    </div>
  </div>
  <div v-else class="z-file">
    <t-input-adornment>
      <t-input v-model="filename" ref="crxFileInput" size="small" readonly placeholder="" @click="onClick">
        <template v-if="Readonly !== true && model" #suffixIcon>
          <div @click.stop="onDelete">
            <CloseCircleFilledIcon></CloseCircleFilledIcon>
          </div>
        </template>
      </t-input>
      <template v-if="Readonly !== true" #append>
        <t-button size="small" @click="onUpload" content="上传">
          <template #icon>
            <Upload1Icon></Upload1Icon>
          </template>
        </t-button>
      </template>
    </t-input-adornment>
  </div>
</template>

<script setup>
import { onBeforeUnmount, ref, watch, onMounted, nextTick } from "vue";
import { GetFileName, HasContent, GetThumbnail, GetImageView } from "../../utils/common";
import { Confirm, OpenPage, OpenWindow } from "../../utils/dialog";
import { HttpUpload } from "../../utils/network";
import { PageConfig } from "../../config/pageconfig";
import { CloseCircleFilledIcon, Upload1Icon, AddIcon, CloseIcon, ImageEditIcon, BrowseIcon } from "tdesign-icons-vue-next";
import { setting } from "../../config/appsetting";
import { ViteSetting } from "../../config/vitesetting";
import { debounce } from "lodash";

const props = defineProps({
  /** 控件类型 */
  Type: String,
  /** 是否显示预览图，仅在图片下生效 */
  ShowPreview: { type: Boolean, default: null },
  /** 是否在新的页面预览 */
  ViewNewPage: { type: Boolean, default: null },
  /** 是否只读 */
  Readonly: { type: Boolean, default: null },
  /** 忽略登录 */
  IgnoreLogin: { type: Boolean, default: null },
  /** 存放位置 */
  Store: String,
  /** 字段 */
  Field: String,
  /** 控件宽高比 */
  ImgRatio: Number,
});

const emits = defineEmits(["change"]);

/** 文件路径 */
const model = defineModel({ type: String });
/** 文件名 */
const filename = ref("");
/** 文件后缀匹配正则表达式 */
const regFileEx = /[^\.]*$/;
/**
 * @type {import("vue").Ref<{inputRef:HTMLInputElement}>}
 */
const crxFileInput = ref();

/** 预览 */
function onView() {
  if (model.value) {
    let ex = model.value.match(regFileEx),
      src = `${PageConfig.ViewFileServer}${model.value}`;
    if (HasContent(ex)) ex = ex[0];
    if (setting.component.file.view.includes(ex)) {
      if (props.ViewNewPage) OpenWindow("预览", src);
      else OpenPage("预览", src);
    } else {
      Confirm("当前格式无法预览，是否直接下载？", (res) => {
        if (res.confirm) {
          let dom = document.createElement("a");
          if (ViteSetting.IsApp) dom.href = `/${ViteSetting.ApiPrefix}${src}`;
          else dom.href = src;
          dom.download = "download";
          dom.click();
        }
      });
    }
  }
}

function onClick({ e }) {
  let len = HasContent(filename.value) ? filename.value.length : 0;
  if (e.target.selectionStart < len && e.target.selectionEnd < len) {
    onView();
    e.preventDefault();
  }
}

function onUpload() {
  if (props.Readonly) return;
  HttpUpload(
    `/Vue/${props.IgnoreLogin === true ? "NoLogin" : ""}Upload`,
    false,
    {
      source: props.Type,
      store: props.Store,
    },
    undefined,
    (res) => {
      filename.value = res.data.name;
      let oldValue = model.value;
      model.value = res.data.src;
      emits("change", res.data.src, oldValue);
    }
  );
}

function onDelete() {
  let oldValue = model.value;
  model.value = null;
  filename.value = "";
  emits("change", model.value, oldValue);
}

/** 绑定内部值 */
function bindValue(value, before) {
  filename.value = GetFileName(value);
}

const stopWatchs = [watch(() => model.value, bindValue, { immediate: true })];

const avatar = ref();
const imgWidth = ref(100);

const observer = new ResizeObserver(
  debounce(() => {
    if (avatar.value) imgWidth.value = avatar.value.getBoundingClientRect().width;
  })
);

onMounted(() => {
  nextTick(() => {
    if (avatar.value) observer.observe(avatar.value);
  });
});

onBeforeUnmount(() => {
  stopWatchs.forEach((stop) => stop());
  observer.disconnect();
});
</script>

<style name="avatar" scoped>
.avatar-uploader .t-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  height: 100px;
  width: 100px;
}

.avatar-uploader .avatar {
  max-width: 100%;
  max-height: 100%;
}

.avatar-uploader .t-icon-remove {
  position: absolute;
  top: 2px;
  left: 80px;
  font-size: 20px !important;
  color: var(--td-error-color);
}

.avatar-uploader .t-icon-zoom-in {
  position: absolute;
  bottom: 2px;
  left: 80px;
  font-size: 20px !important;
  color: gray;
}
</style>

<style name="file" scoped>
.z-file {
  width: 100%;
}

.z-file :deep(.t-input.t-is-readonly),
.z-file :deep(.t-input.t-is-readonly .t-input__inner) {
  cursor: pointer;
  color: var(--td-brand-color);
}

.z-file :deep(.t-input.t-input--suffix > .t-input__suffix-icon) {
  display: none;
}

.z-file:hover :deep(.t-input.t-input--suffix > .t-input__suffix-icon) {
  display: initial;
}

.z-file :deep(.t-input.t-input--suffix > .t-input__suffix-icon svg) {
  color: var(--td-error-color);
  cursor: pointer;
}

.upload {
  margin: 5px;
}

.avatar-uploader {
  display: flex;
  align-items: center;
}

.imageUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.imageUpload .addIcon {
  color: var(--t-color-info);
}

.imageUpload:hover {
  border: 2px dashed var(--td-brand-color-1);
  background-color: #efefef;
}

.image {
  position: relative;
  width: 100px;
  height: 100px;
  margin: auto;
}

.icon {
  position: absolute;
  right: 0;
}

.tdesign-demo-image-viewer__ui-image {
  width: 100%;
  height: 100%;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: var(--td-radius-small);
  overflow: hidden;
}

.tdesign-demo-image-viewer__ui-image--hover {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--td-text-color-anti);
  line-height: 22px;
  transition: 0.2s;
}

.tdesign-demo-image-viewer__ui-image:hover .tdesign-demo-image-viewer__ui-image--hover {
  opacity: 1;
  cursor: pointer;
}

.tdesign-demo-image-viewer__ui-image--img {
  width: auto;
  height: 100%;
  cursor: pointer;
  position: absolute;
}

.tdesign-demo-image-viewer__ui-image--footer {
  padding: 0 16px;
  height: 56px;
  width: 100%;
  line-height: 56px;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  color: var(--td-text-color-anti);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  box-sizing: border-box;
}

.tdesign-demo-image-viewer__ui-image--title {
  flex: 1;
}

.tdesign-demo-popup__reference {
  margin-left: 16px;
}

.tdesign-demo-image-viewer__ui-image--icons .tdesign-demo-icon {
  cursor: pointer;
}

.tdesign-demo-image-viewer__base {
  width: 160px;
  height: 160px;
  margin: 10px;
  border: 4px solid var(--td-bg-color-secondarycontainer);
  border-radius: var(--td-radius-medium);
}

.error {
  width: 200px;
  height: 100px;
  background-color: rgb(243, 243, 243);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #ccc;
}
</style>
