import { reactive, ref } from "vue";
import { EqualsObject, IsNullOrEmpty } from "../../../utils/common";
import { debounce } from "lodash";

/**
 * @param {Object} param0
 * @param {import("vue").Ref<string>} param0.keyword
 * @param {(event: "change" | "update:modelValue", value)=> void} param0.emits
 * @param {import("vue").Ref<Array>} param0.suggestions
*/
export function useMultiplePopup({ keyword, emits, suggestions, searchSuggestionAsync, clearSuggestionAsync, SetValue }) {
    /**
     * @type {import("tdesign-vue-next").TagInputProps}
     */
    const tagInputProps = {
        clearable: true,
        minCollapsedNum: 1,
        excessTagsDisplayType: "scroll",
        size: "small",
        tagProps: {
            size: "small",
            closable: false
        },
        onBlur() {
            keyword.value = "";
            clearSuggestionAsync();
        },
        onClear() {
            SetValue([]);
        },
        /**
         * @param {string} value
         * @param {import("tdesign-vue-next").InputValueChangeContext} context 
         */
        onInputChange(value, context) {
            switch (context.trigger) {
                case "enter":
                    if (suggestions.value.length == 1)
                        SetValue(suggestions.value[0]);
                    break;
                case "clear":
                    clearSuggestionAsync();
                    break;
                case "input":
                    if (IsNullOrEmpty(value)) {
                        clearSuggestionAsync();
                    } else {
                        clearSuggestionAsync.cancel();
                        searchSuggestionAsync(value);
                    }
                    break;
            }
        }
    }

    const models = ref([]);

    /**
     * @param {Array} values
     */
    function setValue(values) {
        if (EqualsObject(values, models.value)) {
            return false;
        } else {
            models.value = values;
            return true;
        }
    }

    /**
     * 检查数据，不正确直接删除，直接清楚 keyword 即可，多选不需要检查错误数据
     * @author anturin
     */
    function checkData() {
        keyword.value = "";
    }

    /**
     * 检查数据，不正确直接删除
     * @description 点击弹窗的时候，先触发blur事件，再触发open。在open事件中取消校验 
     * @author anturin
     */
    const checkDataAsync = debounce(checkData, 200);

    return {
        models,
        tagInputProps,
        checkData,
        setValue,
        checkDataAsync
    }
}