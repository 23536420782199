<template>
    <div class="container">
        <div ref="crxStencil" class="stencil-container" v-if="Readonly !== false"></div>
        <div ref="crxGraph" :class="`graph-container ${Readonly ? 'readonly' : ''}`"></div>
        <div ref="crxProp" class="prop-container">
            <div class="graph-form">
                <t-tabs v-model="tabIndex">
                    <t-tab-panel label="属性" value="prop" v-if="IsNodeType(['leader', 'filter'])">
                        <t-form labelAlign="top">
                            <t-form-item label="节点名称">
                                <z-input v-bind="nodeName" v-model="currentValue" :Readonly="Readonly"
                                         @change="changeNodeName"></z-input>
                            </t-form-item>
                            <template v-if="IsNodeType('leader')">
                                <t-form-item label="审核类型">
                                    <z-input v-bind="nodeCheckType" v-model="currentValue"
                                             :Readonly="Readonly"></z-input>
                                </t-form-item>
                                <template v-if="isSelectUser() == false">
                                    <t-form-item label="单据人员">
                                        <z-input v-bind="nodeUserFields" v-model="currentValue"
                                                 :Readonly="Readonly"></z-input>
                                    </t-form-item>
                                    <t-form-item label="角色">
                                        <z-input v-bind="nodeUserRole" v-model="currentValue"
                                                 :Readonly="Readonly"></z-input>
                                    </t-form-item>
                                    <t-form-item label="人员">
                                        <z-input v-bind="nodeUser" v-model="currentValue"
                                                 :Readonly="Readonly"></z-input>
                                    </t-form-item>
                                    <t-form-item label="关系">
                                        <z-input v-bind="nodeRelation" v-model="currentValue"
                                                 :Readonly="Readonly"></z-input>
                                    </t-form-item>
                                </template>
                            </template>
                            <template v-else>
                                <t-form-item label="条件">
                                    <t-button type="text" @click="SetCondition">
                                        {{ currentCondition.length > 0 ? "已设置" : "未设置" }}
                                    </t-button>
                                </t-form-item>
                                <t-form-item label="不满足操作">
                                    <t-radio-group v-model="currentFalseType" :readonly="Readonly"
                                                   @change="ChangeFalseType">
                                        <t-radio value="next">继续环节</t-radio>
                                        <t-radio value="back">回退环节</t-radio>
                                        <t-radio value="draft">退回发起人</t-radio>
                                    </t-radio-group>
                                </t-form-item>
                            </template>
                        </t-form>
                    </t-tab-panel>
                    <t-tab-panel label="基础信息" value="base" v-else>
                        <t-form labelAlign="top">
                            <t-form-item label="名称" requiredMark>
                                <z-input v-bind="flowName" v-model="model" :Readonly="Readonly"></z-input>
                            </t-form-item>
                            <t-form-item label="单据类型" requiredMark>
                                <z-input v-bind="flowBusiness" v-model="model" :Readonly="Readonly"
                                         @change="ChangeBusiness"></z-input>
                            </t-form-item>
                            <t-form-item label="消息推送">
                                <z-input v-bind="flowMessage" v-model="model" :Readonly="Readonly"></z-input>
                            </t-form-item>
                        </t-form>
                    </t-tab-panel>
                    <t-tab-panel label="事件" value="event" v-if="IsNodeType(['leader', 'filter'])">
                        <t-form labelAlign="top">
                            <t-form-item :label="IsNodeType('leader') ? '通过后事件' : '条件满足事件'">
                                <z-input v-bind="nodeApproveEvents" v-model="currentValue"
                                         :Readonly="Readonly"></z-input>
                            </t-form-item>
                            <t-form-item :label="IsNodeType('leader') ? '驳回后事件' : '条件不满足事件'">
                                <z-input v-bind="nodeRejectEvents" v-model="currentValue"
                                         :Readonly="Readonly"></z-input>
                            </t-form-item>
                            <t-form-item label="环节撤回事件">
                                <z-input v-bind="nodeRetractEvents" v-model="currentValue"
                                         :Readonly="Readonly"></z-input>
                            </t-form-item>
                        </t-form>
                    </t-tab-panel>
                    <t-tab-panel label="系统信息" value="system" v-else>
                        <t-form labelAlign="top" layout="vertical">
                            <t-form-item label="创建人">
                                <z-input v-bind="flowCreator" v-model="model"></z-input>
                            </t-form-item>
                            <t-form-item label="创建时间">
                                <z-input v-bind="flowCreateTime" v-model="model"></z-input>
                            </t-form-item>
                            <t-form-item label="最后修改人">
                                <z-input v-bind="flowEditor" v-model="model"></z-input>
                            </t-form-item>
                            <t-form-item label="最后修改时间">
                                <z-input v-bind="flowEditTime" v-model="model"></z-input>
                            </t-form-item>
                        </t-form>
                    </t-tab-panel>
                </t-tabs>
            </div>
            <div class="graph-tabar" v-if="Readonly !== true">
                <t-button theme="primary" @click="Save(graph, model)">保存</t-button>
                <t-button theme="danger" @click="Cancel">取消</t-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import zInput from "../src/components/base/input.vue";
import { onMounted, ref } from "vue";
import { HasContent } from "../src/utils/common";
import { HttpPost } from "../src/utils/network";
import { Graph } from "@antv/x6";
import { useKeyboard } from "../src/hooks/workflow/useKeyboard";
import { useRegisterNode, useStencil, InitNode, NodeValidateConnect, NodeValidateMagnet, CreateEdge, NodeValidateEdge } from '../src/hooks/workflow/useRegisterNode';
import { useFlowBusinessInput, useFlowSystemInfo, useFlowNodeInput, useFlowEventInput } from "../src/hooks/workflow/useFlowData";
import { Save, Cancel } from "../src/hooks/workflow/useFlowBusiness";
import { appsettings } from "../appsettings";
import { useStickyToolStore } from "../src/stores/useStickyToolStore";
import { Alert, OpenFormDialog } from "../src/utils/dialog";
import { cloneDeep } from "lodash";
import { computed } from "vue";

useRegisterNode();

const props = defineProps({
    Readonly: { type: Boolean, default: null },
});

const model = defineModel();

const crxGraph = ref();
const crxProp = ref();
const crxStencil = ref();

/**
 * 页面数据
 * @type {import("vue").Ref<Graph>}
 */
const graph = ref();
const tabIndex = ref("base");
const nodeType = ref("");

const { flowName, flowBusiness, flowMessage } = useFlowBusinessInput({});
const { flowCreator, flowCreateTime, flowEditor, flowEditTime } = useFlowSystemInfo({});
const { nodeName, nodeCheckType, nodeUserRole, nodeUser, nodeRelation } = useFlowNodeInput();
const { ApproveEvent, RejectEvent, RetractEvent, UserField } = useFlowEventInput({});
const nodeApproveEvents = ref(ApproveEvent);
const nodeRejectEvents = ref(RejectEvent);
const nodeRetractEvents = ref(RetractEvent);
const nodeUserFields = ref(UserField);
/** 流程数据 */
const flowInfo = ref();

/** 是否显示节点接口
 * @param {HTMLElement[]} domPorts 节点
 * @param {Boolean} show 是否显示
 */
function ShowPorts(domPorts, show) {
    if (HasContent(domPorts))
        domPorts.forEach(x => x.style.visibility = show ? "visible" : "hidden");
}

/** 当前选中的节点 */
const currentNode = ref(null);

const currentValue = computed({
    get() {
        return currentNode.value.data;
    },
    set(value) {
        return currentNode.value.data = value;
    }
});

const currentCondition = ref();

const currentFalseType = ref("");

/** 绑定Graph事件 */
function BindEvent() {
    graph.value.on("node:mouseover", () => ShowPorts(crxGraph.value.querySelectorAll(".x6-port-body"), true));
    graph.value.on("node:mouseleave", () => ShowPorts(crxGraph.value.querySelectorAll(".x6-port-body"), false));
    graph.value.on("cell:click", e => {
        currentNode.value = e.cell;
        currentCondition.value = e.cell.data.Condition;
        currentFalseType.value = e.cell.data.FalseType;
        nodeType.value = e.cell.data.type;
        if (IsNodeType(["leader", "filter"])) {
            tabIndex.value = "prop";
        } else {
            tabIndex.value = "base";
        }
    });
    graph.value.on("blank:click", e => {
        nodeType.value = "";
        currentNode.value = null;
        tabIndex.value = "base";
    });
}

/** 判断当前节点是不是以下类型
 * @param {String[] | String} types
 */
function IsNodeType(types) {
    if (HasContent(types)) {
        if (Array.isArray(types)) {
            return types.includes(nodeType.value);
        } else {
            return types == nodeType.value;
        }
    }
}

function isSelectUser() {
    if (currentValue.value)
        return currentValue.value.CheckType == "select";
    return false;
}

function ChangeBusiness(isCreate) {
    HttpPost("/Genesis/Service/WorkFlow/GetBusinessContent", {
        name: model.value.Business
    }, res => {
        flowInfo.value = res.data;
        const update = useFlowEventInput(res.data);
        nodeApproveEvents.value = update.ApproveEvent;
        nodeRejectEvents.value = update.RejectEvent;
        nodeRetractEvents.value = update.RetractEvent;
        nodeUserFields.value = update.UserField;
        if (isCreate !== true)
            InitNode(graph.value);
    });
}

function changeNodeName(control, value) {
    var nodes = graph.value.getNodes();
    var node = nodes.find(x => x.id == currentNode.value.id);
    if (node) {
        var name = value.label;
        if (name.length > 4)
            name = name.substring(0, 4) + "\n" + name.substring(4);
        node.attr("text/text", name);
    }
}

/**
 * 设置条件
 */
function SetCondition() {
    OpenFormDialog(`【${currentNode.value.label}】条件`, {
        SubContent: {
            Field: "Condition", Mode: "edit",
            PageMode: false, BatchEdit: false, BatchCalculate: false, Export: false,
            Tabar: props.Readonly ? [] : [{ Label: "新增", Action: "AddRow", }, { Label: "删除", Action: "DeleteRow", }],
            Columns: [{
                Label: "字段",
                Field: "Field",
                Type: "select",
                Options: flowInfo.value.FilterFields,
                Fixed: true,
                Readonly: props.Readonly,
            }, {
                Label: "比较",
                Field: "Operate",
                Type: "select",
                Readonly: props.Readonly,
                Options: [
                    { Text: "等于", Value: "=" }, { Text: "不等于", Value: "<>" },
                    { Text: "大于", Value: ">" }, { Text: "大于等于", Value: ">=" },
                    { Text: "小于", Value: "<" }, { Text: "小于等于", Value: "<=" },
                    { Text: "包含", Value: "contains" }, { Text: "开头", Value: "startWith" },
                    { Text: "结尾", Value: "endWith" }
                ],
            }, {
                Label: "值",
                Field: "Value",
                Type: "text",
                Readonly: props.Readonly,
            }, {
                Label: "连接符",
                Field: "Connect",
                Type: "select",
                CanNull: false,
                Readonly: props.Readonly,
                Options: [{ Text: "并且", Value: "and" }, { Text: "或者", Value: "or" }]
            }]
        }
    }, { Condition: cloneDeep(currentValue.value.Condition) }, function (res) {
        currentValue.value.Condition = res.Condition;
        currentCondition.value = res.Condition;
    }, { height: 400 });
}

function ChangeFalseType(value) {
    if (value == "back") {
        var edges = graph.value.getEdges(),
            nodes = graph.value.getNodes();
        var beforeEdges = edges.filter(x => x.target.cell == currentNode.value.id);
        for (var i = 0; i < beforeEdges.length; i++) {
            const beforeEdge = beforeEdges[i];
            if (beforeEdge.source.cell == "start") {
                Alert("不支持回退到开始环节！", "error");
                currentValue.value.FalseType = "next";
                currentFalseType.value = "next";
                return;
            } else {
                const beforeTarget = nodes.find(x => x.id == beforeEdge.source.cell);
                if (beforeTarget.data.type == "filter") {
                    Alert("不支持回退到条件类型环节！", "error");
                    currentValue.value.FalseType = "next";
                    currentFalseType.value = "next";
                    return;
                }
            }
        }
        var noEdge = edges.find(x => x.source.cell == currentNode.value.id && x.data.label == "N");
        if (noEdge)
            graph.value.removeEdge(noEdge);
    }
    currentValue.value.FalseType = value;
    currentFalseType.value = value;
}

onMounted(() => {
    if (appsettings.Page.HelpButton)
        useStickyToolStore().AddStandardStickyTool("help");

    graph.value = new Graph({
        container: crxGraph.value,
        width: "100%", height: "100%",
        /** 允许鼠标滚轮缩放 */
        mousewheel: { enabled: true, modifiers: ["alt"] },
        connecting: {
            allowBlank: false,
            allowLoop: false,
            allowMulti: false,
            highlight: true,
            snapline: true,
            snap: { radius: 20, },
            router: { name: "manhattan", args: { padding: 20, } },
            connector: { name: "rounded", args: { radius: 10, } },
            validateMagnet: NodeValidateMagnet,
            createEdge: CreateEdge,
            validateConnection: NodeValidateConnect,
            validateEdge: NodeValidateEdge
        }
    });

    useKeyboard(graph.value);
    BindEvent();

    const stencil = useStencil(graph.value);
    crxStencil.value.appendChild(stencil.container);

    if (model.value.Content) {
        ChangeBusiness(true);
        graph.value.fromJSON(JSON.parse(model.value.Content));
    } else {
        InitNode(graph.value);
    };
});

</script>

<style scope>
.container {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.stencil-container {
    width: 120px;
    height: 100%;
    position: relative;
    border-right: 1px solid #dfe3e8;
}

.graph-container {
    width: calc(100% - 480px);
    height: 100%;
    border-right: 1px solid #dfe3e8;
}

.graph-container.readonly {
    width: calc(100% - 360px);
}

.prop-container {
    width: 360px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.graph-form {
    padding: 10px;
    overflow-y: auto;
    height: calc(100% - 80px);
    flex: 1;
}

.graph-form .t-form__item {
    margin-bottom: var(--td-comp-margin-xxxs);
}

.graph-tabar {
    border-top: 1px solid #dfe3e8;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.graph-tabar>* {
    margin-left: 4px;
}
</style>