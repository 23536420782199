import { defineStore } from "pinia";
import { parse } from "qs";

export const useNetworkStore = defineStore("network", {
    state: () => ({
        /** 是否显示 */
        showStatus: true,
        /** 总时间 */
        total: 10,
        /** 执行时间 */
        exec: 0,
        /** 延迟时间 */
        delay: 0,
        /** 请求任务 */
        tasks: {

        }
    }),
    actions: {
        /**
         * 更新时间
         * @param {number} total 总请求时间
         * @param {number} exec 执行时间
         */
        updateTime(total, exec) {
            this.total = total;
            this.exec = exec;
            this.delay = Math.ceil(total - exec);
            let win = window;
            //网络时间传递到父页面
            while (win.parent != win) {
                win = win.parent;
                if (win.$app && win.$app.network)
                    win.$app.network.updateTime(this.total, this.exec);
                else
                    break;
            }
        },
        /**
         * 获取时间
         * @param {number} num 时间
         * @returns {string} 时间字符串
         */
        getTimeString(num) {
            if (num > 60000)
                return `${Math.floor(num / 60000)}m${Math.floor(num % 60000) / 1000}s`
            if (num > 1000)
                return `${Math.ceil(num / 10) / 100}s`;
            return `${Math.ceil(num)}ms`;
        },
        /**
         * 获取总请求时间
         * @returns {string} 时间字符串
         */
        getTotalTime() {
            return this.getTimeString(this.total);
        },
        /**
         * 获取执行时间
         * @returns {string} 时间字符串
         */
        getExecTime() {
            return this.getTimeString(this.exec);
        },
        /**
         * 获取延迟时间
         * @returns {string} 时间字符串
         */
        getDelayTime() {
            return this.getTimeString(this.delay);
        },
        /**
         * 是否有错误任务
         * @returns {boolean} 是否有错误任务
         */
        hasErrorTask(isThrow) {
            const hasError = Object.values(this.tasks).includes(3);
            if (hasError && isThrow) {
                throw new Error("页面部分数据加载失败，请重新刷新后操作！");
            }
            return hasError;
        },
        /**
         * 是否有正在执行的任务
         * @returns {boolean} 是否有正在执行的任务
         */
        hasRuningTask(isThrow) {
            const hasRuning = Object.values(this.tasks).includes(1);
            if (hasRuning && isThrow) {
                throw new Error("页面未加载完毕，请稍后再操作！");
            }
            return hasRuning;
        },
        addTask() {

        },
        getBaseQuery(other) {
            var menuid, accid, query,
                win = window;
            while (true) {
                if (menuid && accid)
                    break;
                if (win.location.search.length > 1) {
                    query = parse(win.location.search.substring(1));
                    menuid = menuid || query.menuid;
                    accid = accid || query.accid;
                }
                if (win == win.parent)
                    break;
                win = win.parent;
            }
            return {
                menuid,
                accid,
                ...other
            };
        }
    }
})