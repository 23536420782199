//此文件为自动生成，请勿修改！详情见预生成脚本：cli/prepare：50
import zcamera from "./camera.vue";
import zcheckbox from "./checkbox.vue";
import zdaterange from "./daterange.vue";
import zdatetime from "./datetime.vue";
import zfile from "./file.vue";
import zmap from "./map.vue";
import zmonaco from "./monaco.vue";
import znumber from "./number.vue";
import zpopup from "./popup.vue";
import zprogress from "./progress.vue";
import zradio from "./radio.vue";
import zrate from "./rate.vue";
import zrichtext from "./richtext.vue";
import zselect from "./select.vue";
import zswitch from "./switch.vue";
import ztag from "./tag.vue";
import ztemplate from "./template.vue";
import ztext from "./text.vue";
import ztransfer from "./transfer.vue";

/**
 * 获取输入组件
 * @param type
 * @returns {import("vue").Component}
 */
function getInputComponent(type) {
    switch (type) {
		case "camera":
			return zcamera;
		case "checkbox":
			return zcheckbox;
		case "yearrange":
		case "monthrange":
		case "daterange":
		case "datetimerange":
		case "timerange":
			return zdaterange;
		case "year":
		case "month":
		case "week":
		case "date":
		case "dates":
		case "datetime":
		case "time":
			return zdatetime;
		case "file":
		case "image":
		case "video":
		case "doc":
		case "excel":
		case "ppt":
		case "txt":
		case "pdf":
			return zfile;
		case "map":
			return zmap;
		case "monaco":
		case "code":
			return zmonaco;
		case "number":
			return znumber;
		case "popup":
			return zpopup;
		case "progress":
			return zprogress;
		case "radio":
			return zradio;
		case "rate":
			return zrate;
		case "richtext":
			return zrichtext;
		case "select":
			return zselect;
		case "switch":
			return zswitch;
		case "tag":
			return ztag;
		case "template":
			return ztemplate;
		case "text":
			return ztext;
		case "transfer":
			return ztransfer;
        default:
            return ztext;
    }
}

export {
    getInputComponent
}