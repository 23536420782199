<template>
    <t-tag-input v-model="inputValue" size="small" @change="onChange" clearable>
    </t-tag-input>
</template>

<script setup>
import { reactive, watch, onBeforeUnmount } from "vue";

const props = defineProps({
    Type: String,
    Field: String,
    Readonly: { type: Boolean, default: null },
});

const emits = defineEmits(["change"]);
const model = defineModel({ type: Array, default: [] });
const inputValue = reactive([]);

/** 变更事件 */
function onChange(value) {
    let oldValue = model.value;
    model.value = value;
    emits("change", model.value, oldValue);
}

const stopWatchs = [
    watch(() => model.value, value => {
        inputValue.splice(0, inputValue.length, value.map(x => x))
    }, { immediate: true, deep: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>