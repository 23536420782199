<template>
    <div class="z-line-scroller">
        <t-button class="z-ctrl z-ctrl__left" v-show="canLeft" size="small" theme="default" @click="onStart">
            <template #icon>
                <ChevronLeftDoubleIcon :size="18"></ChevronLeftDoubleIcon>
            </template>
        </t-button>
        <div class="z-box" ref="box" @scroll="_render">
            <div class="z-content" ref="content" @mousewheel="onMouseScroll" @DOMMouseScroll="onMouseScroll">
                <slot></slot>
            </div>
        </div>
        <t-button class="z-ctrl z-ctrl__right" v-show="canRight" size="small" theme="default" @click="onEnd">
            <template #icon>
                <ChevronRightDoubleIcon :size="18"></ChevronRightDoubleIcon>
            </template>
        </t-button>
    </div>
</template>

<script setup>
import { nextTick, onMounted, onBeforeUnmount, onUpdated, ref } from "vue";
import { throttle } from "lodash";
import { ChevronLeftDoubleIcon, ChevronRightDoubleIcon } from "tdesign-icons-vue-next";

const box = ref();
const content = ref();

const onMouseScroll = throttle((e) => {
    let offset = 0;
    if (e.wheelDelta)
        offset = box.value.scrollLeft - (e.wheelDelta / 8);
    else if (e.detail)
        offset = box.value.scrollLeft + (e.detail * 6);
    else
        return;
    if (offset < 0)
        offset = 0;
    if (offset > content.value.offsetWidth - box.value.offsetWidth)
        offset = content.value.offsetWidth - box.value.offsetWidth;
    if (offset != box.value.scrollLeft) {
        box.value.scrollTo(offset, 0);
    }
    e.preventDefault();
}, 1);

function onStart() {
    box.value.scrollTo(0, 0);
}

function onEnd() {
    box.value.scrollTo(content.value.offsetWidth - box.value.offsetWidth, 0);
}

const canLeft = ref(false);
const canRight = ref(false);

function _render() {
    nextTick(() => {
        if (box.value.offsetWidth < content.value.offsetWidth) {
            canLeft.value = box.value.scrollLeft > 0;
            canRight.value = box.value.scrollLeft + box.value.offsetWidth < content.value.offsetWidth;
        } else {
            canLeft.value = false;
            canRight.value = false;
        }
    });
}

onUpdated(() => {
    _render();
});

onMounted(() => {
    window.addEventListener("resize", _render);
    _render();
});

onBeforeUnmount(() => {
    window.removeEventListener("resize", _render);
});

defineExpose({
    ToStart() {
        nextTick(onStart);
    },
    ToEnd() {
        nextTick(onEnd);
    }
});
</script>

<style scoped>
.z-line-scroller {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
}

.z-box {
    overflow: hidden;
    width: 100%;
}

.z-box>.z-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
}

.z-content {
    width: fit-content;
}

.z-ctrl+.z-box,
.z-box+.z-ctrl {
    margin-left: 4px;
}

.z-ctrl {
    margin-left: 4px;
}
</style>