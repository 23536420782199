<template>
  <t-input-adornment>
    <template v-if="Prepend" #prepend>
      <div v-text="Prepend.Label" @click="onPrependClick"></div>
    </template>
    <t-input v-model="inputValue" size="small" :readonly="Readonly" clearable @enter="onEnter" :label="PrependText" :suffix="AppendText" @blur="onBlur"></t-input>
    <template v-if="Append" #append>
      <div v-text="Append.Label" @click="onAppendClick"></div>
    </template>
  </t-input-adornment>
</template>

<script setup>
import { onBeforeUnmount, ref, watch } from 'vue';
import { HasContent, IsNullOrEmpty, MoneyFormat, toFn } from "../../utils/common";
import { isNumber } from 'lodash';

const props = defineProps({
    Type: String,
    Readonly: { type: Boolean, default: null },
    Precision: { type: Number, default: null },
    Min: { type: Number, default: null },
    Max: { type: Number, default: null },
  /** 前置按钮 */
  Prepend: Object,
  /** 后置按钮 */
  Append: Object,
  /** 前置文本 */
  PrependText: String,
  /** 后置文本 */
  AppendText: String,
});

const emits = defineEmits(["change", "click:prepend", "click:append"]);

const model = defineModel();
const dataModel = defineModel("data");

const inputValue = ref();

const regex = [/[^\d\.\+\-\*\/\(\)\%]/g, /-?\d*\.?\d*/];

/** 转换成数字
 * @param {string} value 值
 * @returns {{format:String,value:Number}} 返回序列化结果
 */
function parseNumber(value) {
    let result = { format: "", value: value };
    if (IsNullOrEmpty(value))
        return result;
    try {
        if (isNumber(value))
            value = String(value);
        value = String(eval(value.replace(regex[0], "")));
    } catch (e) {
        //解析失败，可能不包含公式;
    }
    let num = value.match(regex[1])[0];
    if (num == "") {
        result.value = null;
        return result;
    }
    if (num == ".") {
        result.format = "0";
        result.value = 0;
        return result;
    }
    num = Number(num);
    if (HasContent(props.Min)) {
        let min = Number(props.Min);
        if (num < min)
            num = min;
    }
    if (HasContent(props.Max)) {
        let max = Number(props.Max);
        if (num > max)
            num = max;
    }
    if (HasContent(props.Precision)) {
        result.value = num.toFixed(props.Precision);
    } else {
        result.value = num;
    }
    if (props.Type == "money") {
        result.format = MoneyFormat(num, props.Precision);
    } else {
        result.format = String(result.value);
    }
    return result;
}

function onEnter() {
    onChange(inputValue.value);
}

function onBlur() {
    onChange(inputValue.value);
}

function onPrependClick() {
  toFn(props.Prepend)(props, dataModel.value);
  emits("click:prepend", props, dataModel.value);
}

function onAppendClick() {
  toFn(props.Append)(props, dataModel.value);
  emits("click:append", props, dataModel.value);
}

/** 变更事件 */
function onChange(value) {
    let result = parseNumber(value);
    if (inputValue.value != result.format)
        inputValue.value = result.format;
    if (model.value != result.value) {
        let oldValue = model.value;
        model.value = result.value;
        emits('change', model.value, oldValue);
    }
}

const stopWatchs = [
    watch(() => model.value, onChange, { immediate: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>

<style scoped>
:deep(.t-is-readonly),
:deep(.t-is-readonly .t-input__inner) {
    cursor: not-allowed;
}
</style>