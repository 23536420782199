<template>
    <div class="z-popup">
        <t-input-adornment>
            <t-auto-complete ref="crxInput" v-model="keyword" :filterable="false"
                             :readonly="Readonly === true" :options="suggestions" size="small"
                             :popup-props="{ overlayInnerStyle: { width: 'fit-content' } }" @select="onSelect">
                <template #triggerElement>
                    <t-tag-input v-if="IsMult" ref="crxTagInput" v-model:inputValue="keyword" v-model="multInputValue"
                                 v-bind="tagInputProps" :readonly="Readonly">
                        <template #tag="{ value }">
                            <span v-text="showText(value)"></span>
                        </template>
                        <template #collapsedItems="{ count }">
                            <t-popup placement="bottom" trigger="click" destroy-on-close>
                                <t-tag size="small">更多({{ count }})</t-tag>
                                <template #content>
                                    <div style="display: flex; width: 200px; flex-wrap: wrap;">
                                        <div v-for="(item, index) in multInputValue" :key="index"
                                             style="margin-left: 4px;">
                                            <t-tag size="small">{{ showText(item) }}</t-tag>
                                        </div>
                                    </div>
                                </template>
                            </t-popup>
                        </template>
                    </t-tag-input>
                    <t-input v-else v-model="keyword" @input="onSearch" @enter="onEnter" @blur="onBlur" size="small"
                             :readonly="Readonly === true" :placeholder="Readonly ? PlaceHolder || '' : PlaceHolder"
                             clearable></t-input>
                </template>
                <template v-if="searching" #panelTopContent>
                    <div style="text-align: center;">
                        <t-loading text="正在查找..." size="small"></t-loading>
                    </div>
                </template>
            </t-auto-complete>
            <template v-if="Readonly !== true" #append>
                <t-button size="small" @click="onOpen">
                    <template #icon><search-icon /></template>
                </t-button>
            </template>
        </t-input-adornment>
    </div>
</template>

<script setup>
import { SearchIcon } from 'tdesign-icons-vue-next';
import { getCurrentInstance, onBeforeUnmount, ref, watch } from 'vue';
import { useQuickSearch } from "../custom/popup/useQuickSearch";
import { useSinglePopup } from "../custom/popup/useSinglePopup";
import { useMultiplePopup } from "../custom/popup/useMultiplePopup";
import { useOpenDialogSelect } from "../custom/popup/useOpenDialogSelect";
import { HasContent } from '../../utils/common';
import { debounce } from 'lodash';

const props = defineProps({
    Label: String,
    Type: String,
    Field: String,
    NameField: String,
    SelectMap: Object,
    Code: String,
    Url: String,
    Quick: { type: Boolean, default: null },
    ShowCode: { type: Boolean, default: null },
    OnlyName: { type: Boolean, default: null },
    IsMult: { type: Boolean, default: null },
    Param: Object,
    DefaultValue: Object,
    PlaceHolder: String,
    Tip: Object,
    Required: { type: Boolean, default: null },
    Readonly: { type: Boolean, default: null },
    ReadonlyCode: Array,
    Visible: { type: Boolean, default: null },
    VisibleCode: Array,
    Paramenters: Object
});

const model = defineModel();

const emits = defineEmits(["change"]);

const keyword = ref("");
//快速搜索
const {
    configuration,
    searching,
    suggestions,
    searchSuggestionAsync,
    clearSuggestionAsync,
    selectSuggestion
} = useQuickSearch({
    Enabled: props.Quick,
    ShowCode: props.ShowCode,
    Code: props.Code,
    Url: props.Url,
    Param: props.Param,
    SetValue
});

//弹窗搜索
const {
    openPopupDialog
} = useOpenDialogSelect({
    keyword,
    configuration,
    Code: props.Code,
    ShowCode: props.ShowCode,
    Url: props.Url,
    Param: props.Param,
    IsMult: props.IsMult,
    SetValue
});

/** 输入控件 */
const crxInput = ref();
/** 组件的所有数据 */
const {
    model: inputValue,
    setValue,
    checkData,
    checkDataAsync
} = useSinglePopup({
    keyword,
    ShowCode: props.ShowCode,
    OnlyName: props.OnlyName,
    Field: props.Field,
    NameField: props.NameField,
    SetValue
});

/** 输入控件 */
const crxTagInput = ref();
const {
    models: multInputValue,
    tagInputProps,
    setValue: multSetValue,
    checkData: multCheckData,
    checkDataAsync: multCheckDataAsync
} = useMultiplePopup({
    keyword,
    suggestions,
    searchSuggestionAsync,
    clearSuggestionAsync,
    SetValue
});

function showText(item) {
    if (item)
        return props.ShowCode || props.OnlyName ? item[props.Field] || "" : item[props.NameField] || "";
    return "";
}

/** 选择事件
 * @description 返回值为空字符串时，代表没有选择数据，默认选择首行，不用enter事件进行触发
 * @param {*} index 
 */
function onSelect(index) {
    if (props.IsMult)
        multCheckDataAsync.cancel();
    else
        checkDataAsync.cancel();
    selectSuggestion(index);
}

/** enter事件
 * @description 输入框回车事件，如果有唯一数据，则直接选中
 */
function onEnter() {
    if (suggestions.value && suggestions.value.length == 1)
        onSelect(0);
}

/** 离开时校验数据是否合法 */
function onBlur() {
    clearSuggestionAsync();
    //延迟校验数据
    if (props.IsMult)
        multCheckDataAsync();
    else
        checkDataAsync();
}

/** 打开弹窗查询 */
function onOpen() {
    //取消blur的数据校验
    if (props.IsMult)
        multCheckDataAsync.cancel();
    else
        checkDataAsync.cancel();
    openPopupDialog(getCurrentInstance(), props, model.value);
}

/** 文本框输入，快速检索
 * @description 回车选择会触发inputValue清空，此时onSelect会失效，找不到
 */
const onSearch = debounce(() => {
    if (keyword.value)
        searchSuggestionAsync(keyword.value, props, model.value);
    else {
        searchSuggestionAsync.cancel();
    }
}, 200);

function buildValue(value) {
    let res = {},
        fields = GetBindMap();
    fields.map(field => {
        if (HasContent(field[0]) && HasContent(field[1]))
            res[field[0]] = value[field[1]];
    });
    return res;
}

function buildModel(value) {
    let res = {},
        fields = GetBindMap();
    fields.map(field => {
        res[field[0]] = value[field[0]];
    });
    return res;
}

/** 设定值
 * @param {*} value
 * @param {boolean} isAdd 是否为新增，只针对多选时生效
 */
function SetValue(value, isAdd) {
    let change;
    if (props.IsMult) {
        if (isAdd) {
            change = multSetValue([...multInputValue.value, value]);
        } else {
            if (Array.isArray(value)) {
                change = multSetValue(value.map(buildValue));
            } else {
                change = multSetValue([buildValue(value)]);
            }
        }
    } else {
        change = setValue(buildValue(value));
    }
    clearSuggestionAsync();
    if (change) {
        if (props.IsMult) {
            let oldValue;
            if (props.Field in model.value)
                oldValue = model.value[props.Field].map(buildValue);
            else
                oldValue = [];
            if (Array.isArray(value))
                model.value[props.Field] = value.map(buildValue);
            else
                model.value[props.Field] = [buildValue(value)]
            emits("change", model.value, oldValue);
        }
        else {
            let oldValue = buildValue(model.value);
            Object.assign(model.value, buildValue(value));
            emits("change", model.value, oldValue);
        }
    }
    return change;
}

/** 绑定内部值 */
function bindValue(value) {
    if (props.IsMult) {
        if (props.Field in value) {
            value = value[props.Field];
            if (Array.isArray(value)) {
                multSetValue(value.map(buildModel));
            } else {
                multSetValue([buildModel(value)]);
            }
        }
    } else {
        setValue(buildModel(value));
    }
}

const stopWatchs = [
    watch(() => model.value, bindValue, { deep: true, immediate: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
    //表格行切换时，会直接Unmount，导致onBlur事件无法执行，此时直接校验数据是否正确
    clearSuggestionAsync.cancel();
    if (props.IsMult) {
        multCheckDataAsync.cancel();
        multCheckData();
    } else {
        checkDataAsync.cancel();
        checkData();
    }
});

/** 获取绑定映射
 * @returns {string[][]}
 */
function GetBindMap() {
    let result;
    if (props.OnlyName)
        result = [[props.Field, "$Name"], [props.NameField, "$Name"]];
    else
        result = [[props.Field, "$Key"], [props.NameField, "$Name"]];
    if (HasContent(props.SelectMap))
        result.push(...Object.entries(props.SelectMap));
    return result;
}
</script>

<style scoped>
.z-popup {
    width: 100%;
}

.z-popup :deep(.t-is-readonly),
.z-popup :deep(.t-is-readonly .t-input__inner) {
    cursor: not-allowed;
}
</style>