import { onMounted, onBeforeUnmount, ref, watch } from "vue";
import { forEach, isArray } from "lodash";
import { EqualsObject, HasContent } from "../utils/common";
import { ZEnumProperty } from "../models/ZEnumProperty";
import { OnceRequest } from "../utils/network.extend";
import { HttpGet } from "../utils/network";

/** 枚举别名，部分字段不支持，转换成索引 */
function alias(options, labelField, valueField, childrenField, map, { NeedWhole }, rank) {
    if (options === null || options === undefined)
        return null;
    rank = rank || "";
    var res = [];
    forEach(options, (option, index) => {
        let value = option[valueField],
            text = option[labelField] || value,
            item = { label: text, value: rank + (index + 1), option: value };
        //需要完整数据的时候，保留原始数据
        if (NeedWhole)
            item.origin = { ...option };
        if (childrenField in option)
            item.children = alias(option[childrenField], labelField, valueField, childrenField, map, { NeedWhole }, `${item.value}-`);
        res.push(item);
        if (HasContent(map)) {
            if (("value" in map) == false)
                map.value = {};
            map.value[item.value] = value;
            if (("enum" in map) == false)
                map.enum = {};
            map.enum[String(value)] = item.value;
            if (("dic" in map == false))
                map.dic = {};
            map.dic[String(value)] = text;
        }
    });
    return res;
}

/** 获取原始值
 * @param {Array<{origin:Object}>} options 
 * @param {string} key 
 */
function GetOrigin(options, key) {
    if (key.includes("-")) {
        let keys = key.split("-");
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (options[Number(key) - 1].children)
                options = options[Number(key) - 1].children;
            else
                return options[Number(key) - 1].origin;
        }
    } else {
        return options[Number(key) - 1].origin;
    }
}

/**
 * @template T
 * @param {function({
 *    props: ZEnumProperty & T,
 *    onChange: (value:any)=>void,
 *    stopWatchs: Array
 *  })} option 
 * @param {T} defProps 
 * @returns {import("vue").Component}
 */
function useEnumComponent(option, defProps, defComponents) {
    return ({
        props: {
            modelValue: String,
            Type: String,
            Field: String,
            Options: Array,
            Size: String,
            Readonly: { type: Boolean, default: null },
            Code: String,
            Url: String,
            EnumLabel: String,
            EnumValue: String,
            EnumChildren: String,
            CanNull: { type: Boolean, default: null },
            OnlyLeaf: { type: Boolean, default: null },
            IsMult: { type: Boolean, default: null },
            ColCount: { type: Number, default: 4 },
            /** 是否需要完整数据 */
            NeedWhole: { type: Boolean, default: false },
            ...defProps,
            data: Object
        },
        computed: {
            width() {
                if (this.ColCount <= 0)
                    return "100%";
                else
                    return `${100 / this.ColCount}%`;
            }
        },
        components: {
            ...defComponents
        },
        emits: ["change", "update:modelValue", "relatives"],
        setup(props, { emit, expose }) {
            const model = ref();
            const options = ref([]);
            const map = { value: {}, enum: {} };

            /** 枚举改变
             * @param {string|Array} value 
             */
            function onChange(value) {
                let result = convert(value, "value");
                if (EqualsObject(props.modelValue, result) == false) {
                    let oldValue = props.modelValue;
                    emit("update:modelValue", result);
                    emit("change", result, oldValue);
                    if (props.NeedWhole && props.data)
                        props.data[`${props.Field}_Whole`] = convert(value, "whole");
                }
            }

            /** 判断是不是多选控件
             * @returns {Boolean}
             */
            function ismult() {
                switch (props.Type) {
                    case "checkbox":
                        return true;
                    case "radio":
                        return false;
                    default:
                        return props.IsMult;
                }
            }

            /** 枚举转换
             * @param {string|Array} value 
             * @param {"value"|"enum"|"whole"} type 转换类型 value/enum
             */
            function convert(value, type) {
                let result;
                if (ismult()) {
                    result = [];
                    if (HasContent(value)) {
                        if (isArray(value) == false)
                            value = [value];
                        if (type == "whole")
                            forEach(value, item => result.push(GetOrigin(options.value, item)));
                        else
                            forEach(value, item => result.push(map[type][item]));
                    }
                } else {
                    if (isArray(value)) {
                        if (value.length > 0)
                            value = value[0];
                        else
                            value = null;
                    }
                    if (HasContent(value)) {
                        if (type == "whole")
                            result = GetOrigin(options.value, value);
                        else
                            result = map[type][value];
                    }
                    else
                        result = null;
                }
                return result;
            }

            function load() {
                let hasload = false,
                    fnInit = function () {
                        if (HasContent(props.modelValue)) {
                            let result = convert(props.modelValue, "enum");
                            if (EqualsObject(model.value, result) == false)
                                model.value = result;
                        } else {
                            if (ismult())
                                model.value = [];
                            else
                                model.value = null;
                        }
                    };
                if (props.Code) {
                    hasload = true;
                    GetEnum(props.Code, res => {
                        let result = alias(res.data, "EnumName", "EnumCode", "Children", map, {
                            NeedWhole: props.NeedWhole
                        });
                        options.value = result || [];
                        fnInit();
                    });
                }
                else if (props.Url) {
                    hasload = true;
                    GetEnumForUrl(props.Url, {}, res => {
                        let result = alias(res.data, props.EnumLabel || "Text", props.EnumValue || "Value", props.EnumChildren || "Children", map, {
                            NeedWhole: props.NeedWhole
                        });
                        options.value = result || [];
                        fnInit();
                    });
                }
                else if (props.Options) {
                    let result = alias(props.Options, props.EnumLabel || "Text", props.EnumValue || "Value", props.EnumChildren || "Children", map, {
                        NeedWhole: props.NeedWhole
                    });
                    options.value = result || [];
                }
                if (hasload == false) {
                    fnInit();
                }
            }

            /** 绑定内部值 */
            function bindValue(value) {
                let result = convert(value, "enum");
                if (model.value != result)
                    model.value = result;
            }

            function changeOptions(value) {
                let result = alias(value, "Text", "Value", "Children", map, {
                    NeedWhole: props.NeedWhole
                });
                options.value = result || [];
            }

            const stopWatchs = [
                watch(() => props.modelValue, bindValue, { deep: true, immediate: true }),
                watch(() => props.Options, changeOptions, { deep: true, immediate: true })
            ];

            onMounted(() => {
                load();
            });

            onBeforeUnmount(() => {
                stopWatchs.forEach(stop => stop());
            });

            if (typeof option == "function") {
                let res = { model, options, onChange, stopWatchs };
                return Object.assign(res, option({ props, emit, ...res }));
            } else {
                return { model, options, onChange, ...option };
            }
        }
    });
}

/** 返回下拉枚举
 * @param {Object} config 
 * @returns {Promise<{options:Array<{label:String,value:String,option:String}>,map:{dic:Object,enum:Object,value:Object}}>}
 */
function GetEnumOptions(config) {
    return new Promise((resolve, reject) => {
        var map = {};
        if (config.Code) {
            GetEnum(config.Code, res => {
                let options = alias(res.data, "EnumName", "EnumCode", "Children", map, {
                    NeedWhole: config.NeedWhole
                });
                resolve({ options, map });
            }, reject);
        }
        else if (config.Url) {
            GetEnumForUrl(config.Url, {}, res => {
                let options = alias(res.data, config.EnumLabel || "Text", config.EnumValue || "Value", config.EnumChildren || "Children", map, {
                    NeedWhole: config.NeedWhole
                });
                resolve({ options, map });
            }, reject);
        }
        else if (config.Options) {
            let options = alias(config.Options, "Text", "Value", "Children", map, {
                NeedWhole: config.NeedWhole
            });
            resolve({ options, map });
        }
    });
}

/** 获取枚举（同个页面只会加载一次）
 * @param {string} code 编码（在枚举表维护)
 * @param {function(AxiosResponse)} success 成功回调
 * @param {function(Error)} fail 失败回调
 * @param {function()} complete 完成回调
 */
function GetEnum(code, success, fail, complete) {
    return OnceRequest(HttpGet)(`/Permissions/Enum/List/${code}`, {}, success, fail, complete);
}

/** 获取枚举（同个页面只会加载一次）
 * @param {string} url 枚举请求地址
 * @param {function(AxiosResponse)} success 成功回调
 * @param {function(Error)} fail 失败回调
 * @param {function()} complete 完成回调
 */
function GetEnumForUrl(url, query, success, fail, complete) {
    return OnceRequest(HttpGet)(url, query, success, fail, complete);
}

export {
    useEnumComponent,
    GetEnumOptions,
    GetEnum,
    GetEnumForUrl
}