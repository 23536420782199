import { Graph, ObjectExt, Shape } from "@antv/x6";
import { Stencil } from "@antv/x6-plugin-stencil";
import { Toast } from "../../utils/dialog";

ObjectExt.clone;

const attrs = {
    circle: {
        r: 4, magnet: true, fill: "#fff",
        stroke: "#5F95FF", strokeWidth: 1,
        style: { visibility: "hidden" }
    }
};
const ports = {
    groups: {
        top: { position: "top", attrs }, bottom: { position: "bottom", attrs },
        right: { position: "right", attrs }, left: { position: "left", attrs }
    },
    items: [
        { group: "top" }, { group: "bottom" },
        { group: "right" }, { group: "left" }
    ]
};

/** 注册节点 */
function useRegisterNode() {
    /** 注册长方形 */
    Graph.registerNode("ant-rect", {
        inherit: "rect",
        width: 80,
        height: 40,
        attrs: {
            body: { strokeWidth: 1, stroke: "#5F95FF", fill: "#EFF4FF" },
            text: { fontSize: 12, fill: "#262626" }
        },
        ports: ports
    }, true);

    /** 注册多边形 */
    Graph.registerNode("ant-polygon", {
        inherit: "polygon",
        width: 80, height: 60,
        attrs: {
            body: { strokeWidth: 1, stroke: "#5F95FF", fill: "#EFF4FF", refPoints: "0,10 10,0 20,10 10,20" },
            text: { fontSize: 12, fill: "#262626" }
        },
        ports: ports
    }, true);
}

/** 注册模板
 * @param {Graph} gragh
 * @returns {Stencil}
 */
function useStencil(gragh) {
    const stencil = new Stencil({
        title: "工作流",
        target: gragh,
        stencilGraphWidth: 120,
        stencilGraphHeight: 160,
        layoutOptions: {
            columns: 1,
            columnWidth: 100,
            rowHeight: 60,
        },
        groups: [{ name: "element", title: "元素", collapsable: false, collapsed: false }]
    });

    const nodeLeader = gragh.createNode({
        shape: "ant-rect",
        label: "人员",
        attrs: { body: { rx: 6, ry: 6 } },
        tools: [],
        data: { label: "人员", type: "leader", CheckType: "and" }
    });
    const nodeFilter = gragh.createNode({
        shape: "ant-polygon",
        label: "条件",
        tools: [],
        data: { label: "条件", type: "filter", FalseType: "next", Condition: [] }
    });
    stencil.load([nodeLeader, nodeFilter], "element");
    return stencil;
}

/** 校验是否可以新增边
 * @this {Graph}
 * @param {{ cell: Cell, view: CellView, magnet:Element, e: MouseEvent }} e 
 * @returns {boolean}
 */
function NodeValidateMagnet({ cell }) {
    let edges = this.getEdges(),
        count = edges.filter(x => x.source.cell == cell.id).length;
    switch (cell.data.type) {
        case "filter":
            /** 允许出现两个连接点，是和否两个连接 */
            if (cell.data.FalseType == "next") {
                return count < 2;
            } else {
                return count < 1;
            }
        case "start":
        case "leader":
            return count < 1;
        case "end":
            return false;
    }
    return false;
}


/** 校验是否可以连接
 * @this {Graph}
 * @param {Options.ValidateConnectionArgs} e 
 */
function NodeValidateConnect({ targetMagnet }) {
    return !!targetMagnet;
}

/** 校验线点是否可连接
 * @this {Graph}
 * @param {{ type: Edge.TerminalType, edge: Edge, previous: Edge.TerminalData}} e
 */
function NodeValidateEdge({ edge }) {
    let nodes = this.getNodes(),
        source = nodes.find(x => x.id == edge.source.cell),
        target = nodes.find(x => x.id == edge.target.cell);
    if (source.data.type == "filter") {
        if (target.data.type == "filter" && target.data.FalseType == "back") {
            Toast("不支持回退到条件类型环节！", "error");
            return false;
        }
    }
    if (edge.source.cell == "start") {
        if (edge.target.cell == "end") {
            Toast("流程不能直接结束！", "error");
            return false;
        }
        if (target.data.type == "filter" && target.data.FalseType == "back") {
            Toast("不支持回退到开始环节！", "error");
            return false;
        }
    }
    if (edge.target.cell == "start") {
        Toast("开始不能作为下游节点！", "error");
        return false;
    }
    if (edge.source.cell == "end") {
        Toast("结束不能作为上游节点！", "error");
        return false;
    }
    return true;
}

/** 创建连接线
 * @this {Graph}
 * @param {{ sourceCell: Cell, sourceView: CellView, sourceMagnet: Element }} e 
 */
function CreateEdge({ sourceCell }) {
    let label = "";
    if (sourceCell.data.type == "filter") {
        let edges = this.getEdges().filter(x => x.source.cell == sourceCell.id);
        if (edges.length > 0 && edges[0].data.label == "Y") {
            label = "N";
        } else {
            label = "Y";
        }
    }
    return new Shape.Edge({
        label,
        zIndex: 0,
        data: { type: "line", label },
        tools: [],
        attrs: {
            text: { fill: "#A2B1C3" },
            line: { stroke: "#A2B1C3", strokeWidth: 2, targetMarker: { name: "block", width: 12, height: 8 } }
        }
    });
}

/** 初始化节点
 * @param {Graph} graph
 */
function InitNode(graph) {
    graph.fromJSON({
        nodes: [{
            id: "start",
            label: "开始",
            shape: "ant-rect",
            position: { x: 210, y: 50 },
            attrs: { body: { rx: 20, ry: 26, }, },
            data: { label: "开始", type: "start", }
        },
        {
            id: "end",
            label: "结束",
            shape: "ant-rect",
            position: { x: 210, y: 330 },
            attrs: { body: { rx: 20, ry: 26, }, },
            data: { label: "结束", type: "end" }
        }]
    });
}

export {
    useRegisterNode,
    useStencil,
    NodeValidateMagnet,
    CreateEdge,
    NodeValidateConnect,
    NodeValidateEdge,
    InitNode
}