<template>
    <t-cascader size="small" v-model="model" v-bind="cascader" :options="options" @change="onChange"></t-cascader>
</template>

<script>
import { reactive, watch } from "vue";
import { useEnumComponent } from "../../hooks/useEnumComponent";
export default useEnumComponent(({ props, stopWatchs }) => {
    const cascader = reactive({
        filterable: true,
        placeholder: "请选择",
        reserveKeyword: true,
        multiple: props.IsMult,
        checkStrictly: props.OnlyLeaf == false,
        clearable: props.CanNull !== false,
        readonly: props.Readonly === true
    });
    stopWatchs.push(watch(() => props.Readonly, x => cascader.readonly = x === true));
    return { cascader };
}, {});
</script>

<style scoped>
:deep(.t-is-readonly),
:deep(.t-is-readonly .t-input__inner) {
    cursor: not-allowed;
}
</style>