export const setting = {
    component: {
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        default: {
            date: ["YYYY", "YYYY-MM", "YYYY-ww", "YYYY-MM-DD", "YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss", "HH:mm:ss"],
            dateRange: ["YYYY-MM", "YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss", "HH:mm:ss"]
        },
        type: {
            date: ["year", "month", "week", "date", "dates", "datetime", "time"],
            dateRange: ["monthrange", "daterange", "datetimerange", "timerange"],
            file: ["file", "image", "video", "doc", "excel", "ppt", "txt", "pdf"],
            /** 判断是不是枚举类型控件 */
            enum: ["checkbox", "radio", "select", "transfer"]
        },
        file: {
            /** 不允许的文件类型 */
            disAllow: ["exe", "dll", "bat", "bin", "msi", "vba", "js", "py"],
            /** 支持预览的文件类型 */
            view: ["bmp", "jpg", "jpeg", "png", "gif", "svg", "mp4", "avi", "mkv", "flv", "mp3", "wav", "pdf", "txt", "md", "json"],
            allow: {
                image: ["bmp", "jpg", "jpeg", "png", "tif", "gif", "svg", "cdr", "ai"],
                video: ["mp4", "wmv", "avi", "mkv", "rm", "rmvb", "flv", "3gp", "m4v"],
                audio: ["mp3", "wav", "flac", "alac", "wma", "ogg", "acc", "m4a"],
                doc: ["doc", "docx"],
                excel: ["xls", "xlsx"],
                ppt: ["ppt", "pptx"],
                txt: ["txt", "md", "json"],
                pdf: ["pdf"]
            }
        }
    },
};