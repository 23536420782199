<template>
    <div :class="{ 'z-radio': true, 'z-readonly': Readonly }">
        <t-radio-group v-model="model" @change="onChange">
            <t-radio v-for="option in options" :key="option.value" :value="option.value" :readonly="Readonly === true">
                {{ option.label || option.value }}
            </t-radio>
        </t-radio-group>
    </div>
</template>

<script>
import { useEnumComponent } from "../../hooks/useEnumComponent";
export default useEnumComponent();
</script>

<style scoped>
.z-radio {
    width: 100%;
}

.z-radio :deep(.t-radio-group) {
    width: 100%;
}

.z-radio :deep(.t-radio) {
    width: v-bind(width);
    margin: 0;
}

.z-radio :deep(.t-radio:hover) {
    color: var(--t-color-primary);
}

.z-radio :deep(.t-radio.t-radio--small .t-radio__label) {
    font-size: 14px;
}

.z-radio :deep(.t-radio.t-radio--small .t-radio__inner) {
    width: 16px;
    height: 16px;
}

.z-radio :deep(.t-radio.t-radio--small .t-radio__inner::after) {
    width: 7px;
    height: 7px;
}

.z-readonly :deep(.t-radio__input),
.z-readonly :deep(.t-radio__label) {
    cursor: not-allowed;
}
</style>