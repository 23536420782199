<template>
    <div v-if="app.isLoaded == false" id="app-progress"></div>
    <link rel="stylesheet" v-for="stylesheet in app.stylesheets" :key="stylesheet" :href="stylesheet" />
    <component v-if="app.isLoaded && getPageComponent(app.pagetype)" :is="getPageComponent(app.pagetype)"
               ref="crxContent" :pagetype="app.pagetype" v-bind="app.config" v-model="app.data" />
    <ZWindows></ZWindows>
    <t-sticky-tool :offset="[-50, 20]" shape="round" type="compact" :popup-props="stickyToolStore.PopupProps">
        <template v-for="item in stickyToolStore.stickytools" :key="item.label">
            <t-sticky-item :label="item.label" :trigger="item.trigger" :popup="item.popup">
                <template #icon>
                    <component :is="item.icon"></component>
                </template>
            </t-sticky-item>
        </template>
    </t-sticky-tool>
    <component :is="'script'" v-for="javascript in app.javascripts" :key="javascript" :src="javascript"
               type="text/javascript" />
</template>

<script setup>
import ZWindows from "./src/components/windows/windows.vue";
import { useAppStore } from "./src/stores/useAppStore";
import { getPageComponent } from "./pages/page";
import { getCurrentInstance, onMounted } from "vue";
import { useStickyToolStore } from "./src/stores/useStickyToolStore";

const app = window.$app = useAppStore();

const stickyToolStore = useStickyToolStore();

onMounted(() => {
    app.__init();
    app.__bindInstance(getCurrentInstance())
});
</script>
