<template>
    <t-progress v-if="show" :percentage="proccess" v-bind="options"></t-progress>
</template>

<script setup>
import { onBeforeUnmount, ref, watch } from "vue";
import { HasContent } from "../../utils/common";

const props = defineProps({
    time: { type: Number, default: 3000 },
    loading: { type: Boolean, default: null }
});

const options = {
    theme: "line",
    color: ['#0052D9', '#00A870'],
    label: false,
    status: "active",
    size: "small"
};

const proccess = ref(0);

const show = ref(false);

var task;

function startTask() {
    proccess.value = 0;
    show.value = true;
    runTask();
}

function runTask() {
    task = setTimeout(() => {
        if (proccess.value < 98)
            proccess.value++;
        runTask(task);
    }, speed());
}

function speed() {
    if (proccess.value < 50)
        return props.time / 200;
    if (proccess.value < 80)
        return props.time / 100;
    if (proccess.value < 90)
        return props.time / 50;
    if (proccess.value < 98)
        return props.time / 20;
}

function endTask() {
    proccess.value = 100;
    if (task) {
        clearTimeout(task);
        task = null;
    }
    setTimeout(() => {
        show.value = false;
    }, 200);
}

function cancelTask() {
    proccess.value = 0;
    show.value = false;
    if (task) {
        clearTimeout(task);
        task = null;
    }
}

const stopWatchs = [
    watch(() => props.loading, value => {
        if (HasContent(value)) {
            if (value) {
                startTask();
            } else {
                endTask();
            }
        } else {
            cancelTask();
        }
    }, { immediate: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>

<style scoped>
:deep(.t-progress__bar) {
    height: var(--td-size-2);
}
</style>