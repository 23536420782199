/** 页面设置 */
class PageConfig {
    /** 页面类型
     * @returns {String}
     */
    static get PageType() {
        if (typeof $pagetype == "undefined")
            return undefined;
        else
            return $pagetype;
    }
    /** 页面配置路径
     * @returns {String}
     */
    static get ConfigPath() {
        if (typeof $ZhcConfigPath == "undefined")
            return undefined;
        else
            return $ZhcConfigPath;
    }
    /** 页面配置参数
     * @returns {any}
     */
    static get ConfigParam() {
        if (typeof $ZhcConfigParam == "undefined")
            return undefined;
        else
            return $ZhcConfigParam;
    }
    /** 页面配置参数
     * @returns {any}
     */
    static get Config() {
        if (typeof $ZhcConfig == "undefined")
            return undefined;
        else
            return $ZhcConfig;
    }
    /** 页面查询条件
     * @returns {any}
     */
    static get Query() {
        if (typeof $ZhcQuery == "undefined")
            return undefined;
        else
            return $ZhcQuery;
    }
    static set Query(value) {
        $ZhcQuery = value;
    }
    /** 页面加载信息
     * @returns {String}
     */
    static get LoadMessage() {
        if (typeof $ZhcLoadInfoMessage == "undefined")
            return undefined;
        else
            return $ZhcLoadInfoMessage;
    }
    /** 页面数据
     * @returns {any}
     */
    static get Data() {
        if (typeof $ZhcData == "undefined")
            return undefined;
        else
            return $ZhcData;
    }

    static set Data(value) {
        $ZhcData = value;
    }

    /** 页面数据请求地址
     * @returns {String}
     */
    static get DataUrl() {
        if (typeof $ZhcDataUrl == "undefined")
            return undefined;
        else
            return $ZhcDataUrl;
    }
    /** 页面是否只读
     * @returns {Boolean}
     */
    static get IsMult() {
        if (typeof $ZhcDialogIsMult == "undefined")
            return undefined;
        else
            return $ZhcDialogIsMult;
    }
    /** 页面风格ID
     * @returns {Number}
     */
    static get SchemeID() {
        if (typeof $SchemeID == "undefined")
            return undefined;
        else
            return $SchemeID;
    }
    /** 页面风格ID
     * @returns {Number}
     */
    static get CasualLifeSvg() {
        if (typeof CasualLifeSvg == "undefined")
            return undefined;
        else
            return CasualLifeSvg;
    }
    /** 页面配置信息
     * @returns {any}
     */
    static get VueSetting() {
        if (typeof $VueSetting == "undefined")
            return undefined;
        else
            return $VueSetting;
    }
    /** 页面分页配置信息
     * @returns {number[]}
     */
    static get PageSizes() {
        var pageSizes;
        if (this.VueSetting)
            pageSizes = this.VueSetting.PageSizes;
        if (pageSizes && pageSizes.length > 0)
            return pageSizes;
        else
            return [20, 40, 60, 80, 100, 200, 400];
    }
    /** 默认分页大小
     * @returns {number}
     */
    static get DefaultPageLimit() {
        var limit;
        if (this.VueSetting)
            limit = this.VueSetting.DefaultPageLimit;
        return limit || this.PageSizes[Math.floor(this.PageSizes.length / 2)];
    }

    static get ViewFileServer() {
        if (typeof $ViewFileServer == "undefined")
            return "";
        else
            return $ViewFileServer;
    }
}

export {
    PageConfig
}