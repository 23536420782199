<template>
    <div :style="`width: ${column.width < 200 ? 200 : column.width}px;`">
        <t-input placeholder="请输入" v-model="strFilter" @input="onFilter" @clear="onFilter"
                 clearable></t-input>
        <div style="display: flex;align-items: center;">
            <t-checkbox class="z-checkitem" :indeterminate="isIndeterminate" v-model="isAllCheck"
                        @change="onAllCheck">
                <span v-text="model.length == 0 ? '全选' : `全选(选中${model.length}项)`"></span>
            </t-checkbox>
            <i v-show="model.length > 0" class="tur-close tur-icon-close" @click="onClear"></i>
            <i :class="'tur-sort tur-icon-sort' + column.order" @click="changeSort"></i>
        </div>
        <vxe-list ref="crxContent" :data="filters" height="30vh">
            <template #default="{ items }">
                <t-checkbox-group class="z-checkgroup" v-model="model" @change="onCheck">
                    <t-checkbox class="z-checkitem" v-for="item in items" :key="item.value" size="small"
                                :value="item.value">
                        <span v-text="item.label"></span>
                    </t-checkbox>
                </t-checkbox-group>
            </template>
        </vxe-list>
    </div>
</template>

<script setup>
import { debounce, uniqBy } from "lodash";
import { onBeforeUnmount, onBeforeUpdate, onMounted, ref } from "vue";
import { HasContent, IsNullOrEmpty, RegisterEvent } from "../../../utils/common";
import { GetEnumOptions } from "../../../hooks/useEnumComponent";

/**
 * @type {{
 *    column: import("vxe-table").VxeColumnProps,
 *    columnIndex: Number,
 *    options: Object,
 *    table: import("vxe-table").VxeTableInstance,
 *    grid: import("vxe-table").VxeGridInstance,
 *    panel: import("vxe-table").VxeFilterPanel
 * }}
 */
const props = defineProps({
    column: Object,
    columnIndex: { type: Number, default: null },
    options: Object,
    table: Object,
    grid: Object,
    panel: Object
});

const crxContent = ref();
const strFilter = ref("");
const filters = ref([]);
const isAllCheck = ref(false);
const isIndeterminate = ref(false);
const model = ref([]);
/** 当前渲染的列 */
const renderColumn = ref("");

/** 内部下拉选择筛选 */
const onFilter = debounce(() => {
    if (HasContent(crxContent.value)) {
        if (HasContent(strFilter.value)) {
            crxContent.value.reloadData(filters.value.filter(x => x.label.toLocaleLowerCase().indexOf(strFilter.value.toLocaleLowerCase()) > -1));
        } else {
            crxContent.value.reloadData(filters.value);
        }
    }
}, 300);

/** 选中项 */
function onCheck(value) {
    isIndeterminate.value = value.length > 0 && value.length < filters.value.length;
    isAllCheck.value = value.length > 0 && value.length == filters.value.length;
}

/** 全选/清空 */
function onAllCheck(value) {
    isAllCheck.value = value;
    if (value)
        model.value = filters.value.filter(x => x.label.toLocaleLowerCase().indexOf(strFilter.value.toLocaleLowerCase()) > -1).map(x => x.value);
    else
        model.value = [];
    isIndeterminate.value = false;
}

/** 清空选项 */
function onClear() {
    isAllCheck.value = false;
    isIndeterminate.value = false;
    model.value = [];
}

/** 变更排序 */
function changeSort() {
    switch (props.column.order) {
        case "asc":
            props.table.sort(props.column.field, "desc");
            break;
        case "desc":
            props.table.clearSort(props.column.field);
            break;
        default:
            props.table.sort(props.column.field);
            break;
    }
}

/** 筛选框加载时，实时计算加载筛选数据 */
function loadData() {
    let conf = props.column.params,
        parser = new DOMParser(),
        filterColumns = props.grid.getCheckedFilters(),
        filterColumn = filterColumns.find(x => x.column == props.column),
        //重新计算筛选数据，排除掉本列筛选值，剩下的筛选数据
        getFilterData = () => {
            let data = props.grid.getTableData().fullData;
            filterColumns.forEach(item => {
                if (item.column == props.column)
                    return;
                let field,
                    curColumn = item.column.params;
                switch (curColumn.Type) {
                    case "popup":
                        field = (curColumn.ShowCode || curColumn.OnlyName) ? curColumn.Field : curColumn.NameField;
                        break;
                    default:
                        field = curColumn.Field;
                        break;
                }
                data = data.filter(row => item.values.includes(String(row[field] || "")));
            });
            return data;
        },
        fnFiltersAsync = new Promise((resolve, reject) => {
            switch (conf.Type) {
                case "checkbox":
                case "select":
                case "radio":
                    {
                        GetEnumOptions(conf).then(({ map }) => {
                            resolve(uniqBy(getFilterData().map(x => {
                                let value = x[conf.Field];
                                return { label: map.dic[value] || value, value: String(value || "") };
                            }), x => x.value));
                        }).catch(reject);
                    }
                    break;
                case "popup":
                    {
                        let field = (conf.ShowCode || conf.OnlyName) ? conf.Field : conf.NameField;
                        resolve(uniqBy(getFilterData().map(x => {
                            return {
                                label: x[field] || "",
                                value: x[field] || ""
                            };
                        }), x => x.value));
                    }
                    break;
                default:
                    resolve(uniqBy(getFilterData().map(x => {
                        let value = x[conf.Field];
                        switch (conf.Type) {
                            case "switch":
                                return { label: value ? "是" : "否", value: String(value || "") };
                            case "template":
                                if (IsNullOrEmpty(value)) {
                                    return { label: "", value: "" };
                                } else {
                                    try {
                                        let tConfig = props.table.props.params,
                                            strTemplate = RegisterEvent(props.table, "RenderTemplate", () => value, tConfig, conf, x);
                                        let doc = parser.parseFromString(strTemplate, 'text/html');
                                        return { label: doc.body.textContent, value: String(value || "") };
                                    } catch (e) {
                                        return { label: "", value: String(value || "") }
                                    }
                                }
                            default:
                                return { label: String(value || ""), value: String(value || "") }
                        }
                    }), x => x.value));
                    break;
            }
        });
    fnFiltersAsync.then(values => {
        //根据数字、字母、中文进行排序
        filters.value = values.sort((x, y) => x.value.localeCompare(y.value, ["en", "zh"]));
        strFilter.value = "";
        if (filterColumn)
            model.value = filterColumn.values || [];
        isAllCheck.value = model.value.length > 0 && model.value.length == filters.value.length;
        isIndeterminate.value = model.value.length > 0 && model.value.length < filters.value.length;
        renderColumn.value = props.column.field;
    });
}

/** 提交筛选 */
function submitFilter() {
    if (model.value.length == 0) {
        props.table.clearFilter(renderColumn.value).then(() => props.table.updateData());
    } else {
        let value = filters.value.filter(x => model.value.includes(x.value)).map(x => ({ ...x, checked: true }));
        props.table.setFilter(renderColumn.value, value).then(() => props.table.updateData());
    }
}

onBeforeUpdate(() => {
    //字段直接切换时，不会触发onMounted、onBeforeUnmount事件，需要提交筛选和重新加载数据
    if (props.column.field != renderColumn.value) {
        submitFilter();
        loadData();
    }
});

/** 筛选关闭时，自动提交筛选，无需额外的操作，防止未点击筛选 */
onBeforeUnmount(() => {
    submitFilter();
});

onMounted(() => {
    loadData();
});
</script>

<style scoped>
.z-checklist {
    display: flex;
    flex-direction: column;
    max-height: 30vh;
    padding: 0;
    margin: 0;
}

.z-checkgroup {
    width: 100%;
}

.z-checkitem {
    width: 100%;
    padding-left: 4px;
    margin: 0;
    box-sizing: border-box;
}

.z-checkitem :deep(.t-checkbox__input) {
    flex-shrink: 0;
}

.z-checkitem:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.tur-sort,
.tur-close {
    padding: 3px 0.5em;
    border-radius: 2px;
}

.tur-sort:hover,
.tur-close:hover {
    cursor: pointer;
    color: var(--td-text-color-anti);
    background-color: var(--td-brand-color);
}

.tur-close {
    color: var(--td-error-color);
    font-weight: bolder;
}

.tur-close:hover {
    background-color: var(--td-error-color);
}
</style>