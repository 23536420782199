import { cloneDeep } from "lodash";
import { toFn } from "./common";

/** 查询队列 */
const queue = new Map();
/** 错误结果 */
const error = new Map();
/** 正确结果 */
const result = new Map();

/** 只请求一次，后续使用缓存结果
 * @description 如果需要更新，使用res.update进行更新
 * @template T
 * @template T2
 * @param {(...args:T)=>T2} fnReq 
 * @returns {(...args:T)=>T2 & {update:()=>void}}
 */
function OnceRequest(fnReq) {
    let fn = (...args) => {
        let pars = args.slice(0, fnReq.length - 3),
            fns = args.slice(fnReq.length - 3),
            key = JSON.stringify(pars);
        if (result.has(key))
            //请求成功
            toFn(fns[0])(cloneDeep(result.get(key)));
        else if (error.has(key))
            //请求错误
            toFn(fns[1])(cloneDeep(error.get(key)));
        else if (queue.has(key))
            //加到任务队列
            queue.get(key).push(fns);
        else {
            queue.set(key, [fns]);
            if (args.length < fnReq.length - 3) {
                fnReq(...args);
            } else {
                fnReq(...pars, res => {
                    result.set(key, res);
                    queue.get(key).forEach(x => toFn(x[0])(cloneDeep(res)));
                }, err => {
                    error.set(key, err);
                    queue.get(key).forEach(x => toFn(x[1])(cloneDeep(err)));
                }, () => {
                    queue.get(key).forEach(x => toFn(x[2])());
                    queue.delete(key);
                });
            }
        }
        return {
            update() {
                let pars = args.slice(0, fnReq.length - 3),
                    key = JSON.stringify(pars);
                queue.delete(key);
                error.delete(key);
                result.delete(key);
            }
        }
    };
    return fn;
}

export { OnceRequest }