<template>
    <template v-for="(menu, index) in model" :key="menu.id">
        <t-submenu v-if="menu.children && menu.children.length > 0" :value="bindPrefix(index)">
            <template #title>
                <high-light :value="menu.title" :keyword="keyword" />
            </template>
            <menu-tree-item v-model="menu.children" :prefix="bindPrefix(index)" :keyword="keyword"
                            @click="onClick"></menu-tree-item>
        </t-submenu>
        <t-menu-item v-else :value="bindPrefix(index)" @click="onClick(menu)">
            <template #content>
                <high-light :value="menu.title" :keyword="keyword" />
            </template>
        </t-menu-item>
    </template>
</template>

<script setup name="menu-tree-item">
import { padStart } from "lodash";
import { ZMenuItem } from "../../../models/ZMenuItem";
import highLight from "../../base/high-light.vue";

const props = defineProps({
    /** 菜单前缀 */
    prefix: String,
    /** 查询词 */
    keyword: String
});
const emits = defineEmits(["click"]);

/** 菜单配置
 * @type {import("vue").ModelRef<ZMenuItem[]>}
 */
const model = defineModel();

/** 菜单前缀 */
function bindPrefix(index) {
    return props.prefix + padStart(index, 2, "0");
}

/** 菜单点击事件 */
function onClick(menu) {
    emits("click", menu);
}
</script>