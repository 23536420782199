<template>
    <Menubar class="z-menu-bar">
        <template #start>
            <div class="z-menu-bar__tags" v-if="menuStore.multipleMode">
                <t-tag :theme="onActive('home')" class="z-unselect" @click="onClick('home')">
                    <HomeIcon :size="12"></HomeIcon>
                    <span v-text="menuStore.homeTab.title"></span>
                </t-tag>
                <line-scroller style="width: calc(100% - 80px);">
                    <t-space :size="4">
                        <template v-for="menuTab in menuStore.menuTabs" :key="menuTab.id">
                            <t-tag class="z-unselect" :theme="onActive(menuTab.id)"
                                   @click="onClick(menuTab)" @close="onClose(menuTab)"
                                   @contextmenu="onContextMenu($event, menuTab)" closable>
                                {{ menuTab.title }}
                            </t-tag>
                        </template>
                    </t-space>
                </line-scroller>
            </div>
            <t-breadcrumb v-else separator-icon="ico-arrow-right">
                <t-breadcrumb-item v-for="path in menuStore.activeMenuFullPath" :key="path">
                    <span v-text="path"></span>
                </t-breadcrumb-item>
            </t-breadcrumb>
        </template>
        <template #end>
            <t-space :size="4">
                <user-message v-if="Message" v-bind="Message"></user-message>
                <user-avatar v-if="User" v-bind="User" v-model="model"></user-avatar>
            </t-space>
        </template>
    </Menubar>
</template>

<script setup>
import Menubar from 'primevue/menubar';
import userAvatar from "../user/user-avatar.vue";
import userMessage from '../user/user-message.vue';
import { HomeIcon } from 'tdesign-icons-vue-next';
import lineScroller from '../../base/line-scroller.vue';
import { useMenuScore } from '../../../stores/useMenuStore';
import { onMounted, ref } from 'vue';

const menuStore = useMenuScore();

const model = defineModel();
const props = defineProps({
    /** 待办任务信息 */
    Task: Object,
    /** 消息信息 */
    Message: Object,
    /** 用户信息 */
    User: Object
});

/**
 * 激活菜单
 * @param {string} value
 */
function onActive(value) {
    return value == menuStore.activeMenuTab ? "primary" : "default";
}

/**
 * 切换菜单
 * @param {ZMenuItem} value
 */
function onClick(value) {
    menuStore.changeMenuTab(value);
}

/**
 * 关闭菜单
 * @param {ZMenuItem} value
 */
function onClose(value) {
    menuStore.closeMenuTab(value);
}

/**
 * 打开右键菜单
 * @param {MouseEvent} e
 * @param {ZMenuItem} value
 */
function onContextMenu(e, value) {
    menuStore.openContextMenu(e, value);
}

onMounted(() => {
    menuStore.initRecordMenuTabs();
});
</script>

<style scoped>
.z-menu-bar {
    padding: 3px 6px;
    border: none;
    box-shadow: var(--z-box-shadow);
    border-bottom: 1.4px solid var(--gray-300);
    border-radius: 0;
}

.z-menu-bar .z-menu-bar__tags {
    display: flex
}

.z-menu-bar :deep(.p-menubar-start) {
    width: 0;
    flex-grow: 1;
    margin-right: 6px;
}

.z-menu-bar :deep(.p-menubar-start>.t-space) {
    width: 100%;
}
</style>