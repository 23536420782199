<template>
    <t-dropdown @click="onClick" :options="options">
        <t-avatar class="z-unselect">{{ acronym }}</t-avatar>
    </t-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import { useUserStore } from '../../../stores/useUserStore';
import { toFn } from '../../../utils/common';

const user = useUserStore();

const model = defineModel();
const props = defineProps({
    /// <summary>用户名字段</summary>
    NameField: String,
    /// <summary>头像字段</summary>
    AvatarField: String,
    /** 菜单列表 */
    Buttons: {
        type: Array,
        default: () => []
    }
});
const emits = defineEmits(['click']);

const options = computed(() => {
    return props.Buttons.map(btn => ({
        content: btn.Label,
        theme: btn.Style,
        config: btn
    }));
});

/**
 * 用户首字母缩写
 */
const acronym = computed(() => {
    var userName = model.value[props.NameField];
    if (userName && userName.length > 0)
        return userName[0];
    else
        return "A";
});

/**
 * 点击事件
 * @param {{theme: string, config: object, content: string}} btn 
 */
function onClick(btn) {
    toFn(btn.config)();
    emits("click", btn);
}

</script>
