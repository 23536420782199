import dayjs from "dayjs";
import { HasContent } from "../utils/common";
import Cookies from "js-cookie";

/** 时间格式化
 * @param {string} format 
 * @description [Y,D]自动大写，[f,F]为毫秒。兼容C# DateTime格式化标准
 */
Date.prototype.Format = function (format) {
    return dayjs(this).format(format.replace(/([yd])/g, $1 => $1.toLocaleUpperCase()).replace(/f|F/g, "S"));
};

/** 设置Cookie
 * @param {string} name
 * @param {string} value
 * @author anturin
 */
window.setCookie = function (name, value, options) {
    Cookies.set(name, value, options);
}

/** 获取Cookie
 * @param {String} name
 * @returns {String} cookie
 * @author anturin
 */
window.getCookie = function (name) {
    return Cookies.get(name);
}

/** 移除Cookie
 * @param {String} name
 * @author anturin
 */
window.removeCookie = function (name, options) {
    Cookies.remove(name, options);
}

/** 是否包含在数组中
 * @template T
 * @param {T} item 
 * @param {T[]} arr 
 * @returns {Boolean} 
 * @author anturin
 */
window.InArray = function (item, arr) {
    if (HasContent(item) && HasContent(arr))
        return arr.includes(item);
    return false;
}