<template>
    <div :class="{ 'z-checkbox': true, 'z-readonly': Readonly }">
        <template v-if="IsMult !== false">
            <div class="z-checkbox__all" v-if="Readonly !== true && options.length > 4">
                <t-checkbox style="width: fit-content;" :indeterminate="indeterminate" v-model="all">
                    <span>全选</span>
                </t-checkbox>
                <span class="t-checkbox-reverse z-unselect">/</span>
                <span class="t-checkbox-reverse z-unselect" @click="onCheckReverse">反选</span>
            </div>
            <t-checkbox-group v-model="model" @change="onChange">
                <t-checkbox v-for="option in options" :key="option.value" :value="option.value"
                            :readonly="Readonly === true">
                    <span v-text="OptionRender(option)"></span>
                </t-checkbox>
            </t-checkbox-group>
        </template>
        <t-checkbox v-else :readonly="Readonly === true" v-model="model" @change="onChange">
            <span v-text="OptionRender(option)"></span>
        </t-checkbox>
    </div>
</template>

<script>
import { difference } from "lodash";
import { computed } from "vue";
import { HasContent, toFn } from "../../utils/common";
import { useEnumComponent } from "../../hooks/useEnumComponent";

export default useEnumComponent(({ props, model, options, onChange }) => {
    return {
        indeterminate: computed(() => {
            return model.value.length > 0 && model.value.length < options.value.length;
        }),
        all: computed({
            get() {
                return options.value.length > 0 && model.value.length == options.value.length;
            },
            set(value) {
                if (value) {
                    onChange(options.value.map(x => x.value));
                }
                else {
                    onChange([]);
                }
            }
        }),
        onCheckReverse() {
            onChange(difference(options.value.map(x => x.value), model.value));
        },
        OptionRender(option) {
            option = option || props;
            if (HasContent(props.OptionRender)) {
                return toFn(props.OptionRender)(props, option);
            } else if (HasContent(option)) {
                return option.label || option.value;
            } else {
                return "";
            }
        }
    };
}, {});
</script>

<style scoped>
.z-checkbox {
    width: 100%;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.z-checkbox__all {
    display: flex;
    align-content: center;
}

.z-checkbox__all :deep(.t-checkbox-reverse) {
    line-height: 24px;
    height: 24px;
    display: inline-block;
    font-size: 14px;
    margin-left: 2px;
}

.z-checkbox__all :deep(.t-checkbox-reverse:hover) {
    cursor: pointer;
    color: var(--brand-main);
}

.z-checkbox :deep(.t-checkbox-group) {
    width: 100%;
    gap: 0;
}

.z-checkbox :deep(.t-checkbox) {
    width: v-bind(width);
}

.z-checkbox :deep(.t-checkbox:hover) {
    color: var(--brand-main);
}

.z-checkbox :deep(.t-checkbox.t-checkbox--small .t-checkbox__label) {
    font-size: 14px;
}

.z-checkbox :deep(.t-checkbox.t-checkbox--small .t-checkbox__inner) {
    width: 18px;
    height: 18px;
}

.z-checkbox :deep(.t-checkbox__input.is-indeterminate .t-checkbox__inner::before) {
    height: 4px;
}

.z-checkbox :deep(.t-checkbox.t-checkbox--small .t-checkbox__input.is-indeterminate .t-checkbox__inner::before) {
    top: 6px;
}

.z-checkbox :deep(.t-checkbox.t-checkbox--small .t-checkbox__inner::after) {
    width: 4px;
    height: 10px;
}

.z-checkbox :deep(.t-checkbox__inner::after) {
    border-width: 2px;
}

.z-checkbox :deep(.t-checkbox__input.is-checked .t-checkbox__inner::after) {
    transform: rotate(45deg) scaleY(1) translateX(1.5px) translateY(-.5px)
}

.z-readonly :deep(.t-checkbox__input),
.z-readonly :deep(.t-checkbox__label) {
    cursor: not-allowed;
}
</style>