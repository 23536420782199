import { isArray, reject } from "lodash";
import { setting } from "../config/appsetting";
import { HasContent, RegisterEvent } from "../utils/common";
import { nextTick } from "vue";
import { GetEnumOptions } from "./useEnumComponent";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import dayOfYear from "dayjs/plugin/dayOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(quarterOfYear);
dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);

/**
 * 绑定默认值
 * @param {Array} controls
 * @param {*} model
 */
function bindDefault(controls, model) {
    if (controls && controls.length > 0 && model) {
        let tasks = controls.filter(x => {
            if (x.DefaultValue || (setting.component.type.enum.includes(x.Type) && x.CanNull == false)) {
                if (setting.component.type.dateRange.includes(x.Type))
                    return (x.BeginField in model && x.EndField in model) == false;
                return (x.Field in model) == false;
            }
            return false;
        }).map(x => GetDefaultValue(x).then(value => {
            if (setting.component.type.dateRange.includes(x.Type)) {
                if (isArray(value)) {
                    if (value.length > 0 && x.BeginField)
                        model[x.BeginField] = value[0];
                    if (value.length > 1 && x.EndField)
                        model[x.EndField] = value[1];
                } else {
                    if (x.BeginField)
                        model[x.BeginField] = value;
                    else if (x.EndField)
                        model[x.EndField] = value;
                }
            } else {
                model[x.Field] = value;
            }
        }));
        if (tasks.length > 0)
            return Promise.all(tasks);
    }
    return Promise.resolve();
}

/** 时间格式化
 * @param {String} value 
 * @returns {String}
 * @description 
 *   $now:当前时间
 *   $today:今天
 *   $now.startDay 今天开始 00:00:00
 *   $now.startMonth 这个月开始 1日
 *   $now.start...
 *   $now.endDay 今天结束 23:59:59
 *   $now.endMonth 这个月结束 28、30、31日
 *   $now.end...
 *   $now.atHour5 今天的5点 
 *   $now.atMonth5 5月份的今天日期
 *   $now.beforeDay5 5天前
 *   $now.beforeMonth5 5个月前
 *   $now.before...
 *   $now.afterDay5 5天后
 *   $now.afterMonth5 5个月后
 *   $now.after...
 *   $now.dayOfYear 今年第几天
 *   $now.weekOfYear 今年第几周
 *   $now.quarterOfYear 今年第几季度
 *   $now.date 今天日期 26
 *   $now.day 今天是星期几 5
 *   $now.week 这个月第几周
 *   $now:format 日期格式化 默认 YYYY-MM-DD HH:mm:ss
 *   格式化链接：https://day.js.org/docs/zh-CN/display/format
 */
function convertValue(value) {
    if (typeof value == "string") {
        var isNow = value.startsWith("$now"),
            isToday = value.startsWith("$today");
        if (isNow || isToday) {
            let date = dayjs();
            if (isToday)
                date = date.startOf("day");
            var fns = value.match(/(\.|:)[^\.:]+/g),
                format = setting.component.dateFormat;
            if (fns) {
                for (let i = 0; i < fns.length; i++) {
                    let fn = fns[i];
                    if (fn.startsWith(".start"))
                        date = date.startOf(fn.substring(6).toLocaleLowerCase());
                    else if (fn.startsWith(".end"))
                        date = date.endOf(fn.substring(4).toLocaleLowerCase());
                    else if (fn.startsWith(".at")) {
                        var part = fn.substring(3).match(/^[^\d]+/g),
                            number = fn.substring(3).match(/\d+$/g);
                        if (part.length > 0 && number.length > 0) {
                            fn = part[0].toLocaleLowerCase();
                            if (fn == "month")
                                date = date[fn](parseInt(number[0]) - 1);
                            else
                                date = date[fn](parseInt(number[0]));
                        }
                    } else if (fn.startsWith(".before")) {
                        var part = fn.substring(7).match(/^[^\d]+/g),
                            number = fn.substring(7).match(/\d+$/g);
                        if (part.length > 0)
                            date = date.subtract(number.length > 0 ? parseInt(number[0]) : 1, part[0].toLocaleLowerCase());
                    } else if (fn.startsWith(".after")) {
                        var part = fn.substring(6).match(/^[^\d]+/g),
                            number = fn.substring(6).match(/\d+$/g);
                        if (part.length > 0)
                            date = date.add(number.length > 0 ? parseInt(number[0]) : 1, part[0].toLocaleLowerCase());
                    } else if (fn.startsWith(":"))
                        format = fn.substring(1);
                    else {
                        fn = fn.substring(1);
                        if (fn == "month")
                            return date[fn]() + 1;
                        else
                            return date[fn]();
                    }
                }
            }
            return date.format(format);
        }
    }
    return value;
}

/** 获取默认值
 * @param {any} component 组件属性
 * @returns {Promise<any>} 返回默认值
 * @author anturin
 */
function GetDefaultValue(component) {
    return new Promise(resolve => {
        if (HasContent(component)) {
            try {
                if ("DefaultValue" in component) {
                    let res;
                    if (Array.isArray(component.DefaultValue))
                        res = component.DefaultValue.map(convertValue);
                    else
                        res = convertValue(component.DefaultValue);
                    resolve(res);
                    return;
                }
                else if (setting.component.type.enum.includes(component.Type)) {
                    if (component.CanNull === false) {
                        GetEnumOptions(component).then(res => {
                            if (res.options.length > 0)
                                resolve(res.options[0].option);
                            else
                                resolve();
                        }).catch(err => resolve());
                        return;
                    }
                }
            } catch (err) {
                console.error(err);
                reject(err);
            }
        }
        resolve();
    });
}
/** 清空值
 * @param {*} control 
 * @param {*} model 
 */
function clearValue(control, model) {
    if (control && model) {
        switch (control.Type) {
            case "popup":
                if (control.Field)
                    delete model[control.Field];
                if (control.NameField)
                    delete model[control.NameField];
                if (control.SelectMap)
                    Object.keys(control.SelectMap).forEach(key => delete model[key]);
                break;
            default:
                if (setting.component.type.dateRange.includes(control.Type)) {
                    if (control.BeginField)
                        delete model[control.BeginField];
                    if (control.EndField)
                        delete model[control.EndField];
                } else {
                    if (control.Field)
                        delete model[control.Field];
                }
                break;
        }
    }
}

/** 回滚组件变更
 * @param {*} control 
 * @param {*} data 
 * @param {*} oldValue
 */
function RollBack(control, data, oldValue) {
    switch (control.Type) {
        case "popup":
            if (oldValue) {
                if (control.Field)
                    data[control.Field] = oldValue[control.Field];
                if (control.NameField)
                    data[control.NameField] = oldValue[control.NameField];
                if (control.SelectMap)
                    Object.keys(control.SelectMap).forEach(key => data[key] = oldValue[key]);
            } else {
                if (control.Field)
                    delete data[control.Field];
                if (control.NameField)
                    delete data[control.NameField];
                if (control.SelectMap)
                    Object.keys(control.SelectMap).forEach(key => delete data[key]);
            }
            break;
        default:
            if (setting.component.type.dateRange.includes(control.Type)) {
                if (control.BeginField) {
                    if (isArray(oldValue) && oldValue.length > 0)
                        data[control.BeginField] = oldValue[0];
                    else
                        delete data[control.BeginField];
                }
                if (control.EndField) {
                    if (isArray(oldValue) && oldValue.length > 0)
                        data[control.EndField] = oldValue[1];
                    else
                        delete data[control.EndField];
                }
            } else {
                if (control.Field)
                    data[control.Field] = oldValue;
            }
            break;
    }
}

/** 触发组件变更
 * @param {Object} param0 
 * @param {*} param0.control 
 * @param {*} param0.oldValue 
 * @param {*} param0.data 
 * @param {String} param0.event 
 */
function onFieldChange({ control, oldValue, data, event, params }) {
    RegisterEvent({
        RollBack() {
            nextTick(() => {
                RollBack(control, data, oldValue)
            });
        }
    }, event, undefined, ...params);
}

export {
    bindDefault,
    clearValue,
    onFieldChange
}