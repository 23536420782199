<template>
    <div class="z-auto-column" @mouseenter="onMouseOver" @mouseleave="onMouseLeave">
        <template v-if="AutoCollapsed !== false && isCollapsed == false" && hideItems.length > 0>
            <component class="z-auto-column__pin" :is="isPin ? PinFilledIcon : PinIcon" @click="isPin = !isPin"
                color="var(--primary-500)" :size="16" />
        </template>
        <div class="z-auto-column__content" ref="crxContent">
            <div v-for="(item, index) in showItems" :key="index" :style="`--z-column__span:${item[colSpan] || 1}`">
                <slot :item="item" :index="index" v-if="renderVisible(item)"></slot>
            </div>
            <template v-if="isCollapsed == false">
                <div v-for="(item, index) in hideItems" :key="index" :style="`--z-column__span:${item[colSpan] || 1}`">
                    <slot :item="item" :index="index" v-if="renderVisible(item)"></slot>
                </div>
            </template>
        </div>
        <div v-if="hideItems.length > 0" class="z-auto-column__collapsed">
            <t-button v-if="AutoCollapsed == false" size="small" @click="isCollapsed = !isCollapsed" variant="outline"
                theme="primary" ghost>
                {{ isCollapsed ? '更多内容' : '隐藏更多' }}
            </t-button>
            <template v-if="isCollapsed">
                <span class="z-auto-column__text" style=" margin-left: 10px; color: var(--primary-color);">内容：</span>
                <template v-for="(item, index) in hideItems" :key="index">
                    <slot class="z-auto-column__writhing" name="collapsed" :item="item" :index="index" v-if="renderVisible(item)"></slot>
                </template>
            </template>
        </div>
    </div>
</template>

<script setup>
import { debounce } from 'lodash';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { PinIcon, PinFilledIcon } from "tdesign-icons-vue-next";

const props = defineProps({
    /**
     * 列最小宽度
     */
    minWidth: { type: Number || String, default: null },
    /**
     * 列最大宽度
     */
    maxWidth: { type: Number, default: null },
    /**
     * 最大列数
     */
    maxCount: { type: Number, default: null },
    /**
     * 最大行数，超出自动不显示
     */
    maxRow: { type: Number, default: null },
    /**
     * 列数据
     */
    items: { type: Array, default: [] },
    /**
     * 列数字段
     */
    colSpan: String,
    /**
     * 是否自动折叠
     */
    AutoCollapsed: { type: Boolean, default: false }
});

const emits = defineEmits(["change", "renderVisible"]);

/**
 * @type {import("vue").Ref<HTMLElement>}
 */
const crxContent = ref(props.maxCount);

/** 宽度 */
const width = ref(0);

/**
 * 渲染可见列
 */
function renderVisible(item) {
    let res = { visible: true };
    emits("renderVisible", item, res);
    return res.visible !== false;
}

const beforeCount = ref(1);

/** 显示的内容 */
const showItems = ref([]);
/** 隐藏的内容 */
const hideItems = ref([]);
/** 是否折叠 */
const isCollapsed = ref(true);

/** 是否固定 */
const isPin = ref(false);

const onMouseOver = debounce(() => {
    if (props.AutoCollapsed !== false) {
        isCollapsed.value = false;
    }
}, 200);

const onMouseLeave = debounce(() => {
    if (props.AutoCollapsed !== false && isPin.value == false) {
        isCollapsed.value = true;
    }
}, 200);

const calculateItems = debounce((value, maxRow, columnCount) => {
    if (maxRow > 0) {
        var show = [], hide = [],
            row = 1,
            count = 0,
            sitRow = function (item, row) {
                if (row > maxRow)
                    hide.push(item);
                else
                    show.push(item);
            };
        for (let i = 0; i < value.length; i++) {
            const item = value[i],
                newCount = item[props.colSpan] || 1;
            if (renderVisible(item) == false)
                continue;
            if (newCount >= columnCount) {
                if (count > 0) {
                    row++;
                    count = 0;
                }
                sitRow(item, row);
                row++;
            } else {
                if (count + newCount == columnCount) {
                    sitRow(item, row);
                    row++;
                    count = 0;
                } else if (count + newCount > columnCount) {
                    row++;
                    sitRow(item, row);
                    count = newCount;
                } else {
                    count += newCount;
                    sitRow(item, row);
                }
            }
        }
        showItems.value = show;
        hideItems.value = hide;
    } else {
        showItems.value = value;
    }
}, 200);

const changeCount = debounce(() => {
    if (width.value > 0) {
        let count = 1;
        if (props.maxCount > 0) {
            count = Math.floor(width.value / (props.minWidth || 300));
            if (count > props.maxCount)
                count = props.maxCount;
            if (count < 1)
                count = 1;
        } else if (props.maxWidth > 0) {
            count = Math.floor(width.value / (props.maxWidth || 300));
            if (count < 1)
                count = 1;
        } else {
            console.warn("maxCount or maxWidth is required");
        }
        crxContent.value.style.setProperty("--z-column__width", `${Math.floor(100 / count)}%`);
        if (beforeCount.value != count) {
            beforeCount.value = count;
            calculateItems(props.items, props.maxRow, count);
            emits("change", count);
        }
    }
}, 100);

const observer = new ResizeObserver(debounce(() => {
    if (crxContent.value) {
        var newWidth = crxContent.value.getBoundingClientRect().width;
        // 页面切换时，会变成0。切换回来时不需要重复渲染
        if ((newWidth == 0 && width.value > 0) || (newWidth > 0 && width.value == newWidth))
            return;
        width.value = newWidth;
        changeCount();
    }
}, 200));

const stopWatchs = [
    watch(() => props.maxCount, changeCount, { immediate: true }),
    watch(() => props.maxWidth, changeCount, { immediate: true }),
    watch(() => props.minWidth, changeCount, { immediate: true }),
    watch(() => props.maxRow, value => {
        calculateItems(props.items, value, beforeCount.value);
    }, { immediate: true }),
    watch(() => props.items, value => {
        calculateItems(value, props.maxRow, beforeCount.value);
    }, { immediate: true, deep: true })
];

onMounted(() => {
    observer.observe(crxContent.value);
});

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
    if (crxContent.value instanceof Element)
        observer.unobserve(crxContent.value);
    observer.disconnect();
});

</script>

<style scoped>
.z-auto-column {
    position: relative;
}

.z-auto-column__pin {
    position: absolute;
    bottom: 5px;
    left: 5px;
}

.z-auto-column__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.z-auto-column__content>div {
    width: calc(var(--z-column__width) * var(--z-column__span));
}

.z-auto-column__collapsed {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 6px;
    margin-bottom: 6px;
}

.z-auto-column__text {
    display: flex;
}

.z-auto-column__writhing {
    display: flex;
}
</style>