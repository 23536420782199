<template>
    <div class="z-charts">
        <div class="z-charts__body" ref="crxChart"></div>
    </div>
</template>

<script setup>
import * as echarts from "echarts";
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { HasContent, IsNullOrEmpty } from "../../utils/common";
import { HttpPost } from "../../utils/network";
import { cloneDeep, debounce, isArray, range } from "lodash";

const props = defineProps({
    /** 图表标题 */
    Label: String,
    /** 图表模板 */
    Template: String,
    /** 图表取数地址 */
    Url: String,
    /** 图表名称字段（X轴） */
    NameField: String,
    /** 图表值字段（Y轴），单个字符串或字符数组 */
    Field: String || Array,
    /** 表格具体配置
     * @description 详见：https://echarts.apache.org/zh/option.html
     */
    Option: Object
});

const observer = new ResizeObserver(() => {
    try {
        myChart.value && myChart.value.resize();
    } catch (err) {
        console.error(err);
    }
});

/** ECharts 对象
 * @type {import("vue").Ref<import("echarts").ECharts>}
 */
const myChart = ref();
/** 
 * @type {import("vue").Ref<HTMLDivElement>}
 */
const crxChart = ref();

/** 转换字段
 * @param {string} field 
 */
function ConvertField(field) {
    let index = field.indexOf("#");
    if (index < 0)
        return { name: field };
    else
        return { name: field.substring(0, index), displayName: field.substring(index + 1) };
}

/** 加载数据 */
function LoadData(query) {
    if (props.Url) {
        myChart.value.showLoading();
        HttpPost(props.Url, query, res => {
            inputValue.value = isArray(res.data) ? res.data : [res.data];
        }, null, () => myChart.value.hideLoading());
    }
}

const inputValue = ref([]);

const option = computed(() => {
    let xAxis = props.NameField,
        yAxis = isArray(props.Field) ? props.Field : [props.Field],
        fields = [xAxis, ...yAxis],
        data = [];
    if (inputValue.value.length > 0) {
        data.push([xAxis, ...new Set(inputValue.value.map(x => x[xAxis]))]);
        yAxis.forEach(field => data.push([field, ...inputValue.value.map(x => x[field])]));
    }
    /**
     * @type {import("echarts").EChartsOption}
     */
    let result = {
        ...props.Option,
        dataset: {
            dimensions: fields,
            source: data
        }
    };
    if (result.dataZoom) {
        if (data.length < 5)
            result.dataZoom = [];
        else if (data.length > 5 && result.dataZoom.length > 0)
            result.dataZoom[0].end = Math.floor(500 / data.length);
    }
    //复制最后一个series
    if (result.series) {
        let series = range(yAxis.length - result.series.length).map(x => cloneDeep(result.series[result.series.length - 1]));
        result.series.push(...series);
    }
    return result;
});

const stopWatchs = [
    watch(() => option.value, value => {
        if (myChart.value) {
            let isInit = myChart.value.getOption() === undefined,
                _option = { backgroundColor: "", ...value };
            myChart.value.setOption(_option);
            // 注意：初始化时，未知原因，需要重复调用 setOption。否则 resize 会报错。导致无法重新渲染大小。
            if (isInit)
                nextTick(() => myChart.value.setOption(_option, false));
        }
    }, { deep: true })
];

onMounted(() => {
    observer.observe(crxChart.value);
    myChart.value = echarts.init(crxChart.value);
    LoadData();
});

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
    if (crxChart.value)
        observer.unobserve(crxChart.value);
    observer.disconnect();
});

defineExpose({
    /** 变更黑夜模式 */
    ChangeDark(dark) {
        myChart.value.init(crxChart.value, dark ? "dark" : "");
    },
    /** 更新大小 */
    RefreshSize() {
    },
    /** 刷新数据 */
    Refresh(query) {
        LoadData(query);
    }
});
</script>

<style scoped>
.z-charts {
    width: 100%;
    height: 100%;
}

.z-charts__body {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>