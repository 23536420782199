import { debounce } from "lodash";
import { ref } from "vue";
import { EqualsObject } from "../../../utils/common";

/**
 * @param {Object} param0 
 * @param {import("vue").Ref<string>} param0.keyword 
 * @param {import("vue").Ref<boolean>} param0.ShowCode
 * @param {import("vue").Ref<boolean>} param0.OnlyName
 * @param {import("vue").Ref<string>} param0.Field
 * @param {import("vue").Ref<string>} param0.NameField
 * @param {(event: "change" | "update:modelValue", value)=> void} param0.emits
 */
export function useSinglePopup({ keyword, ShowCode, OnlyName, Field, NameField, SetValue }) {
    const model = ref({});

    /** 设定值
     * @param {any} value 
     */
    function setValue(value) {
        keyword.value = ShowCode || OnlyName ? value[Field] : value[NameField];
        if (EqualsObject(model.value, value)) {
            return false;
        } else {
            model.value = value;
            return true;
        }
    }

    /**
     * 检查数据，不正确直接删除
     * @author anturin
     */
    function checkData() {
        if (ShowCode || OnlyName) {
            if (model.value[Field] != keyword.value)
                SetValue({});
        } else {
            if (model.value[NameField] != keyword.value)
                SetValue({});
        }
    }

    const checkDataAsync = debounce(checkData, 200);

    return {
        model,
        setValue,
        checkData,
        checkDataAsync
    }
}