<template>
    <div class="z-auto-height" ref="crxContent">
        <slot name="header"></slot>
        <div :class="{
            'z-auto-height__body': true,
            'z-auto-height__scroll': scroll,
            'z-auto-height__close': close
        }" ref="crxBody">
            <slot :height="autoHeight"></slot>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script setup>
import { debounce } from "lodash";
import { onBeforeUnmount, onMounted, ref } from "vue";

const props = defineProps({
    /** 滚动条 */
    scroll: { type: Boolean, default: false },
    /** 是否关闭自动高度 */
    close: { type: Boolean, default: false }
});

const emits = defineEmits(["change"]);

/** 自动高度 */
const autoHeight = ref(0);
/**
 * @type {import("vue").Ref<HTMLElement>}
 */
const crxContent = ref();
/**
 * @type {import("vue").Ref<HTMLElement>}
 */
const crxBody = ref();

/** 自动计算高度 */
var calculate = debounce(() => {
    if (crxBody.value) {
        let rect = crxBody.value.getBoundingClientRect(),
            height = Math.floor(rect.height);
        if (autoHeight.value != height) {
            autoHeight.value = height;
            crxBody.value.style.setProperty("--auto-height", `${height}px`);
            emits("change", autoHeight.value);
        }
    }
}, 120);

const observer = new ResizeObserver(() => {
    calculate();
});

onMounted(() => {
    observer.observe(crxBody.value);
});

onBeforeUnmount(() => {
    if (crxBody.value)
        observer.unobserve(crxBody.value);
    observer.disconnect();
});
</script>

<style scoped>
.z-auto-height {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.z-auto-height__body {
    height: 0;
    flex-grow: 1;
}

.z-auto-height__scroll {
    overflow-y: auto;
}
</style>