import { renderer } from "vxe-table";
import { h } from "vue";
import zFilterNumber from "./filter-number.vue";
import zFilter from "./filter.vue";
import zEdit from "./edit.vue";
import zView from "./view.vue";
import zFooter from "./footer.vue";
import zHeader from "./header.vue";
import zTableButton from "./table-button.vue";
import { HasContent } from "../../../utils/common";
import { forEach } from "lodash";


function toProps(value, ...args) {
    let res = {};
    Object.keys(value).forEach(key => {
        if (key[0] == "$")
            res[key.substring(1)] = value[key];
        else
            res[key] = value[key];
    });
    if (args)
        forEach(args, arg => Object.assign(res, arg));
    return res;
}

/** 注册筛选器 */
renderer.add("z-filter", {
    showFilterFooter: false,
    renderFilter(options, pars) {
        switch (pars.column.params.Type) {
            case "number":
            case "money":
                return h(zFilterNumber, toProps(pars));
            default:
                return h(zFilter, toProps(pars));
        }
    },
    filterResetMethod({ options }) {
        options.forEach(x => x.checked = false);
    },
    filterRecoverMethod({ option }) {
        option.forEach(x => x.checked = false);
    },
    filterMethod({ option = { value }, row, column }) {
        let value = row[column.field];
        if (HasContent(value))
            return String(value) === option.value;
        else
            return option.value === "";
    }
});

renderer.add("z-edit", {
    autofocus: "",
    renderHeader(options, pars) {
        return h(zHeader, toProps(pars));
    },
    renderEdit(options, pars) {
        return h(zEdit, toProps(pars));
    },
    renderCell(options, pars) {
        return h(zView, toProps(pars));
    },
    renderFooter(options, pars) {
        return h(zFooter, toProps(pars));
    },
    // editClickMethod({ options, option, row, column }) {

    // },
    // editKeydownMethod({ options, option, row, column, event }) {

    // },
    // editActivedMethod({ options, option, row, column }) {

    // },
    // editClosedMethod({ options, option, row, column }) {

    // },
    // editCommitMethod({ options, option, row, column }) {

    // }
});

renderer.add("z-footer", {
    renderFooter(options, pars) {
        return h(zFooter, toProps(pars));
    }
});

renderer.add("z-tabar", {
    renderToolbarTool(options, pars) {
        return h(zTableButton, toProps(pars, { align: "right" }));
    },
    renderToolbarButton(options, pars) {
        return h(zTableButton, toProps(pars, { align: "left" }));
    }
});