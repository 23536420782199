<template>
    <div class="z-edit-panel">
        <template v-for="{ type, config } in panels" :key="config">
            <template v-if="type == 'form'">
                <z-form v-bind="config" v-model="model" @change="onChange"></z-form>
            </template>
            <template v-else-if="type == 'area'">
                <p-field-set v-for="area in config" :key="area" :legend="area.Label">
                    <edit-panel v-bind="area" v-model="model"></edit-panel>
                </p-field-set>
            </template>
            <template v-else-if="type == 'tab'">
                <t-tabs v-model="tabValue">
                    <template v-for="tab in config" :key="tab">
                        <t-tab-panel :value="tab.Label" :label="tab.Label" :destroy-on-hide="false">
                            <edit-panel v-bind="tab" v-model="model"></edit-panel>
                        </t-tab-panel>
                    </template>
                </t-tabs>
            </template>
            <template v-else-if="type == 'annex'">
                <z-annex v-bind="config" v-model="model"></z-annex>
            </template>
            <template v-else-if="type == 'album'">
                <z-album v-bind="config" v-model="model"></z-album>
            </template>
            <template v-else-if="type == 'table'">
                <template v-if="Array.isArray(config)">
                    <div v-for="table in config" :key="table.Label">
                        <z-table v-bind="table" v-model="model"></z-table>
                    </div>
                </template>
                <div v-else>
                    <z-table v-bind="config" v-model="model"></z-table>
                </div>
            </template>
            <template v-else-if="type == 'record'">
                <z-record v-bind="config" v-model="model"></z-record>
            </template>
        </template>
    </div>
</template>

<script setup>
import zForm from "./form.vue";
import zAnnex from "./annex.vue";
import zAlbum from "./album.vue";
import zTable from "./table.vue";
import zRecord from "./record.vue";
import pFieldSet from 'primevue/fieldset';

import { computed, getCurrentInstance, onMounted, onBeforeUnmount, ref } from "vue";
import { HasContent, IsNullOrEmpty, RegisterEvent } from "../../utils/common";
import { onFieldChange } from "../../hooks/useInputComponent";

defineOptions({ name: "EditPanel" });

const props = defineProps({
    ColCount: { type: Number, default: null },
    Content: Object,
    Areas: Array,
    Tabs: Array,
    Annex: Object,
    Album: Object,
    SubContent: Object,
    SubContents: Object,
    Record: Object,
    FootContent: Object,
    Readonly: { type: Boolean, default: null },
    /** 忽略边框 */
    ignoreBorder: Boolean
});

const model = defineModel();

const emits = defineEmits(["change"]);

const _tabValue = ref("");
const tabValue = computed({
    get() {
        if (props.Tabs) {
            let value;
            for (let i = 0; i < props.Tabs.length; i++) {
                let tab = props.Tabs[i];
                if (RenderTabVisible(tab)) {
                    value = tab.Label;
                    if (IsNullOrEmpty(_tabValue.value) || _tabValue.value == value)
                        break;
                }
            }
            return value;
        }
    },
    set(value) {
        _tabValue.value = value;
    }
});

const panels = computed(() => {
    let res = [];
    if (HasContent(props.Content)) {
        res.push({
            type: "form",
            config: {
                ColCount: props.ColCount,
                Content: props.Content,
                Readonly: props.Readonly
            }
        });
    }
    if (HasContent(props.Areas)) {
        res.push({
            type: "area",
            config: props.Areas.filter(x => RenderAreaVisible(x))
                .map(x => ({ ...x, Readonly: x.Readonly || props.Readonly }))
        });
    }
    if (HasContent(props.Tabs)) {
        res.push({
            type: "tab",
            config: props.Tabs.filter(x => RenderTabVisible(x))
                .map(x => ({ ...x, Readonly: x.Readonly || props.Readonly }))
        });
    }
    if (HasContent(props.Annex)) {
        res.push({
            type: "annex",
            config: {
                ...props.Annex,
                Readonly: props.Annex.Readonly || props.Readonly
            }
        });
    }
    if (HasContent(props.Album)) {
        res.push({
            type: "album",
            config: {
                ...props.Album,
                Readonly: props.Album.Readonly || props.Readonly
            }
        });
    }
    if (HasContent(props.Record)) {
        res.push({
            type: "record", config: {
                ...props.Record,
                Readonly: props.Record.Readonly || props.Readonly
            }
        });
    }
    if (HasContent(props.SubContent)) {
        res.push({
            type: "table", config: {
                ...props.SubContent,
                Readonly: props.SubContent.Readonly || props.Readonly
            }
        });
    }
    if (HasContent(props.SubContents)) {
        res.push({
            type: "table", config: props.SubContents
                .map(x => ({ ...x, Readonly: x.Readonly || props.Readonly }))
        });
    }
    if (HasContent(props.FootContent)) {
        res.push({
            type: "form", config: {
                ColCount: props.ColCount,
                Content: props.FootContent,
                Readonly: props.Readonly
            }
        });
    }
    return res;
})

const stopWatchs = [];

function onChange(control, data, oldValue) {
    onFieldChange({
        event: "FieldChange",
        control: control,
        data: data,
        oldValue: oldValue,
        params: [control, data]
    });
    emits("change");
}

/** 渲染区域是否可见 */
function RenderAreaVisible(area) {
    return RegisterEvent(getCurrentInstance(), "RenderAreaVisible", x => true, area, model.value);
}

/** 渲染页签是否可见 */
function RenderTabVisible(tab) {
    return RegisterEvent(getCurrentInstance(), "RenderTabVisible", x => true, tab, model.value);
}

onMounted(() => {
});

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>

<style>
.z-edit-panel {
    height: fit-content;
    padding: 3px 5px;
}

.z-edit-panel .t-tabs__nav-item.t-size-m,
.z-edit-panel .t-tabs__btn.t-size-m {
    height: var(--td-comp-size-s);
    line-height: var(--td-comp-size-s);
}

.z-edit-panel .t-tabs__nav-item-text-wrapper {
    height: var(--td-comp-size-s);
}

.z-edit-panel .z-edit-card {
    height: 100%;
    width: 100%;
}

.z-edit-panel .z-edit-card .t-card__body {
    overflow-y: hidden;
}

.z-edit-card+.z-edit-card {
    margin-top: 6px;
}
</style>