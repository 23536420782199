<template>
    <ConfirmPopup id="confirm" ref="crxContent">
        <template #container="{ message }">
            <div class="z-confirm-popup">
                <div class="z-confirm-popup__message">
                    <HelpCircleFilledIcon color="var(--yellow-500)" size="28px" />
                    <div class="z-confirm-popup__message__text" v-html="message.message"></div>
                </div>
                <div class="z-confirm-popup__buttons">
                    <t-space size="8px">
                        <t-button class="z-confirm-popup__button" size="small" theme="default" @click="onCancel">
                            <span v-text="message.rejectLabel"></span>
                        </t-button>
                        <t-button class="z-confirm-popup__button" size="small" @click="onSubmit">
                            <span v-text="message.acceptLabel"></span>
                        </t-button>
                    </t-space>
                </div>
            </div>
        </template>
    </ConfirmPopup>
</template>

<script setup>
import ConfirmPopup from "primevue/confirmpopup";
import { useDialogStore } from "../../stores/useDialogStore";
import { HelpCircleFilledIcon } from "tdesign-icons-vue-next";
import { ref } from "vue";

const store = useDialogStore();
store.usePrimePopupConfirm();

const crxContent = ref();

function onSubmit() {
    crxContent.value.accept()
}

function onCancel() {
    crxContent.value.reject()
}
</script>

<style scoped>
.z-confirm-popup {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.z-confirm-popup__message {
    display: flex;
    flex-wrap: nowrap;
}

.z-confirm-popup__message__text {
    width: 300px;
    margin: 6px 0px 10px 8px;
}


.z-confirm-popup__buttons {
    display: flex;
    justify-content: flex-end;
}

.z-confirm-popup__button {
    zoom: 1.1;
    -ms-zoom: 1.1;
}
</style>