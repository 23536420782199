import { HasContent, IsNullOrEmpty, ParseParameters, RegisterEvent } from "../../../utils/common";
import { OpenDialog, Toast } from "../../../utils/dialog";

/** 使用快速查询
 * @param {Object} param0
 * @param {import("vue").Ref<String>} param0.keyword
 * @param {string} param0.Code
 * @param {boolean} param0.ShowCode
 * @param {boolean} param0.OnlyName
 * @param {string} param0.Url
 * @param {object} param0.Param
 * @param {boolean} param0.IsMult
 * @param {Object} param0.configuration
 * @param {string} param0.configuration.Key
 * @param {string} param0.configuration.Name
 * @param {(value)=> void} param0.SetValue
*/
export function useOpenDialogSelect({ keyword, Code, ShowCode, Url, Param, IsMult, SetValue, configuration }) {
    let path;
    if (Code && typeof Business != "undefined" && Code in Business.DialogCode) {
        path = Business.DialogCode[Code];
    } else if (Url) {
        path = Url;
    } else {
        Toast("未配置弹窗地址", "error");
    }
    function openPopupDialog(sender, props, other) {
        if (IsNullOrEmpty(path)) {
            Toast("未配置弹窗地址", "error");
            return;
        }
        let param;
        if (HasContent(Param))
            param = JSON.parse(ParseParameters(JSON.stringify(Param), other));
        else
            param = {};
        if (ShowCode)
            param[configuration.Key] = keyword.value;
        else
            param[configuration.Name] = keyword.value;
        if (IsMult)
            param.ismult = true;
        RegisterEvent(sender, "OpenPopup", () => {
            OpenDialog("选择弹窗", path, param, SetValue);
        }, props, param);
    }

    return {
        openPopupDialog
    }
}