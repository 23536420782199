<template>
    <component :is="getInputComponent(config.Type)" v-bind="config" v-model="inputValue" :data="model"
               @change="onChange"></component>
</template>

<script setup>
import { computed } from "vue";
import { getInputComponent } from "../form/input.js"
import { setting } from "../../config/appsetting.js";

const props = defineProps({
    /** 配置 */
    config: Object
});

const model = defineModel({ type: Object, default: {} });

const inputValue = computed({
    get() {
        let type = props.config.Type;
        if (type == "popup") {
            return model.value;
        } else if (setting.component.type.dateRange.includes(type)) {
            return [model.value[props.config.BeginField], model.value[props.config.EndField]];
        }
        else {
            return model.value[props.config.Field];
        }
    },
    set(value) {
        let type = props.config.Type;
        if (type == "popup") {
            model.value = value;
        } else if (setting.component.type.dateRange.includes(type)) {
            if (value.length > 0)
                model.value[props.config.BeginField] = value[0];
            else
                delete model.value[props.config.BeginField];
            if (value.length > 1)
                model.value[props.config.EndField] = value[1];
            else
                delete model.value[props.config.EndField];
        } else {
            model.value[props.config.Field] = value;
        }
    }
})

const emits = defineEmits(['change']);

function onChange(value, oldValue) {
    emits('change', props.config, model.value, oldValue);
}
</script>