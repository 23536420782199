import { defineStore } from "pinia";
import { HttpPostAsync } from "../utils/network";

export const useUserStore = defineStore("user", {
    state: () => ({
        userName: ""
    }),
    actions: {
        /**
         * 登录
         * @param {string} account
         * @param {string} passwd
         */
        async login(account, passwd) {
            return await HttpPostAsync("/Login/LoginOn", {
                username: account,
                password: passwd,
                //登录授权模块
                cSubCode: "Z5",
                $ignore: true
            });
        }
    }
});