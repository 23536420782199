<template>
    <menu-bar class="z-footer-bar">
        <template #end>
            <t-space size="small" align="center">
                <div v-for="info in Infos" :key="info.Label" class="z-unselect" v-text="GetInfo(info)"></div>
                <div v-if="network.showStatus" class="z-unselect">
                    <FlagIcon v-if="system.IsCustomConfig" name="flag" :size="14"
                              style="transform: translateY(-2px);" />
                    <span v-if="network.total" v-text="`总耗时:${network.getTimeString(network.total)}`"
                          style="margin-right: 4px;"></span>
                    <span v-if="network.delay" v-text="`延迟:${network.getTimeString(network.delay)}`"></span>
                </div>
                <theme-switch></theme-switch>
                <full-screen></full-screen>
            </t-space>
        </template>
    </menu-bar>
</template>

<script setup>
import menuBar from "primevue/menubar";
import themeSwitch from "./theme-switch.vue";
import fullScreen from "./full-screen.vue";
import { FlagIcon } from "tdesign-icons-vue-next";

import { useSystemStore } from "../../stores/useSystemStore";
import { useNetworkStore } from "../../stores/useNetworkStore";
import { HasContent } from "../../utils/common";

const props = defineProps({
    /** 绑定信息
     * @type {Array<{Label:string,Field:string}>}
     */
    Infos: Array
});
const model = defineModel();

const system = useSystemStore();
const network = useNetworkStore();

function GetInfo(item) {
    let res = item.Label;
    if (HasContent(res))
        res += ":";
    else
        res = "";
    res += model.value[item.Field];
    return res;
}
</script>

<style scoped>
.z-footer-bar {
    padding: 2px 16px;
    font-size: small;
    border: none;
    border-radius: 0;
    background-color: var(--gray-300);
}
</style>