<template>
    <menu-bar class="z-button-bar" v-if="buttons.length > 0">
        <template #start>
            <template v-for="btn in buttons" :key="btn">
                <z-button v-bind="btn" @click="onClick"></z-button>
            </template>
        </template>
    </menu-bar>
</template>

<script setup>
import menuBar from "primevue/menubar";
import ZButton from "../base/button.vue";

const model = defineModel();
const props = defineProps({
    buttons: { type: Array, default: [] }
})
const emits = defineEmits(["click"]);

function onClick() {
    emits("click", ...arguments);
}
</script>

<style scoped>
.z-button-bar {
    padding-left: 0.4em;
    padding: 1.5px;
    border: none;
    box-shadow: var(--z-box-shadow);
    border-radius: 0;
}
</style>