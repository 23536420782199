import { definePreset } from "primevue/themes";
import Aura from "primevue/themes/primeone/aura";

export const defaultTheme = definePreset(Aura, {
    primitive: {
        rosered: {
            50: '#fcf5f9',
            100: '#f5d8e5',
            200: '#edbad2',
            300: '#d25590',
            400: '#c72b74',
            500: '#bc0058',
            600: '#a9004f',
            700: '#960046',
            800: '#84003e',
            900: '#710035',
            950: '#5e002c'
        }
    },
    semantic: {
        primary: {
            50: '{rosered.50}',
            100: '{rosered.100}',
            200: '{rosered.200}',
            300: '{rosered.300}',
            400: '{rosered.400}',
            500: '{rosered.500}',
            600: '{rosered.600}',
            700: '{rosered.700}',
            800: '{rosered.800}',
            900: '{rosered.900}',
            950: '{rosered.950}'
        }
    }
});