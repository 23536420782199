<template>
    <auto-height scroll>
        <template v-if="options && options.length > 0" #header>
            <t-input class="z-tree-search" v-model="searchWord" clearable placeholder="快速搜索" @change="onSearch">
                <template #suffix-icon>
                    <SearchIcon class="z-tree-search__icon"></SearchIcon>
                </template>
            </t-input>
        </template>
        <template #default>
            <t-tree class="z-tree" ref="crxTree" v-bind="treeOption" :data="options" :checkable="IsMult"
                    :filter="fnSearch" @active="onActive" @change="onChange">
                <template #label="{ node }">
                    <high-light :value="node.label" :keyword="searchWord"></high-light>
                </template>
            </t-tree>
        </template>
    </auto-height>
</template>

<script>
import { ref } from "vue";
import { useEnumComponent } from "../../hooks/useEnumComponent";
import { SearchIcon } from 'tdesign-icons-vue-next';
import autoHeight from "./auto-height.vue";
import { debounce } from "lodash";
import { IsNullOrEmpty, SearchWordPosition } from "../../utils/common";
import highLight from "./high-light.vue";

export default useEnumComponent(({ props, onChange }) => {
    /** 树基础配置属性 */
    const treeOption = {
        size: "small",
        empty: "暂无数据",
        icon: true,
        activable: true,
        hover: true,
        expandOnClickNode: true,
        line: true,
        scroll: {
            // type: "virtual",
            isFixedRowHeight: true,
            threshold: 50,
            bufferSize: 20,
            rowHeight: 34
        }
    };
    /** 搜索关键字 */
    const searchWord = ref("");
    /** 搜索函数 */
    const fnSearch = ref(null);

    /** 搜索 */
    const onSearch = debounce((value) => {
        if (IsNullOrEmpty(value))
            fnSearch.value = null;
        else
            fnSearch.value = ({ label }) => {
                return SearchWordPosition(label, value) >= 0;
            };
    }, 200);

    /** 节点点击，多选走check事件 */
    function onActive(value) {
        if (props.IsMult)
            return;
        onChange(value);
    }

    return {
        searchWord,
        fnSearch,
        treeOption,
        onSearch,
        onActive
    }
}, {}, {
    highLight,
    autoHeight,
    SearchIcon
});
</script>

<style scoped>
.z-tree {
    height: 100%;
}

.z-tree :deep(.t-tree__empty) {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: fit-content;
    font-weight: bolder;
    font-size: 18px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
</style>