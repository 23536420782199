<template>
    <auto-height>
        <template #default>
            <div class="z-sidebar">
                <menu-tree></menu-tree>
                <auto-height style="width: 100%;">
                    <template #header>
                        <menu-bar v-bind="$props" v-model="model"></menu-bar>
                    </template>
                    <template #default>
                        <z-frames ref="crxFrames"></z-frames>
                    </template>
                </auto-height>
            </div>
        </template>
        <template #footer>
            <footerBar v-bind="Footer" v-model="model"></footerBar>
        </template>
    </auto-height>
</template>

<script setup>
import autoHeight from '../src/components/base/auto-height.vue';
import footerBar from '../src/components/custom/footer-bar.vue';
import menuTree from "../src/components/custom/menu/menu-tree.vue";
import menuBar from "../src/components/custom/menu/menu-bar.vue";
import zFrames from "../src/components/base/frames.vue";
import { useMenuScore } from '../src/stores/useMenuStore';
import { ZMenuItem } from '../src/models/ZMenuItem';
import { getCurrentInstance, onBeforeUnmount, onMounted, ref } from 'vue';
import { useAppStore } from '../src/stores/useAppStore';
import { GetAccountSet } from '../src/utils/business';
import { CreateURL } from '../src/utils/network';

const props = defineProps({
    /** 待办任务 */
    Task: Object,
    /** 消息 */
    Message: Object,
    /** 用户信息 */
    User: Object,
    /** 尾部配置信息 */
    Footer: Object
});
const model = defineModel();

const menuStore = useMenuScore();
/**
 * @type {import("vue").Ref<ZFrames>}
 */
const crxFrames = ref(null);
/**
 * 打开菜单
 * @param {ZMenuItem[]} menus
 */
function openMenu(menus) {
    menus.forEach(menu => crxFrames.value.open(menu.title, CreateURL(menu.href, {
        menuid: menu.id == "home" ? "" : menu.id,
        accid: GetAccountSet()
    }), menuStore.multipleMode != true));
}

/**
 * 关闭菜单
 * @param {ZMenuItem[]} menus
 */
function closeMenu(menus) {
    menus.forEach(menu => crxFrames.value.close(CreateURL(menu.href, {
        menuid: menu.id == "home" ? "" : menu.id,
        accid: GetAccountSet()
    })));
}

onMounted(() => {
    useAppStore().__bindInstance(getCurrentInstance());
    menuStore.addEventListener("open", openMenu);
    menuStore.addEventListener("close", closeMenu);
});

onBeforeUnmount(() => {
    menuStore.removeEventListener("open", openMenu);
    menuStore.removeEventListener("close", closeMenu);
});
</script>

<style scoped>
.z-sidebar {
    height: 100%;
    display: flex;
}
</style>