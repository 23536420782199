<template>
    <span v-if="columnIndex == 0">
        <span v-if="!isAllCheck && count > 0" class="z-select z-light" v-text="`(${count})`"></span>
        <span class="z-total">合计</span>
    </span>
    <span v-else>
        <span v-if="select.sum > 0" class="z-select z-light" v-text="`已选(${select.text})`"
              @click="CopyToClipboard(select.sum)"></span>
        <span :class="isAllCheck ? 'z-total z-light' : 'z-total'" v-text="total.text"
              @click="CopyToClipboard(total.sum)"></span>
    </span>
</template>

<script setup>
import { onMounted, onUpdated, reactive, ref } from "vue";
import { CopyToClipboard, HasContent, MoneyFormat } from "../../../utils/common";
import { debounce, max, round, toNumber } from "lodash";

/**
 * @description: 表格合计
 * @type {{
 *  grid: import("vxe-table").VxeGridInstance,
 *  items: any[],
 *  column: import("vxe-table").VxeColumnProps,
 *  columnIndex: number
 * }}
 */
const props = defineProps({
    grid: Object,
    items: Array,
    column: Object,
    columnIndex: { type: Number, default: null }
});

const isAllCheck = ref(false);
const count = ref(0);
const total = reactive({ sum: 0, text: "" });
const select = reactive({ sum: 0, text: "" });

const calculateSum = debounce(() => {
    if (props.column.type == "checkbox" || props.column.type == "seq") {
        if (props.columnIndex == 0) {
            count.value = props.grid.getCheckboxRecords().length;
            isAllCheck.value = count.value == props.grid.getTableData().visibleData.length;
        }
    }
    else {
        let conf = props.column.params;
        if (conf.IsSummary) {
            let rows = props.grid.getTableData().visibleData,
                records = props.grid.getCheckboxRecords(),
                add = (x, v) => {
                    //确保JavaScript在计算时，精度不丢失 0.1 + 0.2 = 0.30000000000000004
                    let strX = String(x),
                        strV = String(v),
                        indexX = strX.indexOf("."),
                        indexV = strV.indexOf("."),
                        iDot = max([indexX > -1 ? strX.length - indexX : 0, indexV > -1 ? strV.length - indexV : 0]),
                        pow = Math.pow(10, iDot);
                    return round(x * pow + v * pow, 0) / pow;
                },
                fn = (arr, item) => {
                    if (arr && arr.length > 0) {
                        item.sum = arr.map(row => toNumber(row[props.column.field] || null)).reduce((x, v) => add(x, v));
                        if (conf.Type == "money") {
                            item.sum = round(item.sum, 2);
                            item.text = MoneyFormat(item.sum);
                        } else {
                            if (HasContent(conf.Precision))
                                item.sum = round(item.sum, conf.Precision);
                            item.text = String(item.sum);
                        }
                    } else {
                        item.sum = 0;
                        item.text = "0";
                    }
                };
            fn(rows, total);
            //全选不显示两行
            isAllCheck.value = records.length == rows.length;
            if (isAllCheck.value) {
                select.sum = 0;
                select.text = "";
            } else {
                fn(records, select);
            }
        }
    }
}, 200);

onUpdated(() => {
    calculateSum();
});

onMounted(() => {
    calculateSum();
});

</script>

<style scoped>
.z-select,
.z-total {
    display: block;
    word-break: keep-all;
}

.z-select:hover,
.z-total:hover {
    text-decoration-line: underline;
    color: var(--td-brand-color);
    cursor: pointer;
}

.z-light {
    color: var(--td-brand-color);
}
</style>