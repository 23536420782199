<template>
    <cell-view v-if="readonly" v-bind="$props"></cell-view>
    <z-input v-else :config="column.params" v-model="model" @change="onChange"></z-input>
</template>

<script setup>
import { computed, getCurrentInstance, nextTick, onMounted, ref } from "vue";
import { TableColumnReadonly } from "./action";
import cellView from "./view.vue";
import zInput from "../../base/input.vue";
import { bindDefault, onFieldChange } from "../../../hooks/useInputComponent";

/**
 * @type {{
 *    table: import("vxe-table").VxeTableInstance,
 *    column: import("vxe-table").VxeColumnProps,
 *    columnIndex: Number,
 *    row: import("vxe-table").VxeTableDataRow,
 *    rowIndex: Number,
 *    isHidden: Boolean,
 *    hasFilter: Boolean,
 *    seq: Number
 * }}
 */
const props = defineProps({
    table: Object,
    column: Object,
    columnIndex: { type: Number, default: null },
    row: Object,
    rowIndex: { type: Number, default: null },
    isHidden: { type: Boolean, default: null },
    hasFilter: { type: Boolean, default: null },
    seq: { type: Number, default: null }
});

const model = computed({
    get() {
        return props.row;
    },
    set(value) {
        Object.assign(props.row, value);
    }
})

const instance = ref();

const readonly = computed(() => {
    if (props.column.params.Type == "template") {
        return true;
    }
    let res = TableColumnReadonly(props.table.props.params, props.column.params, props.row) === true;
    nextTick(() => {
        if (instance.value) {
            if (res)
                instance.value.vnode.el.parentElement.parentElement.classList.remove("z-table-edit-cell");
            else
                instance.value.vnode.el.parentElement.parentElement.classList.add("z-table-edit-cell");
        }
    });
    return res;
});

function onChange(control, data, oldValue) {
    onFieldChange({
        event: "TableColumnChange",
        control: control,
        data: data,
        oldValue: oldValue,
        params: [props.table.props.params, control, data]
    });
}

onMounted(() => {
    instance.value = getCurrentInstance();
    if (TableColumnReadonly(props.table.props.params, props.column.params, props.row) === false) {
        bindDefault([props.column.params], props.row);
    }
});
</script>

<style scoped>
:deep(.t-input) {
    padding: 0;
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    width: calc(100% - 1px);
    height: calc(100% - 2px);
    background: transparent;
}

:deep(.t-auto-complete) {
    width: 0;
    flex-grow: 1;
}

:deep(.t-input.t-input--focused) {
    box-shadow: none;
}

:deep(.t-input .t-input__suffix:not(:empty)){
    margin-left: var(--td-comp-margin-xs);
}

:deep(.t-input-adornment>:last-child),
:deep(.t-input-adornment>:first-child),
:deep(.t-input-adornment>.t-button) {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

:deep(.t-cascader) {
    transform: translateY(-2px) !important;
}
</style>