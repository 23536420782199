<template>
    <div class="nao-pages-line">
        <auto-height scroll>
            <template #header>
                <button-bar :buttons="buttons" @click="onClick"></button-bar>
                <z-form v-if="Content" :ColCount="ColCount" :Content="Content" v-model="model"
                    :Readonly="renderPageReadonly()" @change="onChange"></z-form>
            </template>
            <template #default>
                <splitter style="height: 100%;border:none;">
                    <splitter-panel :size="60" v-if="Line">
                        <auto-height>
                            <template #default="{ height }">
                                <z-table ref="crxLine" v-bind="Line" v-model="model" :Height="height"
                                    :Readonly="renderPageReadonly()"></z-table>
                            </template>
                        </auto-height>
                    </splitter-panel>
                    <splitter-panel :size="40">
                        <div style="overflow-y:auto;">
                            <div class="z-line-group">
                                <span v-html="toFn(GroupRender)()"></span>
                            </div>
                            <div class="z-line-move">
                                <div v-for="item in Imports" :key="item">
                                    <z-form v-if="item.Content && model[item.ContentField]" :ColCount="item.ColCount"
                                        :Content="item.Content" v-model="model[item.ContentField]"
                                        :style="`max-width:${item.Width}`" :Readonly="renderPageReadonly()"
                                        @change="onChange"></z-form>
                                    <p v-html="toFn(item.TitleRender)(item)"></p>
                                </div>
                            </div>
                            <div class="z-line-import" v-if="renderPageReadonly() != true">
                                <div v-for="item in Imports" :key="item" class="z-line-input">
                                    <p>&nbsp;</p>
                                    <t-textarea v-model="model[item.Field]"
                                        :autosize="{ minRows: 10, maxRows: 10 }"></t-textarea>
                                    <div class="z-line-button">
                                        <template v-for="btn in item.Buttons" :key="btn">
                                            <t-button type="primary" @click="toFn(btn)(btn)">
                                                <span v-text="btn.Label"></span>
                                            </t-button>
                                        </template>

                                    </div>
                                </div>
                                <div v-for="item in Imports" :key="item" class="z-line-demo">
                                    <p v-text="item.Demo.Title"></p>
                                    <t-textarea :value="item.Demo.Value" autosize readonly></t-textarea>
                                    <p v-text="item.Demo.ResultTitle"></p>
                                    <p v-text="item.Demo.ResultValue"></p>
                                </div>
                            </div>
                        </div>
                    </splitter-panel>
                </splitter>
            </template>
        </auto-height>
    </div>
</template>

<script setup>
import { getCurrentInstance, computed } from 'vue';
import autoHeight from '../src/components/base/auto-height.vue';
import buttonBar from '../src/components/base/button-bar.vue';
import zForm from "../src/components/base/form.vue";
import zTable from "../src/components/base/table.vue";

import splitter from "primevue/splitter";
import splitterPanel from "primevue/splitterpanel";

import { RegisterEvent, toFn } from "../src/utils/common";
import { useAppStore } from '../src/stores/useAppStore';
import { isArray } from 'lodash';
import { onFieldChange } from '../src/hooks/useInputComponent';

const props = defineProps({
    ColCount: Number,
    Content: Array,
    Line: Object,
    Tabar: Object,
    Imports: Array,
    RemarkRender: String,
    GroupRender: String
});

const model = defineModel();

function onClick(btn) {
    toFn(btn)();
}

function renderPageReadonly() {
    return RegisterEvent(getCurrentInstance(), "RenderPageReadonly", () => false);
}

const buttons = computed(() => {
    let res = [];
    if (props.Tabar && props.Tabar.Buttons)
        res.push(...props.Tabar.Buttons.filter(renderBtnVisible));
    return res;
});

function renderBtnVisible(btn) {
    return btn.Visible !== false && RegisterEvent(getCurrentInstance(), "RenderBtnVisible", () => {
        if (isArray(btn.VisibleCode))
            return btn.VisibleCode.includes(useAppStore().pagetype);
        return true;
    }, btn, model.value);
}

function onChange(control, data, oldValue) {
    onFieldChange({
        event: "FieldChange",
        control: control,
        data: data,
        oldValue: oldValue,
        params: [control, data]
    });
}
</script>

<style scoped>
.nao-pages-line {
    height: 100%;
}


.z-line {
    display: flex;
    max-width: 100%;
}

.z-table-part {
    flex-grow: 1;
    flex-shrink: 1;
}

.z-line-part {
    width: 400px;
    flex-shrink: 0;
}

.z-line-group,
.z-line-move,
.z-line-input,
.z-line-demo {
    padding: 4px;
    box-sizing: border-box;
}

.z-line-import {
    display: flex;
    flex-wrap: wrap;
}

.z-line-button {
    display: flex;
    justify-content: space-evenly;
    padding-top: 4px;
}

.z-line-input,
.z-line-demo {
    width: 50%;
}

.z-line-remark,
.z-line-move {
    font-size: smaller;
}

.z-line-group {
    display: flex;
}

.z-line-move {
    display: flex;
}

.z-line-move>div {
    flex: 1;
    max-width: 100%;
}
</style>