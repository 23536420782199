import { defineStore } from "pinia";

export const useContextMenuStore = defineStore("contextmenu", {
    state: () => ({
        /**
         * 菜单事件
         * @type {Event}
         */
        event: null,
        /**
         * 菜单数据
         * @type {import("primevue/menuitem").MenuItem[]}
         */
        menus: []
    }),
    actions: {
        /**
         * 打开右键菜单
         * @param {Event} e
         * @param {import("primevue/menuitem").MenuItem[]} value
         */
        open(e, value) {
            this.event = e;
            this.menus = value;
            // 阻止默认事件
            e.preventDefault();
        },
        /**
         * 关闭右键菜单
         */
        hide() {
            this.event = null;
        }
    }
});