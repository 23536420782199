<template>
    <div class="z-page-help">
        <t-divider>
            <h3>帮助文档</h3>
        </t-divider>
        <div class="content">
            <template v-if="state < 2">
                <t-loading size="small" loading text="正在获取文档说明……"></t-loading>
            </template>
            <template v-else-if="state == 2" >
                <div v-for="btn in btns" :key="btn.BtnName">
                    <h4 v-text="btn.BtnName"></h4>
                    <template v-if="btn.UserRemark">
                        <h5>功能说明：</h5>
                        <p v-text="btn.UserRemark"></p>
                    </template>
                    <template v-if="btn.UserCase">
                        <h5>使用场景：</h5>
                        <p v-text="btn.UserCase"></p>
                    </template>
                    <template v-if="btn.UseQuestion">
                        <h5>常见问题：</h5>
                        <p v-text="btn.UseQuestion"></p>
                    </template>
                </div>
            </template>
            <t-message v-else-if="state == 3" class="t-message__text" theme="warning" content="当前页面无文档说明"></t-message>
            <t-message v-else-if="state == 4" class="t-message__text" theme="error" content="获取文档说明失败"></t-message>
        </div>
        <t-divider align="right">
            <div class="z-page-icon">
                <t-link theme="primary" @click="OpenHandBookPage">
                    <LinkIcon />
                    <span>操作手册</span>
                </t-link>
                <span>&emsp;&emsp;</span>
                <t-link theme="primary" @click="OpenBugPage">
                    <ServiceIcon />
                    <span>我要反馈</span>
                </t-link>
            </div>

        </t-divider>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { GetFromMenu } from '../../utils/business';
import { OpenPage } from '../../utils/dialog';
import { HttpPost } from '../../utils/network';
import { LinkIcon, ServiceIcon } from 'tdesign-icons-vue-next'


/**
 * 问题页面地址
 */
const QuestionPageURL = "/Genesis/QueReqManage/Question/CusEorA";
/**
 * 获取页面帮助信息
 */
const PageHelpInfoURL = "/Genesis/Permissions/Menu/GetNewPageHelp";


const OperatePageURL = ref();
/** 状态 */
const state = ref(0);
/** 按钮 */
const btns = ref([]);

function OpenBugPage() {
    OpenPage("问题反馈", QuestionPageURL, {
        from: GetFromMenu()
    }, {
        FromRight: true
    });
    document.querySelector("div.t-sticky-tool > div.t-sticky-item > svg.t-icon-help").parentElement.click();
}

function OpenHandBookPage() {
    OpenPage("操作手册", OperatePageURL.value, {
        from: GetFromMenu()
    }, {
        FromRight: true
    });
    document.querySelector("div.t-sticky-tool > div.t-sticky-item > svg.t-icon-help").parentElement.click();
}


function LoadPageHelp() {
    state.value = 1;
    HttpPost(PageHelpInfoURL, {
        from: GetFromMenu()
    }, res => {
        btns.value = res.data.Buttons;
        state.value = res.data.Buttons.length > 0 ? 2 : 3;
        OperatePageURL.value = res.data.Help;
    }, err => {
        state.value = 4;
    });
}

onMounted(() => {
    LoadPageHelp();
});

</script>


<style scoped>
.z-page-help {
    width: 50vw;
}

.t-message__text {
    width: 100%;
    justify-content: center;
    box-shadow: none;
}

.z-page-icon {
    display: block;
}

</style>