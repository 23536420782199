<template>
  <div class="nao-pages-line">
    <auto-height scroll>
      <template #header>
        <button-bar :buttons="buttons" @click="onClick"></button-bar>
        <z-form v-if="Content" :ColCount="ColCount" :Content="Content" v-model="model" :Readonly="renderPageReadonly()" @change="onChange"></z-form>
      </template>
      <template #default>
        <splitter style="height: 100%; border: none">
          <splitter-panel :size="60" v-if="Line">
            <auto-height>
              <template #default="{ height }">
                <vxe-list :height="height" class="nao-group" :data="model[Line.Field]" :scroll-y="{ enabled: true }">
                  <template #default="{ items }">
                    <table class="nao-group-table">
                      <tbody>
                        <template v-for="item in items" :key="item">
                          <tr>
                            <td>组别</td>
                            <td style="padding-left: 4px" :colspan="model.GameType == 5 ? 5 : 1">{{ item.GroupName }}</td>
                            <template v-if="model.GameType == 1 && model.NeedDeadLine && model.DeadLineTime">
                              <td>线路</td>
                              <td class="nao-group-score" align="right">
                                <t-input-number
                                  class="z-input-number"
                                  :decimalPlaces="0"
                                  :autoWidth="false"
                                  size="small"
                                  :max="20"
                                  :allowInputOverLimit="false"
                                  theme="normal"
                                  suffix="条"
                                  v-model="item.LineCount"
                                  @blur="onLineChange(item)"
                                  clearable></t-input-number>
                              </td>
                            </template>
                            <td>有效时间</td>
                            <td class="nao-group-score" align="right">
                              <t-input-number class="z-input-number" :decimalPlaces="0" :autoWidth="false" size="small" :max="99999" :allowInputOverLimit="false" theme="normal" suffix="分钟" v-model="item.ScoreValidityTime" clearable></t-input-number>
                            </td>
                            <template v-if="model.GameType == 3 || model.GameType == 4">
                              <td>规定时间</td>
                              <td class="nao-group-score" align="right">
                                <t-input-number class="z-input-number" :decimalPlaces="0" :autoWidth="false" size="small" :max="99999" :allowInputOverLimit="false" theme="normal" suffix="分钟" v-model="item.ScoreExpiryTime" clearable></t-input-number>
                              </td>
                              <td>超时扣分</td>
                              <td class="nao-group-score" align="right">
                                <t-input-number
                                  class="z-input-number"
                                  :decimalPlaces="0"
                                  :autoWidth="false"
                                  size="small"
                                  :max="99999"
                                  :allowInputOverLimit="false"
                                  theme="normal"
                                  suffix="分/分钟"
                                  v-model="item.ExceededDeductionScore"
                                  :min="1"
                                  clearable></t-input-number>
                              </td>
                            </template>
                          </tr>
                          <tr v-if="model.GameType == 4">
                            <td>特殊规则</td>
                            <td colspan="7" style="padding-left: 4px">
                              <t-radio-group v-model="item.EffectiveType">
                                <t-radio value="1">
                                  <t-input-number
                                    class="z-input-number z-input-rule-number"
                                    :decimalPlaces="0"
                                    :autoWidth="false"
                                    size="small"
                                    :max="99"
                                    :allowInputOverLimit="false"
                                    theme="normal"
                                    label="按规定线路打卡，线路积分 X"
                                    suffix="倍"
                                    v-model="item.EffectiveMultiple"
                                    :min="1"
                                    placeholder=""
                                    clearable></t-input-number>
                                </t-radio>
                                <t-radio value="2">未按规定线路打卡不得分</t-radio>
                              </t-radio-group>
                            </td>
                          </tr>
                          <!-- 计时赛、接力赛 -->
                          <template v-if="model.GameType == 1 || model.GameType == 6">
                            <template v-for="subLine in item.Lines" :key="subLine">
                              <tr :class="{ 'nao-group-line': true, 'nao-group-item__active': current == subLine }" @click="onChangeGroup(item, subLine)">
                                <td style="white-space: nowrap">{{ subLine.LineName }}</td>
                                <td colspan="7" v-html="LineRender(subLine)"></td>
                              </tr>
                            </template>
                          </template>
                          <!-- 积分赛 -->
                          <template v-else-if="model.GameType == 3">
                            <tr :class="{ 'nao-group-line': true, 'nao-group-item__active': current == item.Lines[0] }" @click="onChangeGroup(item, item.Lines[0])">
                              <td>检查点</td>
                              <td colspan="6" v-html="PointAndCodeRender(item.Lines[0])"></td>
                              <td>
                                <t-button type="primary" size="small" @click="SetTime(item.Lines[0])">
                                  <span v-if="renderPageReadonly() != true">设置时间窗口</span>
                                  <span v-else>查看时间窗口</span>
                                </t-button>
                              </td>
                            </tr>
                          </template>
                          <!-- 体验赛 -->
                          <template v-else-if="model.GameType == 4">
                            <tr :class="{ 'nao-group-line': true, 'nao-group-item__active': current == item.Lines[0] }" @click="onChangeGroup(item, item.Lines[0])">
                              <td>检查点</td>
                              <td colspan="3" v-html="PointAndCodeRender(item.Lines[0])"></td>
                              <td>线路</td>
                              <td colspan="3" v-html="LineRender(item.Lines[0])"></td>
                            </tr>
                          </template>
                          <!-- 团队赛 -->
                          <template v-else-if="model.GameType == 5">
                            <tr :class="{ 'nao-group-line': true, 'nao-group-item__active': current == item.Lines[0] }" @click="onChangeGroup(item, item.Lines[0])">
                              <td>自由点</td>
                              <td colspan="3" v-html="PointRender(item.Lines[0])"></td>
                              <td>必打线路</td>
                              <td colspan="3" v-html="LineRender(item.Lines[0])"></td>
                            </tr>
                          </template>
                        </template>
                      </tbody>
                    </table>
                  </template>
                </vxe-list>
              </template>
            </auto-height>
          </splitter-panel>
          <splitter-panel :size="40" v-if="renderPageReadonly() != true">
            <div style="overflow-y: auto">
              <div class="z-line-import">
                <div v-for="item in Imports" :key="item" class="z-line-input">
                  <p>&nbsp;</p>
                  <t-textarea v-model="model[item.Field]" :autosize="{ minRows: 12, maxRows: 12 }"></t-textarea>
                  <div class="z-line-button">
                    <template v-for="btn in item.Buttons" :key="btn">
                      <t-button type="primary" @click="toFn(btn)(btn)">
                        <span v-text="btn.Label"></span>
                      </t-button>
                    </template>
                  </div>
                </div>
                <div v-for="item in Imports" :key="item" class="z-line-demo">
                  <p v-text="item.Demo.Title"></p>
                  <t-textarea :value="item.Demo.Value" autosize readonly></t-textarea>
                  <p v-text="item.Demo.ResultTitle"></p>
                  <p v-text="item.Demo.ResultValue"></p>
                </div>
              </div>
            </div>
          </splitter-panel>
        </splitter>
      </template>
    </auto-height>
  </div>
</template>

<script setup>
import { getCurrentInstance, computed, ref, onMounted } from "vue";
import autoHeight from "../src/components/base/auto-height.vue";
import buttonBar from "../src/components/base/button-bar.vue";
import zForm from "../src/components/base/form.vue";

import splitter from "primevue/splitter";
import splitterPanel from "primevue/splitterpanel";

import { RegisterEvent, toFn } from "../src/utils/common";
import { useAppStore } from "../src/stores/useAppStore";
import { isArray } from "lodash";
import { onFieldChange } from "../src/hooks/useInputComponent";
import { Confirm, OpenPage, Toast } from "../src/utils/dialog";

const props = defineProps({
  ColCount: Number,
  Content: Array,
  Line: Object,
  Tabar: Object,
  Imports: Array,
  RemarkRender: String,
  GroupRender: String,
});

const model = defineModel();

/** 当前选中行 */
const current = ref();

function onClick(btn) {
  toFn(btn)();
}

function renderPageReadonly() {
  return RegisterEvent(getCurrentInstance(), "RenderPageReadonly", () => false);
}

const buttons = computed(() => {
  let res = [];
  if (props.Tabar && props.Tabar.Buttons) res.push(...props.Tabar.Buttons.filter(renderBtnVisible));
  return res;
});

function renderBtnVisible(btn) {
  return (
    btn.Visible !== false &&
    RegisterEvent(
      getCurrentInstance(),
      "RenderBtnVisible",
      () => {
        if (isArray(btn.VisibleCode)) return btn.VisibleCode.includes(useAppStore().pagetype);
        return true;
      },
      btn,
      model.value
    )
  );
}

function onChange(control, data, oldValue) {
  onFieldChange({
    event: "FieldChange",
    control: control,
    data: data,
    oldValue: oldValue,
    params: [control, data],
  });
}

function onChangeGroup(group, line) {
  current.value = line;
  toFn("OnAfterGroupLineChange")(group, line);
}

onMounted(() => {
  if (model.value && model.value.GameLine && model.value.GameLine.length > 0) {
    onChangeGroup(model.value.GameLine[0], model.value.GameLine[0].Lines[0]);
  }
});

function LineRender(row) {
  var res = "";
  if (row && row.Line) {
    var temp = model.value.TempCodes;
    if (temp.length == 0) res += row.Line.join("➤");
    else res += row.Line.map((x) => (temp.includes(String(x)) ? `<span class='__strong'>${x}</span>` : x)).join("➤");
  }
  return res;
}

function PointAndCodeRender(row) {
  var res = "";
  if (row && row.Point) {
    var temp = model.value.TempCodes;
    if (temp.length == 0) res += row.Point.map((value, index) => (row.Score && row.Score[index] ? `${value}(${row.Score[index]})` : value)).join("；");
    else
      res += row.Point.map((value, index) => {
        var res = value;
        if (row.Score && row.Score[index]) res += `(${row.Score[index]})`;
        if (temp.includes(String(value))) res = `<span class='__strong'>${res}</span>`;
        return res;
      }).join("；");
  }
  return res;
}

function PointRender(row) {
  var res = "";
  if (row && row.Point) {
    var temp = model.value.TempCodes;
    if (temp.length == 0) res += row.Point.join("；");
    else res += row.Point.map((x) => (temp.includes(String(x)) ? `<span class='__strong'>${x}</span>` : x)).join("；");
  }
  return res;
}

function onLineChange(row) {
  if (row.LineCount < 1) {
    Toast("线路至少需要一条", "error");
    row.LineCount = row.Lines.length;
  } else if (row.LineCount > 20) {
    Toast("线路最多支持20条", "error");
    row.LineCount = row.Lines.length;
  } else if (row.LineCount > row.Lines.length) {
    Confirm(`即将增加${row.LineCount - row.Lines.length}条线路，是否继续？`, (e) => {
      if (e.confirm) {
        row.Lines[0].LineName = "A";
        for (let i = row.Lines.length; i < row.LineCount; i++) {
          row.Lines.push({
            LineName: String.fromCharCode(65 + i),
            Line: [],
          });
        }
      }
    });
  } else if (row.LineCount < row.Lines.length) {
    Confirm(`即将删除最后${row.Lines.length - row.LineCount}条线路，是否继续？`, (e) => {
      row.Lines.splice(row.LineCount, row.Lines.length - row.LineCount);
    });
  }
}

function bindEmpty(arr) {
  if (arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === undefined || arr[i] === null) arr[i] = "";
    }
  }
}

function SetTime(row) {
  console.log(row);
  if (row.Point && row.Point.length > 0) {
    bindEmpty(row.StartTime);
    bindEmpty(row.EndTime);
    bindEmpty(row.Score);
    OpenPage("设置时间窗口", "/Game/ViewGameCodeTime", {
      view: renderPageReadonly(),
      $Post: row,
    });
  } else {
    Toast("请先设置检查点！", "error");
    return;
  }
}
</script>

<style scoped>
.nao-pages-line {
  height: 100%;
}

.z-line {
  display: flex;
  max-width: 100%;
}

.z-table-part {
  flex-grow: 1;
  flex-shrink: 1;
}

.z-line-part {
  width: 400px;
  flex-shrink: 0;
}

.z-line-group,
.z-line-move,
.z-line-input,
.z-line-demo {
  padding: 4px;
  box-sizing: border-box;
}

.z-line-import {
  display: flex;
  flex-wrap: wrap;
}

.z-line-button {
  display: flex;
  justify-content: space-evenly;
  padding-top: 4px;
}

.z-line-input,
.z-line-demo {
  width: 50%;
}

.z-line-remark,
.z-line-move {
  font-size: smaller;
}

.z-line-group {
  display: flex;
}

.z-line-move {
  display: flex;
}

.z-line-move > div {
  flex: 1;
  max-width: 100%;
}

.nao-group {
  padding: 4px;
}

.nao-group :deep(.t-descriptions__body.t-size-m > tbody > tr > td) {
  padding: var(--td-comp-paddingLR-xxs) var(--td-comp-paddingLR-xxs);
}

.nao-group :deep(.t-descriptions__body .t-descriptions__label) {
  text-align: center;
  color: var(--surface-900);
}

.nao-group :deep(.t-descriptions + .t-descriptions) {
  margin-top: 4px;
}

.z-input-number {
  width: 95px;
  border: none;
}

.z-input-number :deep(.t-input) {
  border: none;
  padding: 0 var(--td-comp-paddingLR-xxs);
}

.z-input-number :deep(.t-input.t-input--focused) {
  box-shadow: none;
}

.z-input-number :deep(.t-input .t-input__suffix:not(:empty)) {
  margin-left: var(--td-comp-margin-xxs);
}

.z-input-rule-number {
  width: 230px;
}

.z-input-number :deep(.t-input__inner),
.z-input-rule-number :deep(.t-input__inner) {
  text-align: center;
  font-size: 14px;
}

.z-input-rule-number :deep(.t-input__inner) {
  color: var(--color-white);
  background-color: var(--primary-color);
  border-radius: 4px;
}

.z-input-rule-number :deep(.t-input .t-input__prefix:not(:empty)) {
  margin-right: var(--td-comp-margin-xxs);
}
</style>

<style scoped>
:deep(.p-component) {
  font-size: inherit;
}

.nao-group-table {
  border-collapse: collapse;
  width: 100%;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.nao-group-table td {
  border: 1px solid var(--td-border-level-2-color);
}

.nao-group-table tr td:nth-child(2n + 1) {
  text-align: center;
  width: 7em;
}

.nao-group-score {
  width: 100px;
}

.nao-group-line {
  cursor: pointer;
}

.nao-group-line td {
  padding: 6px 4px;
}

.nao-group-item__active {
  background-color: var(--primary-color);
  color: var(--color-white);
}
</style>
