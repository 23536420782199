<template>
    <div class="z-camera z-unselect">
        <div class="z-camera__content" :id="id"></div>
        <div class="z-camera__tabar">
            <template v-if="needInstallPlugin">
                <t-button size="small" theme="info" style="margin-left: 12px;"
                          @click="onInstallPlugin">点击安装插件</t-button>
                <div class="z-camera__tabar-right">
                    <RefreshIcon size="28px" color="#fff" @click="onRefresh" />
                </div>
            </template>
            <template v-else>
                <component :is="isPlay ? StopIcon : PlayIcon" size="34px" color="#fff" @click="onTogglePlay" />
                <div class="z-camera__tabar-right">
                    <t-select v-if="Cameras.length > 1" size="small" v-model="currentCamera" auto-width
                              @change="onChangeCamera">
                        <template #prefixIcon>
                            <VideoCamera1Icon color="#fff" />
                        </template>
                        <t-option v-for="(item, index) in Cameras" :key="item.Label" :value="index"
                                  :label="item.Label" />
                    </t-select>
                    <template v-if="isPlay">
                        <CameraIcon v-if="AllowCamera !== false" size="28px" color="#fff" @click="onTakePicture" />
                        <component v-if="AllowVideo !== false" size="28px" color="#fff" @click="onToggleRecord"
                                   :is="isRecord ? VideoCameraOffIcon : VideoCamera1Icon" />
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import { debounce, uniqueId } from "lodash";
import { nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import { WebVideoCtrl } from "../../models/WebVideoCtrl";
import { Alert, Toast } from "../../utils/dialog";
import { HttpPost, HttpUpload } from "../../utils/network";
import dayjs from "dayjs";
import { CopyToClipboard, RegisterEvent, toFn } from "../../utils/common";
import { ViteSetting } from "../../config/vitesetting";
import { useAppStore } from "../../stores/useAppStore";
import { getCurrentInstance } from "vue";
import { CameraIcon, PlayIcon, RefreshIcon, StopIcon, VideoCamera1Icon, VideoCameraOffIcon } from "tdesign-icons-vue-next";

/** 视频控件
 * @type {WebVideoCtrl}
 */
const manager = window.WebVideoCtrl;

const props = defineProps({
    /** 控件类型 */
    Type: String,
    /** 字段 */
    Field: String,
    /** 存储位置 */
    Store: String,
    /** 是否只读 */
    Readonly: { type: Boolean, default: null },
    /** 是否允许拍照 */
    AllowCamera: { type: Boolean, default: null },
    /** 是否允许摄像 */
    AllowVideo: { type: Boolean, default: null },
    /** 附件模式（此时Field应设置为AnnexId） */
    AnnexMode: { type: Boolean, default: null },
    /** 摄像头列表 { label: string, ip: string } */
    Cameras: { type: Array, default: [] },
    /** 是否自动播放 */
    AutoPlay: { type: Boolean, default: null }
});

const model = defineModel();

/** 实例
 * @type {import("vue").Ref<import("vue").ComponentInstance>}
 */
const instance = ref();

const emits = defineEmits(["change"]);

/** 安装插件 */
function onInstallPlugin() {
    var a = document.createElement("a");
    a.download = "download";
    if (ViteSetting.IsApp)
        a.href = "?app=/Vue3/hikvision/HCWebSDKPlugin.zip";
    else
        a.href = "/Vue3/hikvision/HCWebSDKPlugin.zip";
    a.click();
}

function onRefresh() {
    loadCamera();
}

/** 当前摄像头 */
const currentCamera = ref(0);

function onChangeCamera(value) {
    // 退出当前摄像头
    manager.I_Logout(`${props.Cameras[value].IP}_${props.Cameras[value].Port || 80}`);
    if (isRecord.value) {
        onToggleRecord();
    }
    if (isPlay.value) {
        onTogglePlay();
    }
}

/** 唯一ID */
const id = uniqueId("z-camera-");
/** 是否需要安装插件 */
const needInstallPlugin = ref(false);

/** 是否播放 */
const isPlay = ref(false);

/** 登录摄像头 */
function LoginAsync(index) {
    return new Promise((resolve, reject) => {
        try {
            index = index || currentCamera.value;
            let _camera = props.Cameras[index];
            manager.I_Login(_camera.IP, 1, _camera.Port || 80, _camera.Account, _camera.Password, {
                timeout: 3000,
                success: resolve,
                error({ errorCode, errorMsg }) {
                    // 2001 已经登录，无须重复登录
                    if (errorCode == 2001) {
                        resolve();
                    } else {
                        console.warn("摄像头登录失败：", errorCode, errorMsg);
                        Toast("获取摄像头失败！", "error");
                        reject({ errorCode, errorMsg });
                    }
                }
            });
        } catch (e) {
            Toast("获取摄像头失败！", "error");
            reject({ errorCode: -1, errorMsg: e });
        }
    });
}

/** 切换播放 */
function onTogglePlay() {
    if (props.Cameras.length <= currentCamera.value) {
        Toast("当前无摄像头信息！", "error");
        return;
    }
    if (isPlay.value == false) {
        LoginAsync().then(() => {
            let _camera = props.Cameras[currentCamera.value];
            manager.I_StartRealPlay(`${_camera.IP}_${_camera.Port || 80}`, {
                iStreamType: 1,
                iChannelID: 1,
                bZeroChannel: false,
                success() {
                    isPlay.value = true;
                },
                error({ errorCode, errorMsg }) {
                    // 3001 已经处于播放状态
                    if (errorCode == 3001) {
                        isPlay.value = true;
                    } else {
                        Toast("播放失败", "error");
                        console.warn("播放失败：", errorCode, errorMsg);
                    }
                }
            });
        });
    } else {
        manager.I_Stop({
            success() {
                isPlay.value = false;
            },
            error(err) {
                Toast("停止失败", "error");
            }
        });
    }
}

/** 是否录像 */
const isRecord = ref(false);
/** 录像文件名 */
const filename = ref("");

/** 切换录像 */
function onToggleRecord() {
    if (isRecord.value == false) {
        onStartRecord();
    } else {
        onStopRecord();
    }
}

/** 开始录像 */
function onStartRecord() {
    if (isPlay.value == false) {
        Toast("请先打开摄像头！", "error");
        return Promise.reject("摄像头未打开！");
    }
    //允许重复开始录像
    if (isRecord.value)
        return Promise.resolve();
    return new Promise((resolve, reject) => {
        RegisterEvent(instance.value, "StartRecord", () => {
            return LoginAsync().then(() => {
                var _camera = props.Cameras[currentCamera.value];
                filename.value = `${_camera.Label}-${dayjs().format("HHmmss")}`;
                manager.I_StartRecord(filename.value, {
                    bDateDir: true,
                    success() {
                        isRecord.value = true;
                        resolve();
                    },
                    error({ errorCode, errorMsg }) {
                        // 3001 已经处于录像状态
                        if (errorCode == 3001) {
                            isRecord.value = true;
                            resolve();
                        } else {
                            console.warn("录像失败：", errorCode, errorMsg);
                            Toast("开始录像失败", "error");
                            reject("录像失败！");
                        }
                    }
                });
            });
        }, props);
    });
}

/** 停止录像
 * @returns {Promise<void>}
 */
function onStopRecord() {
    if (isRecord.value == false)
        return Promise.reject("未开始录像！");
    return new Promise((resolve1, reject1) => {
        RegisterEvent(instance.value, "StopRecord", () => {
            return new Promise((resolve2, reject2) => {
                var resolve = (res) => {
                    resolve1(res);
                    resolve2(res);
                };
                var reject = (err) => {
                    reject1(err);
                    reject2(err);
                };
                manager.I_StopRecord({
                    success() {
                        manager.I_GetLocalCfg().then(cfg => {
                            var fPath = `${cfg.recordPath}\\${dayjs().format("YYYY-MM-DD")}\\${filename.value}.mp4`;
                            CopyToClipboard(fPath);
                            Alert(`录像保存成功，已复制路径，请手动上传文件！\n路径：${fPath}`, "success", () => {
                                upload(null, resolve, reject);
                            });
                            isRecord.value = false;
                        }, ({ errorCode, errorMsg }) => {
                            console.warn("获取录像路径失败：", errorCode, errorMsg);
                            Alert(`录像保存失败，未找到录像路径！`, "error");
                            isRecord.value = false;
                            reject("录像保存失败，未找到录像路径！");
                        });
                    },
                    error({ errorCode, errorMsg }) {
                        console.warn("停止录像失败：", errorCode, errorMsg);
                        Toast("停止录像失败", "error");
                        reject("停止录像失败！");
                    }
                });
            });
        }, props);
    });
}

/** 上传
 * @param {string} content 图片base64
 * @param {function} success 成功回调
 * @param {function} fail 失败回调
 */
function upload(content, success, fail) {
    let fnSuccess = res => {
        if (props.AnnexMode) {
            HttpPost("/Annex/Upload", {
                AnnexId: model.value,
                FileName: res.data.name,
                FilePath: res.data.src
            }, res => {
                if (model.value != res.data.AnnexId) {
                    model.value = res.data.AnnexId;
                    emits("change", res.data.AnnexId);
                }
                toFn(success)(res);
            }, fail);
        } else {
            if (model.value != res.data.src) {
                model.value = res.data.src;
                emits("change", res.data.src);
            }
            toFn(success)(res);
        }
    };
    if (content) {
        HttpPost("/Vue/UploadByBase64", {
            store: props.AnnexMode ? "Annex" : String(props.Store),
            source: "image",
            filename: `${filename.value}.jpg`,
            content
        }, fnSuccess, fail);
    } else {
        HttpUpload("/Vue/Upload", false, {
            store: props.AnnexMode ? "Annex" : String(props.Store),
            source: "video"
        }, null, fnSuccess, fail);
    }
}

/** 拍照
 * @returns {Promise<any>}
 */
function onTakePicture() {
    if (isPlay.value == false) {
        Toast("请先打开摄像头！", "error");
        return Promise.reject("摄像头未打开！");
    }
    return new Promise((resolve1, reject1) => {
        RegisterEvent(instance.value, "TakePicture", () => {
            return new Promise((resolve2, reject2) => {
                var resolve = (res) => {
                    resolve1(res);
                    resolve2(res);
                };
                var reject = (err) => {
                    reject1(err);
                    reject2(err);
                };
                manager.I_CapturePicData().then(data => {
                    var _camera = props.Cameras[currentCamera.value];
                    filename.value = `${_camera.Label}-${dayjs().format("HHmmss")}`;
                    upload(data, resolve, reject);
                    Toast("抓图成功！", "success");
                }).catch(err => {
                    Toast("抓图失败！", "error");
                    reject("抓图失败！");
                });
            });
        }, props);
    });
}

const onResize = debounce(manager.I_Resize, 200);

const observer = new ResizeObserver(resizers => {
    onResize(resizers[0].contentRect.width, resizers[0].contentRect.height);
});

/** 加载相机 */
function loadCamera() {
    manager.I_InitPlugin({
        cbInitPluginComplete() {
            manager.I_InsertOBJECTPlugin(id).then(() => {
                needInstallPlugin.value = false;
                useAppStore().AddCamera(props.Field, {
                    TakePicture: onTakePicture,
                    StartRecord: onStartRecord,
                    StopRecord: onStopRecord
                });
                if (props.AutoPlay) {
                    onTogglePlay();
                }
            }, err => {
                needInstallPlugin.value = true;
            });
        }
    });
}

onMounted(() => {
    instance.value = getCurrentInstance();
    observer.observe(document.getElementById(id));
    nextTick(loadCamera);
});

onBeforeUnmount(() => {
    useAppStore().RemoveCamera(props.Field);
    var dom = document.getElementById(id);
    if (dom)
        observer.unobserve(dom);
    observer.disconnect();
    stopWatchs.forEach(stop => stop());
});

</script>

<style>
.z-camera {
    width: 100%;
    height: 210px;
    padding-bottom: 40px;
    min-height: inherit;
    background-color: var(--td-gray-color-9);
    position: relative;
}

.z-camera__content {
    height: 100%;
    overflow: hidden;
}

.z-camera__tabar {
    width: 100%;
    height: 40px;
    background-color: var(--td-gray-color-9);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
}

.z-camera__tabar svg {
    cursor: pointer;
}

.z-camera__tabar svg:hover {
    color: var(--vxe-primary-darken-color);
}

.z-camera__tabar-right {
    display: flex;
    padding-right: 15px;
}

.z-camera__tabar-right>*+* {
    margin-left: 15px;
}

.t-select .t-input--auto-width {
    width: 100%;
}
</style>