<template>
    <t-switch class="z-theme-switch" :customValue="['default', 'dark']" v-model="system.theme" @change="onThemeChange">
        <template #label="{ value }">
            <SunnyIcon v-if="value == 'default'" :size="12"></SunnyIcon>
            <MoonIcon v-else-if="value == 'dark'" :size="12"></MoonIcon>
            <span v-else></span>
        </template>
    </t-switch>
</template>

<script setup>
import { MoonIcon, SunnyIcon } from 'tdesign-icons-vue-next';
import { useSystemStore } from '../../stores/useSystemStore';

const system = useSystemStore();

function onThemeChange(e) {
}
</script>

<style scoped>
.z-theme-switch {
    background-color: var(--bluegray-600);
}

.z-theme-switch.t-is-checked {
    background-color: var(--yellow-500);
}
</style>