<template>
    <div class="z-monaco">
        <div class="z-monaco__editor" ref="crxContent"></div>
    </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import * as monaco from "monaco-editor";
import workerEditor from "monaco-editor/esm/vs/editor/editor.worker?worker";
import workerJSON from "monaco-editor/esm/vs/language/json/json.worker?worker";
import workerCSS from "monaco-editor/esm/vs/language/css/css.worker?worker";
import workerHTML from "monaco-editor/esm/vs/language/html/html.worker?worker";
import workerTS from "monaco-editor/esm/vs/language/typescript/ts.worker?worker";

const props = defineProps({
    /** 语言 */
    Language: String,
    /** 高度 */
    Height: { type: Number, default: null },
    /** 是否只读 */
    Readonly: { type: Boolean, default: false },
});
const emits = defineEmits(["change"]);

const model = defineModel({ type: String });

/** 编辑器容器
 * @type {import("vue").Ref<HTMLDivElement>}
 */
const crxContent = ref();
/** 编辑器实例
 * @type {import("monaco-editor").editor.IStandaloneCodeEditor}
 */
var editor = null;

/** 高度
 * @type {import("vue").ComputedRef<string>}
 */
const height = computed(() => {
    if (props.Height) {
        if (isNaN(props.Height))
            return props.Height;
        else
            return props.Height + "px";
    } else {
        return "400px";
    }
});

/**
 * 编辑器内容改变
 * @param {string} value
 */
function onChange(value) {
    if (model.value == value)
        return;
    let oldValue = model.value;
    model.value = value;
    emits("change", value, oldValue);
}

/**
 * 绑定值
 * @param {string} value
 */
function bindValue(value) {
    if (editor && editor.getValue() != value)
        editor.setValue(value);
}

const stopWatchs = [
    watch(() => model.value, bindValue, { immediate: true }),
    watch(() => props.Readonly, value => editor && editor.updateOptions({ readOnly: value }), { immediate: true })
];

/** 注册语言环境，使用 Worker 进行语言解析 */
window.MonacoEnvironment = {
    getWorker: function (workerId, label) {
        switch (label) {
            case 'json':
                return new workerJSON();
            case 'css':
            case 'scss':
            case 'less':
                return new workerCSS();
            case 'html':
            case 'handlebars':
            case 'razor':
                return new workerHTML();
            case 'typescript':
            case 'javascript':
                return new workerTS();
            default:
                return new workerEditor();
        }
    }
};

onMounted(() => {
    //创建编辑器
    editor = monaco.editor.create(crxContent.value, {
        value: props.modelValue,
        language: props.Language || "json",
        readOnly: props.Readonly,
        minimap: { enabled: false },
        /** 自动布局 */
        automaticLayout: true,
        folding: true,
        quickSuggestions: true,
        wordBasedSuggestions: true,
        wordBasedSuggestionsMode: "currentDocument",
        suggestOnTriggerCharacters: true,
        suggestOnTriggerCharactersDelay: 100,
        suggestSelection: "recentlyUsed",
        suggest: {
            showIcons: true,
            showMethods: true,
            showFunctions: true,
            showConstructors: true,
            showFields: true,
        }
    });
    //监听内容变化
    editor.onDidChangeModelContent(e => {
        let value = editor.getValue();
        onChange(value);
    });
    setInterval(() => {
        editor.render();
    }, 2000);
});

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
    if (editor)
        editor.dispose();
});
</script>

<style scoped>
.z-monaco {
    border: 1px solid var(--td-border-level-2-color);
    width: 100%;
    height: v-bind(height);
    overflow: hidden;
}

.z-monaco__editor {
    width: 100%;
    height: inherit;
}
</style>