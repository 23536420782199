import { Graph } from "@antv/x6";
import { Alert, ClosePage, Confirm, Loading } from "../../utils/dialog";
import { HttpPost } from "../../utils/network";
import { debounce } from "lodash";
import { IsNullOrEmpty } from "../../utils/common";
import { RefreshTable } from "../../utils/business";

/** 校验流程是否完整
 * @description
 * @param {Graph} gragh
 * @param {Edge[]} edges
 * @param {Node[]} nodes
 * @param {object} next
 * @param {Line[]} lines
 * @param {boolean} hasleader
 * @returns {boolean}
 */
function Validate(gragh, edges, nodes, next, lines, hasleader) {
    if (next == "end") {
        if (hasleader)
            return true;
        else {
            Alert("流程不完整，至少要有一个人员节点！", "error");
            return false;
        }
    }
    hasleader = hasleader || false;
    lines = lines || [];
    edges = edges || gragh.getEdges();
    nodes = nodes || gragh.getNodes();
    next = next || "start";
    var node = nodes.find(x => x.id == next);
    var nextEdges = edges.filter(x => x.source.cell == next);
    switch (node.data.type) {
        case "start":
        case "leader":
            if (nextEdges.length == 0) {
                Alert(`流程不完整，节点【${node.label}】未找到下游节点！`, "error");
                return false;
            } else if (node.data.type == "leader") {
                hasleader = true;
            }
            break;
        case "filter":
            if (node.data.FalseType == "next") {
                if (nextEdges.length < 2) {
                    Alert(`流程不完整，节点【${node.label}】必须连接两个下游节点！`, "error");
                    return false;
                }
            } else if (nextEdges.length == 0) {
                Alert(`流程不完整，节点【${node.label}】未找到下游节点！`, "error");
                return false;
            }
            break;
    }
    for (let i = 0; i < nextEdges.length; i++) {
        const nextEdge = nextEdges[i];
        if (lines.includes(nextEdge.id)) {
            Alert("流程出现循环，请检查！", "error");
            return false;
        }
        lines.push(nextEdge.id);
        if (Validate(gragh, edges, nodes, nextEdge.target.cell, lines, hasleader) == false)
            return false;
    }
    return true;
}

/**
 * @description 保存流程
 * @param {Graph} graph
 * @param {object} model
 */
const Save = debounce((graph, model) => {
    if (IsNullOrEmpty(model.Name)) {
        Alert("未填写名称！", "error");
        return;
    }
    if (IsNullOrEmpty(model.Business)) {
        Alert("未选择单据类型！", "error");
        return;
    }
    let next = Validate(graph);
    if (next) {
        model.Content = JSON.stringify(graph.toJSON());
        var loading = Loading("正在保存数据，请稍候...");
        HttpPost("Save", model, function () {
            Alert("保存成功！", "success", () => {
                ClosePage(function () {
                    RefreshTable(this);
                });
            });
        }, null, loading.close);
    }
}, 200);

function Cancel() {
    Confirm("确定要取消编辑吗？", function () {
        ClosePage();
    });
}

export {
    Validate,
    Save,
    Cancel
}