<template>
    <component class="z-datetime" :is="options.component" v-bind="options.option" v-model="inputValue"></component>
</template>

<script setup>
import { computed, watch, ref, onBeforeUnmount } from "vue";
import { DatePicker, TimePicker } from "tdesign-vue-next";
import { setting } from "../../config/appsetting";

const props = defineProps({
    /** 控件类型 */
    Type: String,
    Field: String,
    Format: String,
    Readonly: { type: Boolean, default: null }
});
const emits = defineEmits(["change"]);

const model = defineModel({ type: String || Date });
const inputValue = ref();

const options = computed(() => {
    if (props.Type == "time") {
        /**
         * @type {import("tdesign-vue-next").TimePickerProps}
         */
        var option = {
            size: "small",
            disabled: props.Readonly === true,
            format: props.Format || setting.component.default[setting.component.type.date.indexOf(props.Type)],
            allowInput: true,
            clearable: true,
            onChange(value) {
                let oldValue = model.value;
                model.value = value;
                emits("change", value, oldValue);
            }
        };
        return { component: TimePicker, option };
    } else {
        /**
         * @type {import("tdesign-vue-next").DatePickerProps}
         */
        var option = {
            size: "small",
            disabled: props.Readonly === true,
            allowInput: true,
            clearable: true,
            format: props.Format || setting.component.default[setting.component.type.date.indexOf(props.Type)],
            mode: props.Type == "datetime" ? "date" : props.Type,
            enableTimePicker: props.Type == "datetime",
            valueType: setting.component.dateFormat,
            onChange(value) {
                let oldValue = model.value;
                model.value = value;
                emits("change", value, oldValue);
            }
        };
        return { component: DatePicker, option };
    }
});

const stopWatchs = [
    watch(() => model.value, value => {
        inputValue.value = value;
    }, { immediate: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>

<style scoped>
.z-datetime {
    width: 100%;
}

:deep(.t-input.t-is-disabled) {
    background-color: var(--td-bg-color-specialcomponent);
    color: var(--td-text-color-primary);
}

:deep(.t-input.t-is-disabled .t-input__inner) {
    color: var(--td-text-color-primary);
}
</style>