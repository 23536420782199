import { defineStore } from "pinia";
import { appsettings } from "../../appsettings";
import { HelpIcon } from "tdesign-icons-vue-next";
import PageHelp from "../components/custom/page-help.vue";
import { h } from "vue";
import { KeepThisPage, LeaveThisPage } from "../utils/common";

export const useStickyToolStore = defineStore("stickytool", {
    state: () => ({
        PopupProps: {
            placement: 'left-top',
            onVisibleChange(visible) {
                if (visible)
                    KeepThisPage();
                else
                    LeaveThisPage();
            }
        },
        stickytools: []
    }),
    actions: {
        /**
         * @param {...import("tdesign-vue-next").TdStickyItemProps} buttons
         */
        AddStickyTool(...buttons) {
            this.stickytools.push(...buttons);
        },
        /**
         * @param {..."help"} types
         */
        AddStandardStickyTool(...types) {
            this.AddStickyTool(...types.map(type => {
                if (type == "help" && location.pathname != appsettings.Service.QuestionPage) {
                    return {
                        label: "帮助",
                        icon: h(HelpIcon, {
                            style: { color: "var(--primary-color)" }
                        }),
                        trigger: "click",
                        popup() {
                            return h(PageHelp, {});
                        }
                    }
                }
            }));
        },
        ClearStickyTool() {
            this.stickytools.splice(0, this.stickytools.length);
        }
    }
})