export const appsettings = {
    SystemId: "nao",
    Menu: {
        /**
         * 是否开启搜索
         */
        EnableSearch: false,
        /**
         * 是否开启多标签模式
         */
        MultipleMode: false,
        /**
         * 获取菜单URL
         */
        URL: "/Home/GetMenu",
        /**
         * 工作台
         */
        HomeTab: {
            id: "home",
            title: "工作台",
            icon: "home",
            href: "/Home/Dashboard",
            titles: ["工作台"]
        },
        /** 主题 */
        Theme: ""
    },
    Page: {
        HelpButton: false
    },
    /**
     * 配置服务
     */
    Service: {
        /**
         * 问题帮助页面
         */
        QuestionPage: "/Genesis/QueReqManage/Question/CusEorA",
        /**
         * 帮助页面
         */
        GetPageHelpInfo: "/Genesis/Permissions/Menu/GetPageHelp"
    },
    Query: {
        /** 是否开启Genesis查询 */
        GenesisQuery: false
    }
}