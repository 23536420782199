<template>
    <div class="z-template" ref="crxContent"></div>
</template>

<script setup>
import { onBeforeUnmount, watch, ref, nextTick } from "vue";
import { HasContent, toFn } from "../../utils/common";

const props = defineProps({
    Label: String,
    Type: String,
    Field: String,
    Render: String || Function,
    IsDynamic: { type: Boolean, default: null },
    Readonly: { type: Boolean, default: null }
});
const emits = defineEmits(["change", "render"]);
const model = defineModel({ type: String });
const dataModel = defineModel("data");
/**
 * @type {import("vue").Ref<HTMLElement>}
 */
const crxContent = ref();

/** 渲染内容 */
function onRender() {
    if (crxContent.value) {
        var value = emits("render", props, dataModel.value, onChange);
        if (HasContent(value) == false)
            value = toFn(props.Render)(props, dataModel.value, onChange);
        if (typeof value == "string")
            crxContent.value.innerHTML = value;
        else if (value instanceof HTMLElement) {
            crxContent.value.innerHTML = "";
            crxContent.value.appendChild(value);
        }
    }
}

/** 变更事件 */
function onChange(value) {
    let oldValue = model.value;
    model.value = value;
    emits("change", value, oldValue);
}

const stopWatchs = [
    watch(() => dataModel.value, () => nextTick(onRender), { immediate: true, deep: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>