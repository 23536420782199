import { defineStore } from "pinia";
import { OnceRequest } from "../utils/network.extend";
import { HttpGet } from "../utils/network";
import { ZMenuItem } from "../models/ZMenuItem";
import { OpenContextMenu } from "../utils/dialog";
import { GetStorageAsync, HasContent, SetStorageAsync } from "../utils/common";
import { isArray } from "lodash";
import { appsettings } from "../../appsettings";
import { match } from "pinyin-pro";

export const useMenuScore = defineStore("menu", {
    state: () => ({
        /**
         * 是否开启搜索
         */
        enableSearch: appsettings.Menu.EnableSearch,
        homeTab: appsettings.Menu.HomeTab,
        /** 
         * 已经打开的菜单
         * @type {ZMenuItem[]}
         */
        menuTabs: [],
        /**
         * 当前激活的菜单
         */
        activeMenuTab: "home",
        /**
         * 是否多选菜单模式
         */
        multipleMode: appsettings.Menu.MultipleMode,
        /**
         * 事件监听器
         */
        listener: {
            /**
             * 打开菜单
             * @type {(menu: ZMenuItem)=>void}
             */
            open: [],
            /**
             * 关闭菜单
             * @type {(menu: ZMenuItem)=>void}
             */
            close: []
        }
    }),
    getters: {
        activeMenuTabIndex(state) {
            return state.menuTabs.findIndex(menu => menu.id == state.activeMenuTab);
        },
        activeMenuFullPath(state) {
            return state.menuTabs.find(menu => menu.id == state.activeMenuTab)?.titles;
        }
    },
    actions: {
        /**
         * 添加事件监听器
         * @param {"open" | "close"} name 
         * @param {(menu: ZMenuItem[])=>void} listener 
         */
        addEventListener(name, listener) {
            this.listener[name].push(listener);
        },
        /**
         * 执行事件监听
         * @param {"open" | "close"} name 
         * @param {ZMenuItem ｜ ZMenuItem[]} menu
         */
        doEventListener(name, menu) {
            if (menu) {
                if (isArray(menu))
                    this.listener[name].forEach(listener => listener(menu));
                else
                    this.listener[name].forEach(listener => listener([menu]));
            }
        },
        /**
         * 移除事件监听器
         * @param {"open" | "close"} name 
         * @param {(menu: ZMenuItem[])=>void} listener 
         */
        removeEventListener(name, listener) {
            let index = this.listener[name].indexOf(listener);
            if (index >= 0)
                this.listener[name].splice(index, 1);
        },
        /**
         * 搜索
         * @param {string} word 
         * @returns {Promise<ZMenuItem[]>}
         */
        async search(word) {
            return new Promise((resolve) => {
                OnceRequest(HttpGet)(appsettings.Menu.URL, {}, res => this.bindPath(res.data, []) && resolve(this.match(res.data, word)));
            });
        },
        /**
         * 绑定菜单路径
         * @param {ZMenuItem[]} menus 
         * @param {string[]} prefix 
         */
        bindPath(menus, prefix) {
            menus.forEach(menu => {
                menu.titles = [...prefix, menu.title];
                if (menu.children)
                    this.bindPath(menu.children, menu.titles);
            });
            return true;
        },
        /**
         * 菜单匹配算法
         * @param {ZMenuItem[]} menus 
         * @param {string} word 
         * @returns {ZMenuItem[]}
         */
        match(menus, word) {
            if (word) {
                return menus.filter(menu => {
                    if (match(menu.title, word, { precision: "first", continuous: true }))
                        return true;
                    else if (menu.children) {
                        menu.children = this.match(menu.children, word);
                        return menu.children.length > 0;
                    }
                    return false;
                });
            }
            return menus;
        },
        /**
         * 打开菜单
         * @param {ZMenuItem | string} menu 
         */
        openMenuTab(menu) {
            if (typeof menu == "string") {
                let index = this.menuTabs.findIndex(x => x.id == menu);
                if (index > -1) {
                    // 跳转到指定菜单，重新查询菜单
                } else
                    this.changeMenuTab(menu);
            } else {
                if (this.menuTabs.findIndex(x => x.id == menu.id) == -1) {
                    this.menuTabs.push(menu);
                    this.recordMenuTabs();
                }
                this.changeMenuTab(menu);
            }
        },
        /**
         * 关闭菜单
         * @param {ZMenuItem | string} menu 
         */
        closeMenuTab(menu) {
            let index;
            if (typeof menu == "string")
                index = this.menuTabs.findIndex(x => x.id == menu);
            else
                index = this.menuTabs.indexOf(menu);
            if (index > -1) {
                //当前激活的菜单
                let active = this.menuTabs.findIndex(x => x.id == this.activeMenuTab);
                //如果是当前激活的菜单
                if (active == index) {
                    //如果只有一个菜单，跳转工作台
                    if (this.menuTabs.length == 1)
                        this.changeMenuTab("home");
                    //如果是最后一个菜单，则前移菜单
                    else if (active == this.menuTabs.length - 1)
                        this.changeMenuTab(this.menuTabs[active - 1]);
                    //否则，后移菜单
                    else
                        this.changeMenuTab(this.menuTabs[active + 1]);
                }
                var removeItems = this.menuTabs.splice(index, 1);
                this.doEventListener("close", removeItems);
                this.recordMenuTabs();
            }
        },
        /**
         * 关闭左侧菜单
         * @param {ZMenuItem | string} menu 
         */
        closeLeftMenuTab(menu) {
            let index;
            if (typeof menu == "string")
                index = this.menuTabs.findIndex(x => x.id == menu);
            else
                index = this.menuTabs.indexOf(menu);
            if (index > 0) {
                let active = this.menuTabs.findIndex(x => x.id == this.activeMenuTab);
                if (active < index)
                    this.changeMenuTab(menu);
                var removeItems = this.menuTabs.splice(0, index);
                this.doEventListener("close", removeItems);
                this.recordMenuTabs();
            }
        },
        /**
         * 关闭右侧菜单
         * @param {ZMenuItem | string} menu 
         */
        closeRightMenuTab(menu) {
            let index;
            if (typeof menu == "string")
                index = this.menuTabs.findIndex(x => x.id == menu);
            else
                index = this.menuTabs.indexOf(menu);
            if (index > -1 && index <= this.menuTabs.length - 1) {
                let active = this.menuTabs.findIndex(x => x.id == this.activeMenuTab);
                if (active > index)
                    this.changeMenuTab(menu);
                var removeItems = this.menuTabs.splice(index + 1, this.menuTabs.length);
                this.doEventListener("close", removeItems);
                this.recordMenuTabs();
            }
        },
        /**
         * 关闭其他菜单
         * @param {ZMenuItem | string} menu 
         */
        closeOtherMenuTab(menu) {
            let index;
            if (typeof menu == "string")
                index = this.menuTabs.findIndex(x => x.id == menu);
            else
                index = this.menuTabs.indexOf(menu);
            if (index > -1) {
                let active = this.menuTabs.findIndex(x => x.id == this.activeMenuTab);
                if (active != index)
                    this.changeMenuTab(menu);
                var removeItems = this.menuTabs.splice(0, index);
                removeItems.push(... this.menuTabs.splice(1, this.menuTabs.length));
                this.doEventListener("close", removeItems);
                this.recordMenuTabs();
            }
        },
        /**
         * 关闭所有菜单
         */
        closeAllMenuTab() {
            var removeItems = this.menuTabs.splice(1, this.menuTabs.length - 1);
            this.doEventListener("close", removeItems);
            this.recordMenuTabs();
            this.changeMenuTab("home");
        },
        /**
         * 切换菜单
         * @param {ZMenuItem | string} menu 
         */
        changeMenuTab(menu) {
            if (typeof menu == "string") {
                this.activeMenuTab = menu;
                if (menu == "home")
                    this.doEventListener("open", this.homeTab);
                else
                    this.doEventListener("open", this.menuTabs.find(x => x.id == menu));
            }
            else {
                this.activeMenuTab = menu.id;
                this.doEventListener("open", menu);
            }
        },
        /**
         * 打开菜单上下文菜单
         * @param {MouseEvent} e 
         * @param {ZMenuItem | string} menu 
         */
        openContextMenu(e, menu) {
            let index, menus = [];
            if (typeof menu == "string")
                index = this.menuTabs.findIndex(x => x.id == menu);
            else
                index = this.menuTabs.indexOf(menu);
            if (index == -1)
                return;
            menus.push({
                label: "关闭标签页",
                command: () => this.closeMenuTab(menu)
            });
            if (index > 1 && index < this.menuTabs.length - 1) {
                menus.push({
                    label: "关闭其他标签页",
                    command: () => this.closeOtherMenuTab(menu)
                });
            }
            if (index > 1) {
                menus.push({
                    label: "关闭左侧标签页",
                    command: () => this.closeLeftMenuTab(menu)
                });
            }
            if (index < this.menuTabs.length - 1) {
                menus.push({
                    label: "关闭右侧标签页",
                    command: () => this.closeRightMenuTab(menu)
                });
            }
            if (this.menuTabs.length > 1) {
                menus.push({
                    label: "关闭全部标签页",
                    command: () => this.closeAllMenuTab()
                });
            }
            OpenContextMenu(e, menus);
        },
        recordMenuTabs() {
            if (this.multipleMode)
                SetStorageAsync("$$menu", this.menuTabs);
        },
        /**
         * 初始化记录菜单
         */
        initRecordMenuTabs() {
            if (this.multipleMode) {
                GetStorageAsync("$$menu").then(value => {
                    if (HasContent(value))
                        this.menuTabs.splice(0, this.menuTabs.length, ...value);
                    this.doEventListener("open", this.homeTab);
                });
            }
        }
    },
});