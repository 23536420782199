import { getCurrentInstance, nextTick, ref } from 'vue';
import { TableReadonly, isSystemColumn } from './action';
import { GetStorageAsync, HasContent, IsNullOrEmpty, RegisterEvent, SetStorageAsync, emptyFn } from '../../../utils/common';
import { debounce } from 'lodash';
import { GetLocationURL } from '../../../utils/network';
import { GetFromMenu } from '../../../utils/business';

/**
 * @description 表格事件
 * @returns {{
 *  currentRow: import("vue").Ref<import("vxe-table").VxeTableDataRow>,
 *  events: import('vxe-table').VxeGridEventProps
 * }}
 */
function useVxeTableEvent({ emits }) {
    /** 表格选中事件
     * @param {import("vxe-table").VxeGridInstance} $grid
     */
    function _tableSelect($grid, row, operate) {
        if ($grid.props.showFooter)
            $grid.updateFooter();
        let config = $grid.props.params,
            model = config.GetModelValue()
        RegisterEvent($grid, "TableSelect", () => {

        }, $grid, $grid.getCheckboxRecords(), row, config, model[config.Field], operate);
    }

    const TableSelect = debounce(_tableSelect, 200);

    const currentRow = ref({});

    /**
     * @type {import('vxe-table').VxeGridEventProps}
     */
    const events = {
        /** 表头鼠标右键事件 */
        onHeaderCellMenu() {

        },
        /** 表头双击事件 */
        onHeaderCellDblclick({ column, $table }) {
            if (isSystemColumn(column) == false) {
                switch (column.order) {
                    case "asc":
                        $table.sort(column.field, "desc");
                        break;
                    case "desc":
                        $table.clearSort(column.field);
                        break;
                    default:
                        $table.sort(column.field);
                        break;
                }
            }
        },
        /** 单元格点击的事件
         * @param {Object} param0 
         * @param {*} param0.column 
         * @param {*} param0.row 
         * @param {HTMLDivElement} param0.cell 
         * @param {*} param0.$table 
         * @param {*} param0.$grid 
         */
        onCellClick({ column, row, cell, $table, $grid }) {
            if (isSystemColumn(column))
                return;
            const config = $table.props.params;
            if (config.IsGroupTable) {
                return console.warn("分组方案，暂时不支持行点击！");
            }
            RegisterEvent($grid, "CellClick", () => {
                emits("cell-click", row, column.params);
            }, config, row, column.params, cell);
            RegisterEvent($grid, "RowClick", () => {
                emits("row-click", row, column.params);
            }, config, row, column.params);
            //单元格点击之后，等待 200 毫秒后，自动选择单元格里面的输入框
            setTimeout(() => {
                var doms = cell.getElementsByTagName("input");
                if (doms.length > 0)
                    doms[0].focus();
            }, 200);
        },
        /** 单元格双击事件 */
        onCellDblclick({ column, row, $grid, $table, $event }) {
            if (isSystemColumn(column))
                return;
            const config = $table.props.params;
            if (config.IsGroupTable) {
                return console.warn("分组方案，不支持行双击！");
            }
            RegisterEvent($grid, "RowDbClick", () => {
                emits("row-dbclick", row, column.params);
            }, config, row, column.params);
            if ($event && $event.target && IsNullOrEmpty($event.target.innerText)) {
                //阻止默认事件
                $event.preventDefault();
            }
        },
        /** 单元格鼠标右键事件 */
        onCellMenu() {

        },
        /** 表尾单击事件 */
        onFooterCellClick() {

        },
        /** 表尾双击事件 */
        onFooterCellDblclick() {

        },
        /** 表尾右键事件 */
        onFooterCellMenu() {

        },
        /** 手动勾选，并且发生改变时触发的事件 */
        onCheckboxChange({ checked, row, $grid }) {
            TableSelect.cancel();
            TableSelect($grid, row, checked ? { checks: [row], unchecks: [] } : { checks: [], unchecks: [row] });
        },
        /** 手动勾选全选时触发的事件 */
        onCheckboxAll({ $table, checked, $grid }) {
            TableSelect.cancel();
            TableSelect($grid, undefined, checked ? { checks: $table.getData(), unchecks: [] } : { checks: [], unchecks: $table.getData() });
        },
        /** 范围选中开始时触发的事件 */
        onCheckboxRangeStart({ $table }) {
            $table.tempCheckRecords = $table.getCheckboxRecords();
        },
        /** 范围选中结束时触发的事件 */
        onCheckboxRangeEnd({ $table, $grid }) {
            var oriRecords = $table.tempCheckRecords || [],
                records = $table.getCheckboxRecords();
            delete $table.tempCheckRecords;
            TableSelect.cancel();
            var unchecks = oriRecords.filter(x => records.includes(x) == false);
            TableSelect($grid, records, undefined, { checks: records, unchecks });
        },
        /** 列宽拖动发生变化时会触发该事件 */
        onResizableChange({ column, $grid }) {
            let config = $grid.props.params;
            //弹窗表格不需要记录宽度
            if (config.Field == "SHOW_TABLE_DATA")
                return;
            let code = `table_scheme${GetLocationURL().pathname}\/${GetFromMenu() || ""}`,
                key = `${config.Position}${config.Field}/${column.field}`;
            GetStorageAsync(code)
                .then(value => {
                    if (IsNullOrEmpty(value))
                        value = {};
                    value[key] = column.renderWidth;
                    SetStorageAsync(code, value);
                });
        },
        onCurrentChange({ row, column, $table, $grid }) {
            currentRow.value = row;
            let config = $table.props.params;
            if (TableReadonly(config) !== true) {
                if (HasContent(column))
                    $table.setEditCell(row, column);
                else
                    $table.setEditRow(row);
            }
            RegisterEvent($grid, "TableCurrentRowChange", emptyFn, config, row);
        }
    }
    return { currentRow, events };
}

export {
    useVxeTableEvent
}