<template>
    <div class="z-menu-tree">
        <t-menu :collapsed="isCollapse" v-model="model" v-model:expanded="expandMenu" :theme="theme" expandMutex>
            <template v-if="menuStore.enableSearch && isCollapse == false" #logo>
                <t-input class="z-menu-search" v-model="searchWord" clearable placeholder="快速搜索" @change="onSearch">
                    <template #prefix-icon>
                        <SearchIcon class="z-menu-search__icon"></SearchIcon>
                    </template>
                </t-input>
            </template>
            <menu-tree-item v-model="menus" prefix="" :keyword="searchWord" @click="onClick"></menu-tree-item>
            <template #operations>
                <div style="height: 33px;">
                    <t-button shape="square" theme="default" @click="onCollapse"
                        :class="{ 'btn-collapse': true, 'collapse': isCollapse }">
                        <template #icon>
                            <MenuFoldIcon v-if="isCollapse"></MenuFoldIcon>
                            <MenuUnfoldIcon v-else></MenuUnfoldIcon>
                        </template>
                    </t-button>
                </div>
            </template>
        </t-menu>
    </div>
</template>

<script setup>
import { MenuFoldIcon, SearchIcon, MenuUnfoldIcon } from 'tdesign-icons-vue-next';
import menuTreeItem from './menu-tree-item.vue';

import { ZMenuItem } from '../../../models/ZMenuItem';
import { useMenuScore } from "../../../stores/useMenuStore";
import { onMounted, reactive, ref } from 'vue';
import { debounce, padStart } from 'lodash';
import { HasContent, IsNullOrEmpty } from '../../../utils/common';
import { appsettings } from '../../../../appsettings';

const theme = appsettings.Menu.Theme;
const menuStore = useMenuScore();

const model = defineModel();

/** 菜单数据
 * @type {import("vue").UnwrapNestedRefs<ZMenuItem[]>}
 */
const menus = reactive([]);
/** 展开菜单 */
const expandMenu = ref([]);
/** 是否折叠 */
const isCollapse = ref(false);
/** 搜索词 */
const searchWord = ref("");

/** 搜索 */
const onSearch = debounce(onLoadMenu, 200);

/**
 * 折叠菜单
 */
function onCollapse() {
    isCollapse.value = !isCollapse.value;
}

/**
 * 点击菜单
 * @param {ZMenuItem} menu 
 * @description 无展开项事件，只触发叶节点的点击事件。允许重复点击菜单项
 */
function onClick(value) {
    menuStore.openMenuTab(value);
}

/**
 * 加载菜单
 * @param {string} word 搜索词
 */
function onLoadMenu(word) {
    menuStore.search(word)
        .then(res => {
            menus.splice(0, menus.length, ...res);
            if (IsNullOrEmpty(searchWord.value)) {
                if (expandMenu.value.length > 0)
                    expandMenu.value = [];
            } else
                expandMenu.value = CalculateExpand(menus);
            model.value = "";
            if (menuStore.multipleMode == false && IsNullOrEmpty(model.value)) {
                model.value = menus[0].id;
                menuStore.openMenuTab(menus[0]);
            }
        })
        .catch(e => console.warn(`菜单加载失败！原因：${e}`));
}

/** 计算展开项
 * @param {Array} arr 菜单
 * @param {String} prefix 前置
 * @author anturin
 */
function CalculateExpand(arr, prefix) {
    let res = [];
    if (HasContent(arr) && Array.isArray(arr)) {
        let value;
        arr.forEach((item, index) => {
            if (item.children) {
                value = `${prefix || ''}${padStart(index, 2, '0')}`;
                res.push(value, ...CalculateExpand(item.children, value));
            }
        });
    }
    return res;
}

onMounted(() => {
    onLoadMenu("");
});

</script>

<style scoped>
.z-menu-tree {
    width: fit-content;
    position: relative;
}

.z-menu-tree :deep(.t-default-menu:not(.t-menu--dark)) {
    background: var(--primary-500);
}

.z-menu-tree :deep(.t-default-menu:not(.t-menu--dark) .t-menu__item:hover),
.z-menu-tree :deep(.t-default-menu:not(.t-menu--dark) .t-menu__item:focus),
.z-menu-tree :deep(.t-default-menu:not(.t-menu--dark) .t-menu__item.t-is-active){
    background-color: var(--primary-800) !important;
}

.z-menu-tree :deep(.t-default-menu .t-menu__operations:not(:empty)){
    border: none;
}

.btn-collapse {
    transform: translateX(0px);
    transition: 0.28s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.btn-collapse:not(.collapse) {
    transform: translateX(170px);
    transition: 0.28s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.z-menu-tree :deep(.t-menu__sub) {
    --padding-left: 32px !important;
}

.z-menu-tree :deep(.t-menu__logo>*) {
    margin: 0;
}

.z-menu-tree :deep(.t-default-menu__inner .t-menu__logo:not(:empty)){
    height: fit-content;
}

.z-menu-search :deep(.t-input) {
    border: none;
    border-bottom: 1px solid var(--gray-600);
    border-radius: 0;
    background-color: transparent;
}

.z-menu-search :deep(.t-input.t-input--focused) {
    box-shadow: none;
}

.z-menu-search :deep(.t-input),
.z-menu-search :deep(.t-input__inner),
.z-menu-search :deep(.t-input__inner::placeholder),
.z-menu-search :deep(.t-input .t-input__prefix>.t-icon) {
    color: var(--color-white);
}

.z-menu-tree :deep(.t-default-menu .t-menu__item) {
    padding: 0 4px;
    height: var(--td-comp-size-s);
    line-height: var(--td-comp-size-s);
    color: var(--color-white);
}

.z-menu-tree :deep(.t-default-menu .t-menu__sub) {
    padding-left: 12px;
}

.z-menu-tree :deep(.t-default-menu__inner .t-menu__logo .t-input__wrap.z-menu-search) {
    margin-left: 0;
}

.z-menu-tree :deep(.t-default-menu__inner .t-menu__logo:not(:empty)){
    height: fit-content;
}

.z-menu-tree :deep(.t-default-menu__inner .t-menu) {
    padding: var(--td-comp-paddingTB-xs) var(--td-comp-paddingLR-s);
}
</style>