<template>
    <div class="z-pages-login">
        <div class="logo">
            <img class="logo-top" src="../src/assets/nao/images/logo_top.svg" />
            <img class="logo-auth" :src="authSrc" />
            <img class="logo-bottom" src="../src/assets/nao/images/logo_bottom.png" />
        </div>
    </div>
</template>
<script setup>
import { nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import { debounce } from "lodash";
import { ViteSetting } from "../src/config/vitesetting";
import { HttpGet } from "../src/utils/network";

const model = defineModel();

const authSrc = ref("");

const zoom = ref(1);

/** 校验是否登录成功 */
function CheckLogin() {
    setTimeout(() => {
        HttpGet("/Login/CheckLogin", {
            scene: model.value.scene
        }, res => {
            if (res.data.IsLogin) {
                if (ViteSetting.IsApp)
                    location.href = `?app=${encodeURIComponent('/')}`;
                else
                    location.href = "/";
            } else {
                CheckLogin();
            }
        });
    }, 1500);
}

/** 计算比例 */
const calcuteZoom = debounce((e) => {
    let zoomX = Math.floor(window.innerWidth / 0.96) / 1000,
        zoomY = Math.floor(window.innerHeight / 0.59) / 1000;
    zoom.value = zoomX > zoomY ? (zoomY * 0.95) : (zoomX * 0.95);
}, 200);

onMounted(() => {
    window.addEventListener("resize", calcuteZoom);
    calcuteZoom();
    nextTick(() => {
        if (ViteSetting.IsApp)
            authSrc.value = `${ViteSetting.ApiPrefix}${model.value.auth_img}`;
        else
            authSrc.value = model.value.auth_img;
        CheckLogin();
    });
});

onBeforeUnmount(() => {
    window.removeEventListener("resize", calcuteZoom);
});
</script>

<style>
.z-pages-login {
    height: inherit;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

.z-pages-login .logo {
    box-sizing: border-box;
    width: 960px;
    height: 590px;
    position: relative;
    zoom: var(--zoom);
}

.z-pages-login .logo .logo-top {
    width: 960px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.z-pages-login .logo .logo-auth {
    width: 90px;
    position: absolute;
    bottom: 165px;
    left: 394px;
    rotate: -5.8deg;
}

.z-pages-login .logo .logo-bottom {
    width: 300px;
    position: absolute;
    bottom: -8px;
    left: 360px;
}

.z-pages-login .logo {
    --zoom: v-bind(zoom);
}
</style>