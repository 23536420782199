import { defineStore } from "pinia";

export const useSystemStore = defineStore("system", {
    state: () => ({
        /**
         * 主题
         * @type {"default" | "dark"}
         */
        theme: "default",
        /**
         * 是否自定义配置
         * @type {boolean}
         */
        IsCustomConfig: false
    }),
    actions: {

    }
})