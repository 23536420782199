<template>
    <div class="z-rightext">
        <component ref="crxContent" :is="'script'" type="text/plain"></component>
    </div>
</template>

<script setup>
import { uniqueId } from "lodash";
import { nextTick, onMounted, onBeforeUnmount, ref, watch } from "vue";
import { HasContent, IsNullOrEmpty, toFn } from "../../utils/common";
import { UEditor } from "../../models/UEditor";

const props = defineProps({
    KeepFormat: { type: Boolean, default: null },
    TemplateCode: String,
    Height: { type: Number, default: null },
    Readonly: { type: Boolean, default: null }
});
const emits = defineEmits(["change"]);

const model = defineModel({ type: String });
const txtValue = defineModel("txtValue", { type: String });
const summaryValue = defineModel("summaryValue", { type: String });

const editTools = [[
    'fullscreen', 'undo', 'redo', '|',
    'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|',
    'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
    'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
    'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
    'directionalityltr', 'directionalityrtl', 'indent', '|',
    'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|',
    'touppercase', 'tolowercase', '|',
    'link', 'unlink', 'anchor', '|',
    'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
    'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'attachment', 'insertframe', 'pagebreak', 'background', '|',
    'horizontal', 'date', 'time', 'spechars', 'wordimage', '|',
    'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
    'print', 'preview', 'searchreplace'
]];
const viewTools = [['fullscreen', 'print', 'preview']];

/**
 * @type {import("vue").Ref<HtmlElemnt>}
 */
const crxContent = ref();
/** 组件唯一标识 */
const guid = ref(uniqueId('z-righ-'));
/** 编辑器组件
 * @type {import("vue").Ref<UEditor>}
 */
const ueditor = ref();

/** 渲染内容
 * @param {string[]} value
 */
function RenderContent(value, index) {
    if (index > 3 || IsNullOrEmpty(value))
        return;
    if (HasContent(ueditor.value)) {
        ueditor.value.ready(() => {
            if (ueditor.value.getContent() != value)
                ueditor.value.setContent(value, false, true);
        });
    }
    else
        nextTick(() => RenderContent(value, index + 1));
}

function onChange() {
    let value = ueditor.value.getContent();
    if (model.value != value) {
        let oldValue = model.value;
        model.value = value;
        if (props.KeepFormat)
            txtValue.value = ueditor.value.getPlainTxt();
        else
            txtValue.value = ueditor.value.getContentTxt();
        summaryValue.value = txtValue.value.replace(/(\n| )+/g, " ").substring(0, 50);
        emits("change", value, oldValue);
    }
}

/** 注册模板参数
 * @param {String} value
 * @author anturin
 */
function RegisterTemplateCode(value) {
    if (HasContent(value)) {
        UE.registerUI("templatecode", (editor, name) => {
            let dialog = new UE.ui.Dialog({ name, editor, title: "选择要插入的参数", cssRules: "width:400px;height:100px;" }),
                btn = new UE.ui.Button({
                    name: `dialogbutton${name}`,
                    title: "模板参数",
                    cssRules: "background-position: -420px -40px;",
                    onclick() {
                        let code = toFn(value)();
                        if (IsNullOrEmpty(code))
                            code = value;
                        dialog.iframeUrl = `/Vue/GetUEdtiorFields?code=${encodeURIComponent(code)}`;
                        dialog.render();
                        dialog.open();
                    }
                });
            return btn;
        });
    }
}

/** 渲染UEditor
 * @author anturin
 */
function RenderUEditor(readonly) {
    crxContent.value.id = guid.value;
    UE.delEditor(guid.value);
    ueditor.value = UE.getEditor(guid.value, {
        toolbars: readonly ? viewTools : editTools,
        initialFrameWidth: "100%",
        initialFrameHeight: props.Height || 320,
    });
    if (readonly !== true)
        ueditor.value.addListener("contentChange", onChange);
    ueditor.value.ready(() => {
        if (readonly) {
            try {
                ueditor.value.setDisabled();
            } catch (e) {
                /** 未知原因会报错，未发现会影响业务，吃掉异常 */
                console.warn(e);
            }
        }
        else
            ueditor.value.setEnabled();
    });
}

function bindHeight(height) {
    if (height) {
        nextTick(() => {
            if (ueditor.value) {
                ueditor.value.ready(() => {
                    ueditor.value.setHeight(height);
                });
            }
        });
    }
}

const stopWatchs = [
    watch(() => model.value, value => RenderContent(value, 0), { immediate: true }),
    watch(() => props.TemplateCode, RegisterTemplateCode, { immediate: true }),
    watch(() => props.Readonly, RenderUEditor),
    watch(() => props.Height, bindHeight, { immediate: true })
];

onMounted(() => {
    RenderUEditor(props.Readonly);
});

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>

<style scoped>
.z-rightext {
    height: 100%;
    width: 100%;
}
</style>