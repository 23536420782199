<template>
    <div class="z-progress">
        <div ref="crxProgress">
            <span v-text="percent + '%'"></span>
        </div>
    </div>
</template>

<script setup>
import { nextTick, onBeforeUnmount, ref, watch } from "vue";

const props = defineProps({
    /** 中等边界，默认20% */
    Low: { type: Number, default: null },
    /** 高等边界，默认80% */
    High: { type: Number, default: null },
    /** 颜色，默认[error,primary,success] */
    Color: Array,
    /** 是否为危险进度条，此时颜色取反 */
    IsDanger: { type: Boolean, default: null },
    /** 值是否为百分比，默认为小数 */
    IsPercent: { type: Boolean, default: null },
    /** 高度，默认100% */
    Height: { type: Number, default: null }
});

const model = defineModel({ type: Number });
/** 进度条
 * @type {import("vue").Ref<HTMLDivElement>}
 */
const crxProgress = ref();

const percent = ref(0);

function bindDangerColor() {
    crxProgress.value.style.backgroundColor = props.Color && props.Color.length > 0 ? props.Color[0] : "var(--red-500)";
}

function bindSuccessColor() {
    crxProgress.value.style.backgroundColor = props.Color && props.Color.length > 2 ? props.Color[2] : "var(--success-500)";
}

/**
 * 绑定进度条
 * @param {number} value
 */
function bindValue(value) {
    if (props.IsPercent)
        percent.value = value || 0;
    else
        percent.value = value * 100 || 0;
    nextTick(() => {
        if (crxProgress.value) {
            crxProgress.value.style.width = `${percent.value > 100 ? 100 : percent.value < 0 ? 0 : percent.value}%`;
            if (percent.value < props.Low || 20) {
                if (props.IsDanger)
                    bindSuccessColor();
                else
                    bindDangerColor();
            } else if (percent.value < props.High || 80) {
                crxProgress.value.style.backgroundColor = props.Color && props.Color.length > 1 ? props.Color[1] : "var(--yellow-500)";
            } else {
                if (props.IsDanger)
                    bindDangerColor();
                else
                    bindSuccessColor();
            }
        }
    });
}

/**
 * 绑定高度
 * @param {number} value
 */
function bindHeight(value) {
    nextTick(() => {
        if (crxProgress.value) {
            crxProgress.value.style.height
                = crxProgress.value.style.lineHeight
                = value ? `${value}px` : "100%";
        }
    })
}

const stopWatchs = [
    watch(() => model.value, bindValue, { immediate: true }),
    watch(() => props.Height, bindHeight, { immediate: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});

</script>

<style>
.z-progress {
    width: 100%;
    border-radius: 2px;
    display: flex;
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
}

.z-progress>div {
    overflow: hidden;
    max-width: 100%;
    height: 100%;
    border-radius: 2px;
}

.z-progress span {
    position: absolute;
    left: 50%;
    color: white;
    transform: translateX(-50%);
}
</style>