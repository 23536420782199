<template>
    <ContextMenu class="z-contextmenu" ref="crxContextMenu" :model="store.menus" @hide="onHide">
    </ContextMenu>
</template>

<script setup>
import ContextMenu from "primevue/contextmenu";
import { onBeforeUnmount, ref, watch } from "vue";
import { useContextMenuStore } from "../../stores/useContextMenuStore";

const store = useContextMenuStore();

/**
 * @type {import("vue").Ref<ContextMenu>}
 */
const crxContextMenu = ref(null);

const stopWatchs = [
    watch(() => store.event, (value) => {
        if (value) {
            crxContextMenu.value.show(value);
        } else {
            crxContextMenu.value.hide();
        }
    })
];

/**
 * 隐藏菜单事件
 */
function onHide() {
    store.hide();
}

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
})
</script>

<style scoped>
.z-contextmenu {
    font-size: 14px;
}
</style>