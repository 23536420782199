<template>
    <div class="z-filter-number">
        <t-input-number class="z-filter__input" v-model="min" theme="normal" size="small" clearable></t-input-number>
        <div style="padding: 0 4px;"><minus-icon /></div>
        <t-input-number class="z-filter__input" v-model="max" theme="normal" size="small" clearable></t-input-number>
        <i :class="'tur-sort tur-icon-sort' + column.order" @click="changeSort"></i>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onBeforeUpdate, onMounted, ref, watch } from "vue";
import { Toast } from "../../../utils/dialog";

const props = defineProps({
    column: Object,
    columnIndex: { type: Number, default: null },
    options: Object,
    /** 绑定表格实例
     * @type {import("vxe-table").VxeGridInstance}
     */
    table: Object,
    /** 绑定过滤面板实例
     * @type {import("vxe-table").VxeFilterPanel}
     */
    panel: Object
});
const min = ref();
const max = ref();
const renderColumn = ref();

function loadData() {
    renderColumn.value = props.column.field;
    if (props.column.params.FilterMin)
        min.value = props.column.params.FilterMin;
    if (props.column.params.FilterMax)
        max.value = props.column.params.FilterMax;
}

/** 变更排序 */
function changeSort() {
    switch (props.column.order) {
        case "asc":
            props.table.sort(props.column.field, "desc");
            break;
        case "desc":
            props.table.clearSort(props.column.field);
            break;
        default:
            props.table.sort(props.column.field);
            break;
    }
}

/** 提交筛选 */
function submitFilter() {
    let fnFilter;
    if (min.value) {
        if (max.value) {
            fnFilter = x => {
                if (isNaN(x[props.column.field]))
                    return false;
                else {
                    let number = Number(x[props.column.field]);
                    return number >= Number(min.value) && number <= Number(max.value);
                }
            };
        } else {
            fnFilter = x => {
                if (isNaN(x[props.column.field]))
                    return true;
                else
                    return Number(x[props.column.field]) >= Number(min.value);
            }
        }
    } else {
        if (max.value) {
            fnFilter = x => {
                if (isNaN(x[props.column.field]))
                    return true;
                else
                    return Number(x[props.column.field]) <= Number(max.value);
            };
        } else {
            props.table.clearFilter(renderColumn.value).then(() => props.table.updateData());
            return;
        }
    }
    let fnMap = x => ({ label: String(x[props.column.field]), value: String(x[props.column.field]), checked: true }),
        value = props.table.getData().filter(fnFilter).map(fnMap);
    if (value.length == 0) {
        Toast("已取消筛选，此范围无数据！", "error");
        min.value = "";
        props.column.params.FilterMin = "";
        max.value = "";
        props.column.params.FilterMax = "";
        props.table.clearFilter(renderColumn.value).then(() => props.table.updateData());
    } else {
        props.table.setFilter(renderColumn.value, value).then(() => props.table.updateData());
    }
}

const stopWatchs = [
    watch(() => min.value, value => props.column.params.FilterMin = value),
    watch(() => max.value, value => props.column.params.FilterMax = value)
];

onBeforeUpdate(() => {
    //字段直接切换时，不会触发onMounted、onBeforeUnmount事件，需要提交筛选和重新加载数据
    if (props.column.field != renderColumn.value) {
        submitFilter();
        loadData();
    }
});

/** 筛选关闭时，自动提交筛选，无需额外的操作，防止未点击筛选 */
onBeforeUnmount(() => {
    submitFilter();
    stopWatchs.forEach(stop => stop());
});

onMounted(() => {
    loadData();
});

</script>

<style scoped>
.z-filter-number {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 360px;
}

.tur-sort {
    padding: 4px 0.6em;
    border-radius: 2px;
    flex-shrink: 0;
}

.tur-sort:hover {
    cursor: pointer;
    color: var(--td-text-color-anti);
    background-color: var(--td-brand-color);
}

.z-filter__input {
    width: 150px;
    flex-shrink: 0;
}
</style>