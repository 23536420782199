import { forEach } from "lodash";
import { ZTableProperty } from "../../../models/ZTableProperty";
import { GetStorageSync, HasContent } from "../../../utils/common";
import { ZTableColumnProperty } from "../../../models/ZTableColumnProperty";
import { GetLocationURL } from "../../../utils/network";
import { GetFromMenu } from "../../../utils/business";


/** 获取列的宽度
 * @param {ZTableProperty} table 
 * @param {ZTableColumnProperty} column 列的配置
 * @param {Boolean} isLast 是否是最后一列
 * @returns {Number}
 */
function GetColumnWidth(table, column, isLast) {
    if (isLast)
        return undefined;
    let code = `table_scheme${GetLocationURL().pathname}\/${GetFromMenu() || ""}`,
        key = `${table.Position}${table.Field}/${column.Field}`,
        scheme = GetStorageSync(code);
    if (scheme && key in scheme)
        return scheme[key];
    return column.Width || 140;
}

/**
 * 
 * @param {ZTableProperty} table 
 * @param {import("vue").UnwrapNestedRefs<import("vxe-table").VxeGridProps>} option 
 * @returns 
 */
function useVxeTableColumn(table, option) {
    /**
    * @type {import("vxe-table").VxeColumnProps[]}
    */
    let hasSummary = false, res = [];
    if (table && table.Columns && table.Columns.length > 0) {
        if (table.Single !== true) {
            res.push({
                type: "checkbox",
                resizable: false,
                width: 30,
                align: "center",
                fixed: "left",
                editRender: { name: "z-footer" }
            });
        }
        if (table.ShowIndex !== false) {
            res.push({
                title: "序",
                type: "seq",
                resizable: false,
                width: 46,
                align: "center",
                fixed: "left",
                editRender: { name: "z-footer" }
            });
        }
        let fnConvertColumn = (column, index) => {
            if (column.Visible === false)
                return;
            /**
             * @type {import("vxe-table").VxeColumnProps}
             */
            var resColumn;
            if (HasContent(column.Children) && column.Children.length > 0) {
                var children = [];
                column.Children.forEach(x => {
                    let _ = fnConvertColumn(x, index);
                    if (HasContent(_))
                        children.push(_);
                });
                resColumn = { title: column.Label, type: null, align: "center", children, fixed: column.Fixed ? 'left' : null };
            } else {
                if (hasSummary == false && column.IsSummary)
                    hasSummary = column.IsSummary;
                let isLast;
                if (table.BlankColumn !== false)
                    isLast = false;
                else
                    isLast = index == table.Columns.length - 1;
                let field;
                if (column.Type == 'popup') {
                    if (column.ShowCode || column.OnlyName)
                        field = column.Field;
                    else
                        field = column.NameField;
                }
                else
                    field = column.Field;
                resColumn = {
                    title: column.Label, type: null, field, filters: [], sortable: true,
                    fixed: column.Fixed ? 'left' : null,
                    resizable: isLast == false, width: GetColumnWidth(table, column, isLast),
                    align: ["number", "money"].includes(column.Type) ? 'right' : 'left', headerAlign: "left",
                    params: column, filterRender: { name: "z-filter" }, editRender: { name: "z-edit" },
                    showOverflow: table.WrapLine || column.ShowLine ? false : "title"
                }
            }
            return resColumn;
        }
        forEach(table.Columns, (column, index) => {
            let resColumn = fnConvertColumn(column, index);
            if (HasContent(resColumn))
                res.push(resColumn);
        });
    }
    if (table.BlankColumn !== false)
        res.push({ type: null, resizable: false });
    if (option.showFooter != hasSummary)
        option.showFooter = hasSummary;
    return res;
}

export {
    useVxeTableColumn
}