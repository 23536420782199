<template>
    <t-input-adornment>
        <template v-if="Prepend" #prepend>
            <div v-text="Prepend.Label" @click="onClickPrepend"></div>
        </template>
        <component :is="componentType" v-bind="componentProps" v-model="inputValue" @enter="onEnter" @blur="onBlur">
        </component>
        <template v-if="Append" #append>
            <div v-text="Append.Label" @click="onClickAppend"></div>
        </template>
    </t-input-adornment>
</template>

<script setup>
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { toFn } from '../../utils/common';
import { Input, Textarea } from 'tdesign-vue-next';

const props = defineProps({
    /** 占位符 */
    PlaceHolder: String,
    /** 前置控件 */
    Prepend: Object,
    /** 后置控件 */
    Append: Object,
    /** 控件类型 */
    Type: String,
    /** 字段 */
    Field: String,
    /** 只读 */
    Readonly: { type: Boolean, default: null },
    /** 多行输入框 */
    RowSpan: { type: Number, default: null },
    /** 允许输入的最大字符数 */
    Max: { type: Number, default: null },
    /** 是否是密码 */
    IsPwd: { type: Boolean, default: null },
    /** 是否要去头部空白 */
    TrimStartSpace: { type: Boolean, default: null },
    /** 是否要去尾部空白 */
    TrimEndSpace: { type: Boolean, default: null },
    /** 回车事件 */
    EnterEvent: String,
    /** 失去焦点事件 */
    BlurEvent: String
});
const emits = defineEmits(["change", "click:prepend", "click:append"]);

const model = defineModel();
const dataModel = defineModel("data");

const inputValue = ref();

const componentType = computed(() => {
    if (isNaN(props.RowSpan) == false && Number(props.RowSpan) > 1)
        return Textarea;
    else
        return Input;
});

const componentProps = computed(() => ({
    size: "small",
    clearable: true,
    maxlength: props.Max,
    showLimitNumber: true,
    readonly: props.Readonly === true,
    type: props.IsPwd ? "password" : "text",
    placeholder: props.PlaceHolder || (props.Readonly === true ? "" : "请输入内容"),
    autosize: { minRows: props.RowSpan, maxRows: props.RowSpan }
}));

/**
 * 回车
 */
function onEnter() {
    onChange();
    toFn(props.EnterEvent)(props, dataModel.value);
}

/**
 * 失去焦点
 */
function onBlur() {
    onChange();
    toFn(props.BlurEvent)(props, dataModel.value);
}

/**
 * 值变更
 */
function onChange() {
    //只有在回车/离开的时候才触发变更，减少变更事件触发次数
    if (props.TrimStartSpace)
        model.value = model.value.trimStart();
    if (props.TrimEndSpace)
        model.value = model.value.trimEnd();
    if (model.value != inputValue.value) {
        let oldValue = model.value;
        model.value = inputValue.value;
        emits("change", model.value, oldValue);
    }
}

/**
 * 点击前置控件
 */
function onClickPrepend() {
    toFn(props.Prepend)(props, dataModel.value);
    emits("click:prepend", props, dataModel.value);
}

/**
 * 点击后置控件
 */
function onClickAppend() {
    toFn(props.Append)(props, dataModel.value);
    emits("click:append", props, dataModel.value);
}

const stopWatchs = [
    watch(() => model.value, value => {
        inputValue.value = value;
    }, { immediate: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>

<style scoped>
:deep(.t-is-readonly),
:deep(.t-is-readonly .t-input__inner) {
    cursor: not-allowed;
}
</style>