<template>
    <div class="z-pages-splitview">
        <auto-height>
            <template #header>
                <button-bar></button-bar>
            </template>
            <template #default>
                <splitter v-if="Content.length > 0" style="height: 100%; border:none;">
                    <splitter-panel v-for="(config, index) in Content" :key="config" :size="GetSplitterWidth(index)">
                        <auto-height>
                            <template #header>
                                <div class="z-pages-splitview-label">{{ config.Label }}</div>
                            </template>
                            <template #default="{ height }">
                                <div :style="{ height: height + 'px', overflowY: 'auto', }" @resizeend="onResizeEnd">
                                    <edit-panel v-bind="config" v-model="model" :Readonly="Readonly"></edit-panel>
                                </div>
                            </template>
                            <template #footer>
                                <div class="z-pages-splitview-tabar">
                                    <template v-for="btn in config.Tabar" :key="btn">
                                        <t-button size="small" @click="onClick(btn)">{{ btn.Label }}</t-button>
                                    </template>
                                </div>
                            </template>
                        </auto-height>
                    </splitter-panel>
                </splitter>
            </template>
        </auto-height>
    </div>
</template>

<script setup>
import { getCurrentInstance, onMounted } from 'vue';
import autoHeight from '../src/components/base/auto-height.vue';
import buttonBar from '../src/components/base/button-bar.vue';
import editPanel from '../src/components/base/edit-panel.vue';

import splitter from 'primevue/splitter';
import splitterPanel from 'primevue/splitterpanel';
import { useAppStore } from '../src/stores/useAppStore';
import { GetStorageSync, SetStorageAsync } from '../src/utils/common';
import { GetLocationURL } from '../src/utils/network';
import { GetFromMenu } from '../src/utils/business';

const model = defineModel();

const props = defineProps({
    pagetype: String,
    Content: { type: Array, default: [] },
    Readonly: { type: Boolean, default: null }
});

onMounted(() => {
    useAppStore().__bindInstance(getCurrentInstance());
});

function GetSplitterWidth(index) {
    let code = `page_splitter${GetLocationURL().pathname}\/${GetFromMenu() || ""}`,
        sizes = GetStorageSync(code);
    if (sizes && sizes.length > index)
        return sizes[index];
    else
        return 100 / props.Content.length;
}

/**
 * @param {*} param0 
 * @param {*} param0.originalEvent
 * @param {number[]} param0.sizes 顺序模块的宽度大小
 */
function onResizeEnd({ originalEvent, sizes }) {
    let code = `page_splitter${GetLocationURL().pathname}\/${GetFromMenu() || ""}`;
    SetStorageAsync(code, sizes);
};
</script>

<style scoped>
.z-pages-splitview {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #f5f5f5;
}

.z-pages-splitview-label {
    padding: 12px 0 0;
    text-align: center;
}

.z-pages-splitview-tabar {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    min-height: 44px;
    border-top: 1px solid var(--td-component-border);
}

.z-pages-splitview-tabar .t-button+.t-button {
    margin-left: 8px;
    margin-top: 4px;
}
</style>