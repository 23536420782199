import { defineStore } from "pinia";
import { useConfirm } from "primevue/useconfirm";

export const useDialogStore = defineStore("dialog", {
    state: () => ({
        /**
         * @type {{
         *  require: (option: import("primevue/confirmationoptions").ConfirmationOptions) => void;
         *  close: () => void;
         * }}
         */
        PrimePopupConfirm: null,

        /**
         * @type {{id: string, result: object, callback: ()=>void}[] || import("@arco-design/web-vue").ModalReturn[]}
         */
        dialogs: [],
        /**
         * @type {{id: string, result: object, callback: ()=>void}[] || import("@arco-design/web-vue").ModalReturn[]}
         */
        formDialogs: [],
        /**
         * @type {{id: string, callback:()=>void}[] || import("@arco-design/web-vue").DrawerReturn[]}
         */
        pages: []
    }),
    actions: {
        /** 使用 PrimePopupConfirm */
        usePrimePopupConfirm() {
            this.PrimePopupConfirm = useConfirm();
        }
    }
});