import {
    HttpGet,
    HttpGetAsync,
    DownLoadByGet,
    DownLoadByGetAsync,
    HttpPost,
    HttpPostAsync,
    HttpPostForJson,
    HttpPostForJsonAsync,
    DownLoadByPost,
    DownLoadByPostAsync,
    DownLoadByPostForJson,
    DownLoadByPostForJsonAsync,
    HttpUpload,
    AsyncLoadInfo
} from "../utils/network";

/** 网页信息 */
window.PageInfo = {
    AsyncLoadInfo: AsyncLoadInfo
};

window.HttpGet = HttpGet;
window.HttpGetAsync = HttpGetAsync;
window.DownLoadByGet = DownLoadByGet;
window.DownLoadByGetAsync = DownLoadByGetAsync;
window.HttpPost = HttpPost;
window.HttpPostAsync = HttpPostAsync;
window.HttpPostForJson = HttpPostForJson;
window.HttpPostForJsonAsync = HttpPostForJsonAsync;
window.DownLoadByPost = DownLoadByPost;
window.DownLoadByPostAsync = DownLoadByPostAsync;
window.DownLoadByPostForJson = DownLoadByPostForJson;
window.DownLoadByPostForJsonAsync = DownLoadByPostForJsonAsync;
window.HttpUpload = HttpUpload;