<template>
    <grid-layout v-model:layout="layouts" :col-num="count" :row-height="rowHeight" :is-draggable="draggable"
                 :is-resizable="resizable" :margin="margin">
        <grid-item v-for="item in layouts" :x="item.x" :y="item.y" :w="item.w" :h="item.h"
                   :i="item.i" :key="item.i" drag-allow-from=".drag-move" drag-ignore-from=".drag-none"
                   @resized="onResized">
            <slot v-bind="{ index: item.i, cell: model[item.i] }"></slot>
        </grid-item>
    </grid-layout>
</template>

<script setup>
import { isArray } from "lodash";
import { onBeforeUnmount, reactive, watch } from "vue";

const model = defineModel({ default: [] });
const props = defineProps({
    count: { type: Number, default: 12 },
    rowHeight: { type: Number, default: 30 },
    draggable: { type: Boolean, default: false },
    resizable: { type: Boolean, default: false },
    margin: { type: Array, default: [10, 10] },
    widthField: { type: String, default: "ColSpan" },
    heightField: { type: String, default: "RowSpan" }
});
const emits = defineEmits(["resized"]);

const layouts = reactive([]);

/** 大小变更事件 */
function onResized() {
    emits("resized", ...arguments);
}

/** 计算布局
 * @param {Array} value
 */
function calcuteLayouts(value) {
    if (isArray(value)) {
        if (value.length != layouts.length) {
            let arr = [], x = 0;
            value.forEach((value, index) => {
                let layout = {
                    i: index,
                    w: value[props.widthField] || 1,
                    h: value[props.heightField] || 1
                };
                //不允许超宽
                if (layout.w > props.count) {
                    layout.w = props.count;
                }
                //不够放，切到下一行
                if (x + layout.w > props.count) {
                    layout.x = 0;
                    x = layout.w;
                }
                else {
                    layout.x = x;
                    x = layout.x + layout.w;
                    if (x == props.count) {
                        x = 0;
                    }
                }
                let y = 0, count = layout.x + layout.w;
                for (let i = layout.x; i <= count; i++) {
                    if (arr.length > i) {
                        if (arr[i] > y) {
                            y = arr[i];
                        }
                        arr[i] += layout.h;
                    } else {
                        arr.push(layout.h);
                    }
                }
                layout.y = y;
                layouts.push(layout);
            });
        }
    }
}

const stopWatchs = [
    watch(() => model.value, calcuteLayouts, { immediate: true })
];

onBeforeUnmount(() => {
    stopWatchs.forEach(stop => stop());
});
</script>
