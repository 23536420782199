import { defineStore } from "pinia";
import { HasContent, RegisterEvent } from "../utils/common";
import { ViteSetting } from "../config/vitesetting";
import { parse } from "qs";
import { HttpPost } from "../utils/network";
import { PageConfig } from "../config/pageconfig";
import { nextTick } from "vue";
import { LoadConfig } from "../utils/business";
import { Alert } from "../utils/dialog";
import { useDialogStore } from "./useDialogStore";
import { VXETable } from "vxe-table";
import { useNetworkStore } from "./useNetworkStore";
import { useMenuScore } from "./useMenuStore";

export const useAppStore = defineStore("app", {
    state: () => ({
        config: {},
        data: {},
        pagetype: "",
        v3: true,
        /**
         * @type {import("vue").ComponentInternalInstance}
         */
        __instance: null,
        javascripts: [],
        stylesheets: [],
        /** 页面是否加载完毕
         * @type {boolean}
         */
        isLoaded: false,
        /** 相机 */
        camera: {},
        /** 页面样式 ID */
        scheme: PageConfig.SchemeID,
        network: useNetworkStore()
    }),
    getters: {
        refs() {
            return this.__instance.refs || {};
        }
    },
    actions: {
        /** 绑定实例
         * @param {import("vue").ComponentInternalInstance} instance
         */
        __bindInstance(instance) {
            if (instance && this.__instance == null)
                this.__instance = instance;
        },
        __init() {
            const fn = () => {
                this.config = PageConfig.Config || {};
                this.data = PageConfig.Data || {};
                RegisterEvent(window, "PageLoad", () => {
                    LoadConfig(true, undefined, undefined, () => this.isLoaded = true);
                    if (HasContent(PageConfig.Data) == false && HasContent(PageConfig.DataUrl))
                        HttpPost(PageConfig.DataUrl, {}, res => Object.assign(this.data, res.data));
                    if (HasContent(PageConfig.LoadMessage))
                        Alert(PageConfig.LoadMessage, "warning");
                });
            };
            if (HasContent(PageConfig.PageType)) {
                this.pagetype = PageConfig.PageType;
                nextTick(fn)
            } else if (ViteSetting.IsApp) {
                let page;
                if (location.search.length > 0)
                    page = parse(location.search.substring(1)).app;
                else
                    page = "/Login/Index";
                if (page) {
                    HttpPost(page, {}, res => {
                        Object.entries(res.data).forEach(([key, value]) => {
                            switch (key) {
                                case "JavaScripts":
                                    value = [
                                        "/Vue/UEditor/ueditor.config.js",
                                        "/Vue/UEditor/ueditor.all.min.js",
                                        "/Vue/UEditor/lang/zh-cn/zh-cn.js",
                                        "/Vue/business.js",
                                        "/Vue/com.js",
                                        ...value
                                    ];
                                    this.javascripts.push(...value.map(js => `${ViteSetting.ApiPrefix}${js}?v=${Date.now()}`));
                                case "StyleSheets":
                                    this.stylesheets.push(...value.map(css => `${ViteSetting.ApiPrefix}${css}?v=${Date.now()}`));
                                    break;
                                default:
                                    window[`$${key}`] = value;
                                    break;
                            }
                        });
                        this.pagetype = PageConfig.PageType;
                        //延迟加载，等待脚本加载
                        setTimeout(fn, 500);
                    });
                }
            }
        },
        $set(object, field, value) {
            object[field] = value;
        },
        AddCamera(name, camera) {
            this.camera[name] = camera;
        },
        RemoveCamera(name) {
            delete this.camera[name];
        },
        CanClosePage() {
            return useDialogStore().pages.length > 0;
        },
        ClosePage(callback) {
            let pages = useDialogStore().pages;
            if (pages.length > 0) {
                let page = pages[pages.length - 1];
                page.callback = callback;
                page.close();
            }
        },
        CloseDialog(res, callback) {
            let dialogs = useDialogStore().dialogs;
            if (dialogs.length > 0) {
                let dialog = dialogs[dialogs.length - 1];
                dialog.result = res;
                dialog.callback = callback;
                dialog.close();
            }
        },
        CloseFormDialog(res, callback) {
            let dialogs = useDialogStore().formDialogs;
            if (dialogs.length > 0) {
                let dialog = dialogs[dialogs.length - 1],
                    instance = VXETable.modal.get(dialog.id);
                if (instance)
                    instance.dispatchEvent("close", { ...dialog, result: res, callback });
            }
        },
        OpenTab(menuid) {
            useMenuScore().openMenuTab(menuid);
        },
        CloseTab(menuid) {
            useMenuScore().closeMenuTab(menuid);
        }
    }
});