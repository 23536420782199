<template>
    <div :class="{ 'z-modal-header': true, 'single': single }" :style="{ 'min-width': minWidth || '' }">
        <div class="z-modal-header-title">
            <component :is="typeIcon"></component>
            <span v-text="title"></span>
        </div>
        <div class="z-modal-header-extra">
            <template v-if="maximize">
                <FullscreenExitIcon v-if="modal.isMaximized()" class="z-icon-button" size="16" @click="onMaximize" />
                <FullscreenIcon v-else size="16" class="z-icon-button" @click="onMaximize" />
            </template>
            <CloseIcon v-if="closable" :class="{ 'z-icon-button': true, 'z-icon-close': true, 'single': single }"
                       size="20" @click="onClose" />
        </div>
    </div>
</template>

<script setup>
import { CheckCircleFilledIcon, CloseCircleFilledIcon, CloseIcon, ErrorCircleFilledIcon, FullscreenIcon, FullscreenExitIcon, HelpCircleFilledIcon, InfoCircleFilledIcon, LoadingIcon } from 'tdesign-icons-vue-next';
import { computed, h } from 'vue';

const typeIcon = computed(() => {
    switch (props.type) {
        case "success":
            return h(CheckCircleFilledIcon, { size: "20", style: { color: "var(--green-500)" } });
        case "warning":
            return h(HelpCircleFilledIcon, { size: "20", style: { color: "var(--yellow-500)" } });
        case "danger":
        case "error":
            return h(CloseCircleFilledIcon, { size: "20", style: { color: "var(--red-500)" } });
        case "info":
            return h(ErrorCircleFilledIcon, { size: "20", style: { color: props.single ? "var(--primary-500)" : "var(--color-white)" } });
        case "loading":
            return h(LoadingIcon, { size: "20", style: { color: props.single ? "var(--primary-500)" : "var(--color-white)" } });
        case "default":
        default:
            return h(InfoCircleFilledIcon, { size: "20", style: { color: props.single ? "var(--primary-500)" : "var(--color-white)" } });
    }
});

const btnOptions = {
    block: true,
    shape: "circle",
    size: "small",
    style: {
        width: "24px"
    }
}

/**
 * @type {{
 *  type: "default" | "success" | "warning" | "danger" | "info" | "error",
 *  title: string,
 *  modal: import("vxe-table").VxeModalInstance,
 *  single: Boolean,
 * }}
 */
const props = defineProps({
    single: { type: Boolean, default: null },
    type: String,
    title: String,
    modal: Object,
    closable: { type: Boolean, default: null },
    maximize: { type: Boolean, default: null },
    minWidth: { type: String, default: null }
});

function onClose() {
    props.modal.fullscreen
    props.modal.dispatchEvent("close");
}

function onMaximize() {
    if (props.modal.isMaximized())
        props.modal.revert();
    else
        props.modal.maximize();
}
</script>

<style scoped>
.z-modal-header {
    width: 100%;
    padding: 4px 8px;
    border-top-left-radius: var(--vxe-border-radius);
    border-top-right-radius: var(--vxe-border-radius);
    display: flex;
    height: 32px;
}

.z-modal-header:not(.single) {
    background-color: var(--primary-color);
    border-bottom: 1px solid var(--color-white);
}

.z-modal-header:not(.single) .z-modal-header-title {
    background-color: var(--primary-color);
    color: var(--color-white);
}

.z-modal-header.single {
    background-color: transparent;
}

.z-modal-header-title {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    max-width: 100%;
}

.z-modal-header-title>span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.z-modal-header-extra {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.z-modal-header-title>* {
    margin-right: 4px;
}

.z-icon-button {
    cursor: pointer;
    border-radius: 2px;
}

.z-icon-close:not(.single) {
    color: var(--color-white);
}

.z-icon-close:not(.single):hover {
    background-color: var(--red-500);
}

.z-icon-close.single {
    color: var(--red-500);
}

.z-icon-close.single:hover {
    color: var(--color-white);
    background-color: var(--red-500);
}
</style>