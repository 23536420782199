<template>
    <div class="z-frames" ref="crxContent"></div>
</template>

<script setup>
import { ref } from 'vue';
import { ViteSetting } from '../../config/vitesetting';
import { HasContent, IsNullOrEmpty } from '../../utils/common';
import { CreateURL } from '../../utils/network';

/**
 * 框架容器
 * @type {import('vue').Ref<HTMLDivElement>}
 */
const crxContent = ref(null);

/**
 * 框架集合
 * @type {{...String}}}
 */
const iframes = {};
const currentURI = ref("");

defineExpose({
    /**
     * 打开框架
     * @param {string} title 页面标题
     * @param {string} uri 页面地址
     * @param {boolean} single 只有单个页面
     * @param {object} query 页面参数
     */
    open(title, uri, single, query) {
        if (IsNullOrEmpty(uri) || uri == currentURI.value)
            return false;
        if (single) {
            if (HasContent(iframes.single)) {
                iframes.single.name = title + Date.now();
                if (ViteSetting.IsApp)
                    iframes.single.src = `?app=${encodeURIComponent(CreateURL(uri, query, false))}`;
                else
                    iframes.single.src = CreateURL(uri, query, false);
            } else {
                let frame = document.createElement("iframe");
                frame.name = title + Date.now();
                if (ViteSetting.IsApp)
                    frame.src = `?app=${encodeURIComponent(CreateURL(uri, query, false))}`;
                else
                    frame.src = CreateURL(uri, query, false);
                frame.frameBorder = 0;
                iframes.single = frame;
                crxContent.value.appendChild(frame);
            }
        } else {
            if (currentURI.value in iframes)
                iframes[currentURI.value].style.display = "none";
            currentURI.value = uri;
            if (uri in iframes)
                iframes[uri].style.display = "block";
            else {
                let frame = document.createElement("iframe");
                frame.name = title + Date.now();
                if (ViteSetting.IsApp)
                    frame.src = `?app=${encodeURIComponent(CreateURL(uri, query, false))}`;
                else
                    frame.src = CreateURL(uri, query, false);
                frame.frameBorder = 0;
                iframes[uri] = frame;
                crxContent.value.appendChild(frame);
            }
        }
    },
    /**
     * 关闭框架
     * @param {string} uri 页面地址
     */
    close(uri) {
        if (IsNullOrEmpty(uri))
            return false;
        if (uri in iframes) {
            iframes[uri].src = "about:blank";
            iframes[uri].remove();
            delete iframes[uri];
        }
    }
});
</script>

<style scoped>
.z-frames {
    height: 100%;
}
</style>