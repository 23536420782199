import axios from "axios";
import { isArray, isObject } from "lodash";
import { parse, stringify } from "qs";
import { setting } from "../config/appsetting";
import { HasContent, IsNullOrEmpty, ParseParameters, RegexMatch, toFn, toPromise } from "./common";
import { Alert, AlertExtend, Confirm, ConfirmExtend, Loading } from "./dialog";
import { useNetworkStore } from "../stores/useNetworkStore";
import { ViteSetting } from "../config/vitesetting";

const regconfirm = /^confirm_.{32}$/;
const regconfirmExtend = /^confirm_extend_.{32}$/;

//前后端分离，使用API_SERVER调用后端接口
if (ViteSetting.ApiServer) {
    axios.defaults.baseURL = ViteSetting.ApiPrefix;
}

/** 请求拦截器 */
axios.interceptors.request.use(function (config) {
    config.metadata = {
        startTime: Date.now(),
        $ignore: config.params.$ignore
    };
    delete config.params.$ignore;
    return config;
});

/** 响应拦截器 */
axios.interceptors.response.use(function (response) {
    if (response.headers.service_execute_time) {
        let total = Date.now() - response.config.metadata.startTime,
            exec = Number(response.headers.service_execute_time);
        useNetworkStore().updateTime(total, exec);
    }
    return response;
}, function (e) {
    if (e.response) {
        return ThrowResponceError(e.response);
    }
    Alert(e.message, "error");
    return e;
});

/** 获取错误信息
 * @param {import("axios").AxiosResponse} response 
 */
function GetErrorMessage(response) {
    switch (response.status) {
        case 404:
            return `服务器开小差了！<br/>${response.request.responseURL} 请求无响应！`;
        default:
            if (response.data.message) {
                return response.data.message;
            }
            if (response.data.err_msg) {
                return response.data.err_msg;
            }
            return null;
    }
}

/** 抛出Response错误
 * @param {import("axios").AxiosResponse} response 
 */
function ThrowResponceError(response) {
    let message = GetErrorMessage(response);
    //开发环境有堆栈，终端显示警告
    if (response.data && response.data.StackTrace) {
        console.warn(`接口响应错误：${message}\nStackTrace：\n${response.data.StackTrace}`);
    }
    Alert(message, "error", () => {
        if (message == "请先登录！")
            location.href = "/";
    });
}

function http(url, headers, query, body, success, fail, complete) {
    if (IsNullOrEmpty(url)) {
        Alert("请求异常，未找到业务地址！", "error", () => {
            toFn(fail)(new Error("请求异常，未找到业务地址！"));
            toFn(complete)();
        });
        return;
    }
    //APP环境，要调整URL
    if (ViteSetting.IsApp && url.length > 0 && url.substring(0, 1) != "/") {
        let _uri = GetLocationURL();
        if (_uri.pathname != "/")
            url = _uri.pathname.replace(/\/\w+\?|\/\w+$/, `/${url}`);
    }
    let $ignore = false;
    if (query && "$ignore" in query) {
        $ignore = true;
        delete query.$ignore;
    }
    if (body && "$ignore" in body) {
        $ignore = true;
        delete body.$ignore;
    }
    let reqFn = function (header) {
        axios.request({
            url: ParseParameters(url),
            method: HasContent(body) ? "POST" : "GET",
            headers: {
                ...header,
                ...headers
            },
            responseType: "json",
            params: useNetworkStore().getBaseQuery({ ...query, $ignore }),
            paramsSerializer: {
                serialize(params) {
                    return stringify(params, { arrayFormat: "repeat" });
                }
            },
            data: body,
            transformRequest: [function (data, headers) {
                if (data instanceof FormData) {
                    return data;
                }
                else if (typeof data == "object") {
                    if (headers["Content-Type"] == "application/x-www-form-urlencoded;charset=UTF-8") {
                        return stringify(data, { arrayFormat: "indices", allowDots: true });
                    } else {
                        //去掉不必要的null，减少数据传输内容
                        return JSON.stringify(data, (key, value) => HasContent(value) ? value : undefined);
                    }
                } else {
                    return data;
                }
            }],
            onUploadProgress(e) {
            },
            onDownloadProgress(e) {
            }
        }).then(response => {
            let todo = () => {
                let next = CheckRequest(response, reqFn, success, fail, complete);
                if (next === true) {
                    HttpSuccess(response, success);
                    toFn(complete)();
                    return;
                }
                if (next === "confirm" || next === "info") {
                    return;
                }
                if (next === "async") {
                    return AsyncRequest(headers["Response-Type"] == "blob" ? "file" : "base", response.data.code, null, success, fail, complete);
                }
                toFn(fail)(response);
                toFn(complete)();
            };
            if ("response__message" in response.headers) {
                Alert(decodeURIComponent(response.headers.response__message), "info", todo);
            }
            else {
                todo();
            }
        }).catch(error => {
            toFn(fail)(error);
            toFn(complete)();
        });
    };
    reqFn();
}

/** 校验请求
 * @param {import("axios").AxiosResponse} response 
 * @param {function(import("axios").AxiosHeaders)} redo 
 * @param {function(import("axios").AxiosResponse)} success 
 * @param {function()} complete 
 * @returns {boolean|string} 返回校验结果
 */
function CheckRequest(response, redo, success, complete) {
    if (response.data.err_code) {
        //清单式错误信息
        if (response.data.err_code == "FAIL_SHOW_TABLE") {
            AlertExtend(GetErrorMessage(response), "error", JSON.parse(response.data.err_param));
            return false;
        }
        if (response.data.err_code == "FAIL_INFO") {
            Alert(GetErrorMessage(response), "warning", () => {
                if ("Result" in response.data) {
                    Object.assign(response.data, response.data.Result);
                    delete response.data.Result;
                }
                toFn(success)(response);
            });
            return "info";
        }
        //校验confirm请求
        let mIndex = RegexMatch(response.data.err_code, regconfirm, regconfirmExtend);
        if (mIndex > -1) {
            let option = JSON.parse(response.data.err_param);
            if (option.discernClose) {
                option.confirmText = "是";
                option.cancelText = "否";
            }
            [Confirm, ConfirmExtend][mIndex](GetErrorMessage(response), function (e) {
                let headers = {};
                if (e.confirm) {
                    headers[response.data.err_code] = true;
                    redo(headers);
                } else if (e.cancel && option.discernClose) {
                    //区分取消时，选否会重新调用原来方法，关闭则不会。不区分时，取消时不会重新调用方法
                    headers[response.data.err_code] = false;
                    redo(headers);
                } else {
                    toFn(complete)();
                }
            }, option);
            return "confirm";
        }
        ThrowResponceError(response);
        return false;
    }
    //错误响应
    if (response.data.state == "error") {
        ThrowResponceError(response);
        return false;
    }
    //异步请求任务，请求切片
    if (response.data.state == "async") {
        return "async";
    }
    //重载返回结果
    if (typeof response.data == "object" && "Result" in response.data) {
        Object.assign(response.data, response.data.Result);
        delete response.data.Result;
    }
    return true;
}

/** 成功回调
 * @param {import("axios").AxiosResponse} response 
 * @param {(res: import("axios").AxiosResponse) => void} success
 */
function HttpSuccess(response, success) {
    if (response.data.state == "success" && response.data.message) {
        //回调中包含Alert，底层不再调用Alert，防止重复提示/批量回调提示
        if (response.config.metadata.$ignore || String(success).includes("Alert")) {
            if (success)
                success(response);
        }
        else {
            Alert(response.data.message, "success", function () {
                if (success)
                    success(response);
            });
        }
    } else if (success) {
        success(response);
    }
}

function AsyncRequest(type, code, loading, success, fail, complete) {
    loading = loading || Loading("正在执行操作");
    /** 递归调用 */
    let fnRecurs = () => {
        //0.8s询问一次
        setTimeout(() => {
            AsyncRequest(type, code, loading, success, fail, complete);
        }, 800);
    }
    HttpGet("/Vue/AsyncGetMessage", { code, close: type != "file" }, response => {
        switch (response.data.AsyncState) {
            case 0: //正常运行
                if (response.data.Message) {
                    loading.text = response.data.Message;
                }
                fnRecurs();
                break;
            case 1: //成功响应
                switch (type) {
                    case "file":
                        {
                            //文件类型需要重新获取文件流
                            let fCount = response.data.Length,
                                fnDownload = i => {
                                    let dom = document.createElement("a");
                                    if (ViteSetting.IsApp)
                                        dom.href = `${ViteSetting.ApiPrefix}/Vue/AsyncGetFile?code=${code}&index=${i}/`;
                                    else
                                        dom.href = `/Vue/AsyncGetFile?code=${code}&index=${i}`;
                                    dom.download = "download";
                                    dom.click();
                                };
                            if (fCount == 1) {
                                fnDownload(0);
                            } else {
                                for (let i = 0; i < fCount; i++) {
                                    fnDownload(i + 1);
                                }
                            }
                            toFn(success)();
                            toFn(complete)();
                            loading.close();
                        }
                        break;
                    case "base":
                    default:
                        //普通类型请求，直接返回响应结果
                        response.data = response.data.AsyncResult;
                        HttpSuccess(response, success);
                        toFn(complete)();
                        loading.close();
                        break;
                }
                break;
            case 2: //等待响应
                let fn,
                    option = {},
                    discernClose = response.data.DiscernClose;
                if (discernClose) {
                    option.confirmText = "是";
                    option.cancelText = "否";
                }
                if (response.data.TableInfo) {
                    fn = ConfirmExtend;
                    Object.assign(option, response.data.TableInfo);
                } else
                    fn = Confirm;
                fn(response.data.Question, e => {
                    if (e.confirm) {
                        HttpGet("/Vue/AsyncSetAnswer", { code, answer: true });
                        fnRecurs();
                    } else if (e.cancel && discernClose) {
                        //区分取消时，选否会重新调用原来方法，关闭则不会。不区分时，取消不会重新调用方法
                        HttpGet("/Vue/AsyncSetAnswer", { code, answer: false });
                        fnRecurs();
                    } else {
                        //关闭时，异步的任务无法中断，导致事务无法提交等等。异步资源占用问题，需要返回后台
                        HttpGet("/Vue/AsyncSetAnswer", { code });
                        toFn(complete)();
                        loading.close();
                    }
                }, option);
                break;
            default:    //未知状态
                break;
        }
    }, error => {
        toFn(fail)(error);
        toFn(complete)();
        loading.close();
    });
}

/** HTTP GET请求
 * @param {(res: import("axios").AxiosResponse) => void} success 
 * @param {(err: Error) => void} fail 
 * @param {() => void} complete 
 */
function HttpGet(url, query, success, fail, complete) {
    http(url, {}, query, undefined, success, fail, complete);
}

/** HTTP GET下载
 * @param {(res: import("axios").AxiosResponse) => void} success 
 * @param {(err: Error) => void} fail 
 * @param {() => void} complete 
 */
function DownLoadByGet(url, query, success, fail, complete) {
    http(url, { "Response-Type": "blob" }, query, undefined, success, fail, complete);
}

/** HTTP POST请求【表单】
 * @param {(res: import("axios").AxiosResponse) => void} success 
 * @param {(err: Error) => void} fail 
 * @param {() => void} complete 
 */
function HttpPost(url, body, success, fail, complete) {
    let headers;
    if (body instanceof FormData)
        headers = { "Content-Type": "multipart/form-data;charset=UTF-8" };
    else
        headers = { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" };
    http(url, headers, undefined, body, success, fail, complete);
}

/** HTTP POST下载【表单】
 * @param {(res: AxiosResponse) => void} success 
 * @param {(err: Error) => void} fail 
 * @param {() => void} complete 
 */
function DownLoadByPost(url, body, success, fail, complete) {
    let headers;
    if (body instanceof FormData)
        headers = { "Content-Type": "multipart/form-data;charset=UTF-8", "Response-Type": "blob" };
    else
        headers = { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8", "Response-Type": "blob" };
    http(url, headers, undefined, body, success, fail, complete);
}

/** HTTP POST请求【JSON】
 * @param {(res: import("axios").AxiosResponse) => void} success 
 * @param {(err: Error) => void} fail 
 * @param {() => void} complete 
 */
function HttpPostForJson(url, body, success, fail, complete) {
    http(url, { "Content-Type": "application/json;charset=UTF-8" }, undefined, body, success, fail, complete);
}

/** HTTP POST下载【JSON】
 * @param {(res: import("axios").AxiosResponse) => void} success 
 * @param {(err: Error) => void} fail 
 * @param {() => void} complete 
 */
function DownLoadByPostForJson(url, body, success, fail, complete) {
    http(url, { "Content-Type": "application/json;charset=UTF-8", "Response-Type": "blob" }, undefined, body, success, fail, complete);
}

const fileEx = /[^\.]*$/;
/** HTTP 文件上传
 * @param {string} url 地址
 * @param {boolean} ismult 是否多文件
 * @param {object} body 表单
 * @param {(dom: HTMLInputElement) => boolean} check 文件检查
 * @param {(res: import("axios").AxiosResponse) => void} success 成功回调
 * @param {(err: Error) => void} fail 失败回调
 * @param {() => void} complete 完成回调
 * @author anturin
 */
function HttpUpload(url, ismult, body, check, success, fail, complete) {
    let dom = document.createElement("input"),
        type = (HasContent(body) ? body.source : undefined) || "file",
        fnChange = function () {
            dom.removeEventListener("change", fnChange);
            if (this.files && this.files.length > 0) {
                for (let i = 0; i < this.files.length; i++) {
                    let file = this.files[i],
                        ex = file.name.match(fileEx);
                    if (setting.component.file.disAllow.includes(ex)) {
                        Alert(`不允许上传【${setting.component.file.disAllow}】格式的文件！`, "error");
                        toFn(fail)(Error("非法文件格式！"));
                        fnComplete();
                        return;
                    }
                    if (type != "file" && setting.component.file.allow[type].includes(ex)) {
                        Alert(`只允许上传【${setting.component.file.allow[type]}】格式的文件！`, "error");
                        toFn(fail)(Error("文件格式不正确！"));
                        fnComplete();
                        return;
                    }
                }
                let next = toFn(check)(this);
                if (next !== false) {
                    try {
                        let form = ParseFormData(body);
                        for (let i = 0; i < this.files.length; i++) {
                            form.append("file", this.files[i]);
                        }
                        HttpPost(url, form, success, fail, fnComplete);
                    } catch (err) {
                        fail(err);
                        fnComplete();
                    }
                    return;
                }
            }
            fnComplete();
        },
        fnCancel = function () {
            toFn(fail)(Error("用户已取消上传！"));
            fnComplete();
        },
        fnComplete = function () {
            if (dom)
                dom.removeEventListener("cancel", fnCancel);
            setTimeout(() => dom = null, 1000);
            toFn(complete)();
        };
    dom.addEventListener("change", fnChange);
    dom.addEventListener("cancel", fnCancel);
    dom.type = "file";
    dom.multiple = ismult;
    dom.click();
}

/** 解析为FormData
 * @returns {FormData}
 */
function ParseFormData(body, form, prefix) {
    form = form || new FormData();
    if (HasContent(body)) {
        Object.entries(body).forEach(([key, value]) => {
            if (HasContent(value)) {
                let fKey = HasContent(prefix) ? `${prefix}[${key}]` : key;
                if (typeof value == "object")
                    ParseFormData(value, form, fKey);
                else
                    form.append(fKey, value);
            }
        });
    }
    return form;
}

/** 根据数据源构建Form表单
 * @param {object} data 数据源
 * @param {HTMLFormElement} form 表单
 * @returns {HTMLFormElement} 表单
 */
function CreateFormByData(data, form) {
    form = form || document.createElement("form");
    if (typeof data == "object") {
        let inputs = [],
            input = document.createElement("input"),
            fn = function (prop, value) {
                if (value === undefined || value === null)
                    return;
                if (isArray(value)) {
                    value.forEach((value, index) => fn(`${prop}[${index}]`, value));
                    return;
                }
                if (isObject(value)) {
                    Object.entries(value).forEach(([key, value]) => fn(`${prop}[${key}]`, value));
                    return;
                }
                input.name = prop;
                input.value = value;
                inputs.push(input.outerHTML);
            };
        input.type = "hidden";
        Object.entries(data).forEach(([key, value]) => fn(key, value));
        form.innerHTML = inputs.join("\n");
    }
    return form;
}

/** 构建URL
 * @param {string} uri 基础URL
 * @param {object} param 参数
 * @param {boolean} encode 参数是否需要编码
 * @param {*} paramCode 内部参数是否需要先编码
 * @returns {string} 返回URL
 */
function CreateURL(uri, param, encode, paramCode) {
    let strParam = "";
    switch (typeof param) {
        case "string":
            strParam = param;
            break;
        case "object":
            let fn = function (prop, value) {
                if (value === undefined || value === null)
                    return;
                if (isArray(value)) {
                    value.forEach((value, index) => fn(`${prop}[${index}]`, value));
                    return;
                }
                if (isObject(value)) {
                    Object.entries(value).forEach(([key, value]) => fn(`${prop}[${key}]`, value));
                    return;
                }
                strParam += `${prop}=${paramCode === false ? value : encodeURIComponent(value)}&`;
            };
            Object.entries(param).forEach(([key, value]) => fn(key, value));
            break;
        default:
            return uri;
    }
    return `${uri}${uri.includes("?") ? "&" : "?"}${encode !== true ? strParam : encodeURIComponent(strParam)}`;
}

/** 获取本地的Location的URL
 * @returns {URL} 
 */
function GetLocationURL() {
    let uri;
    if (ViteSetting.IsApp) {
        let app;
        if (location.search.length > 0)
            app = parse(location.search.substring(1)).app;
        if (HasContent(app))
            uri = new URL(decodeURIComponent(app), location.origin);
    }
    return uri || new URL(location);
}

const HttpGetAsync = toPromise(HttpGet);
const DownLoadByGetAsync = toPromise(DownLoadByGet);
const HttpPostAsync = toPromise(HttpPost);
const HttpPostForJsonAsync = toPromise(HttpPostForJson);
const DownLoadByPostAsync = toPromise(DownLoadByPost);
const DownLoadByPostForJsonAsync = toPromise(DownLoadByPostForJson);
const HttpUploadAsync = toPromise(HttpUpload);

/** 异步加载任务 */
const AsyncLoadInfo = {};

/** 判断异步加载任务是否全部结束 */
function ValidateAsyncLoadInfo(isAlert) {
    isAlert = isAlert !== false;
    //判断页面加载是否完毕（子表或者部分异步取数字段）
    for (var key in AsyncLoadInfo) {
        var state = AsyncLoadInfo[key];
        if (state == 1) {
            if (isAlert)
                Alert("页面未加载完毕，请稍后再操作！", "error");
            return false;
        } else if (state == 3) {
            if (isAlert)
                Alert("页面部分数据加载失败，请重新刷新后操作！", "error");
            return false;
        }
    }
    return true;
}

export {
    HttpGet,
    HttpGetAsync,
    DownLoadByGet,
    DownLoadByGetAsync,
    HttpPost,
    HttpPostAsync,
    HttpPostForJson,
    HttpPostForJsonAsync,
    DownLoadByPost,
    DownLoadByPostAsync,
    DownLoadByPostForJson,
    DownLoadByPostForJsonAsync,
    HttpUpload,
    HttpUploadAsync,
    CreateURL,
    CreateFormByData,
    AsyncLoadInfo,
    ValidateAsyncLoadInfo,
    GetLocationURL
}