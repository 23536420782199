<template>
    <span :class="RenderRequired(table, column)">
        <tip v-bind="column.params.Tip">
            <span v-text="column.title"></span>
        </tip>
        <span v-if="column.order" :class="`tur-sort tur-icon-sort${column.order}`"></span>
    </span>
</template>

<script setup>
import { RegisterEvent } from "../../../utils/common";
import tip from "../../base/tip.vue";

/**
 * @type {{
 *  table: import("vxe-table").VxeTableInstance,
 *  column: import("vxe-table").VxeColumnProps,
 *  columnIndex: Number,
 *  rowIndex: Number,
 *  fixed: Boolean,
 *  hasFilter: Boolean,
 *  isHidden: Boolean
 * }}
 */
const props = defineProps({
    table: Object,
    column: Object,
    columnIndex: { type: Number, default: null },
    rowIndex: { type: Number, default: null },
    fixed: { type: Boolean, default: null },
    hasFilter: { type: Boolean, default: null },
    isHidden: { type: Boolean, default: null }
});

/**
 * 获取列是否必填
 * @param {import("vxe-table").VxeTableInstance} table 
 * @param {import("vxe-table").VxeColumnProps} column 
 */
function RenderRequired(table, column) {
    let required = column.params.Required;
    if (!required)
        required = RegisterEvent(props.table, "TableRenderRequired", x => false, table.props.params, column.params);
    return {
        "z-cell--title": true,
        "vxe-cell--required": required
    };
}
</script>

<style scoped>
.tur-sort {
    color: var(--t-color-primary);
    position: relative;
    top: 1px;
}

.z-cell--title {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.z-cell--title.vxe-cell--required span::before {
    content: "*";
    color: var(--red-500);
    padding: 0 1px;
}
</style>