import { forEach } from "lodash";
import { ZTableProperty } from "../../../models/ZTableProperty";
import { RegisterEvent } from "../../../utils/common";
import { getCurrentInstance } from "vue";

/** 转换按钮
 * @param {Object} button 按钮设置
 * @param {String} align 位置，默认左侧 left/right
 */
function ConvertButton(button, align) {
    if (align == "right")
        return { ...button, align: "right", buttonRender: { name: "z-tabar" } };
    else
        return { ...button, align: "left", buttonRender: { name: "z-tabar" } };
}

/**
 * 
 * @param {ZTableProperty} table 
 * @param {boolean} readonly 
 */
export function useVxeTableButton(table, readonly) {
    var buttons = [];
    forEach(table.Tabar, button => {
        if (readonly === true) {
            //修改数据行的按钮，只读模式不显示
            if (["AddRow", "CopyRow", "DeleteRow"].includes(button.Action))
                return;
        }
        let visible = RegisterEvent(getCurrentInstance(), "TableTabarRenderVisible", () => true, table, button);
        if (visible)
            buttons.push(ConvertButton(button));
    });
    if (table.Export !== false) {
        buttons.push(ConvertButton({ Label: "导出", Action: "$export" }));
    }
    if (readonly !== true) {
        if (table.Import && table.PageMode === false)
            buttons.push(ConvertButton({ Label: "导入", Action: "$import" }));
        if (table.BatchEdit !== false)
            buttons.push(ConvertButton({ Label: "批量修改", Action: "$batchEdit" }));
    }
    buttons.push(ConvertButton({ Label: "重置", Action: "$reset" }));
    return buttons;
}